import { ComponentProps } from 'react';
import {
  List,
  TextField,
  TextInput,
  Filter,
  Pagination,
  minLength,
  Edit,
  TabbedForm,
  FormTab,
  EditButton,
  Toolbar,
  NumberField,
  useRecordContext,
  Labeled,
} from 'react-admin';
import CustomizableDatagrid from '../components/customizableDataGrid/CustomizableDatagrid';
import GroupConnectionComponent from '../components/GroupConnection';

const CatalogueItemFilter = (
  props: Omit<ComponentProps<typeof Filter>, 'children'>
) => (
  <Filter {...props}>
    <TextInput label='Search' source='q' alwaysOn validate={[minLength(2)]} />
  </Filter>
);

const CatalogueItemPagination = (props: ComponentProps<typeof Pagination>) => (
  <Pagination rowsPerPageOptions={[10, 20, 50, 100]} {...props} />
);

export const catalogueItemFields = ({
  sortable = true,
}: { sortable?: boolean } = {}) => {
  return [
    <TextField sortable={sortable} key='id' source='id' />,
    <TextField
      sortable={sortable}
      key='catalogue_item_id'
      source='catalogue_item_id'
    />,
    <TextField sortable={sortable} key='type' source='type' />,
    <TextField sortable={sortable} key='title' source='title' />,
    <TextField key='description' source='description' sortable={false} />,
  ];
};

export const CatalogueItemList = () => {
  return (
    <List
      filters={<CatalogueItemFilter />}
      pagination={<CatalogueItemPagination />}
      perPage={50}
      bulkActionButtons={false}
      exporter={false}
      sort={{ field: 'title', order: 'ASC' }}>
      <CustomizableDatagrid>
        {catalogueItemFields()}
        <NumberField source='groupCount' sortable={false} />
        <EditButton sx={{ float: 'right' }} />
      </CustomizableDatagrid>
    </List>
  );
};

const CatalogueItemTitle = () => {
  const record = useRecordContext();
  return (
    <>
      {record
        ? `[Catalogue item] ${record.catalogue_item_id}`
        : 'Catalogue item'}
    </>
  );
};

const CatalogueItemEditToolbar = (props: ComponentProps<typeof Toolbar>) => (
  <Toolbar {...props}>{''}</Toolbar>
);

export const CatalogueItemEdit = () => {
  return (
    <Edit title={<CatalogueItemTitle />}>
      <TabbedForm toolbar={<CatalogueItemEditToolbar />}>
        <FormTab label='summary'>
          <Labeled label='Contentful Id'>
            <TextField source='id' />
          </Labeled>
          <Labeled label='Item Id'>
            <TextField source='catalogue_item_id' />
          </Labeled>
          <Labeled label='Type'>
            <TextField source='type' />
          </Labeled>
          <Labeled label='Title'>
            <TextField source='title' />
          </Labeled>
          <Labeled label='Description'>
            <TextField source='description' />
          </Labeled>
        </FormTab>
        <FormTab label='groups'>
          <GroupConnectionComponent
            listGroups={true}
            otherResource='catalogue_items'
            otherResourceName='catalogue_item'
            displayAttribute='name'
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

import {
  AdminCreateUserCommand,
  AdminUpdateUserAttributesCommand,
  AdminGetUserCommand,
  AdminEnableUserCommand,
  AdminDisableUserCommand,
  AdminDeleteUserCommand,
  AdminRemoveUserFromGroupCommand,
  AdminAddUserToGroupCommand,
  ListUsersCommand,
  AdminDeleteUserAttributesCommand,
  AttributeType,
  AdminGetUserResponse,
  UserType,
} from '@aws-sdk/client-cognito-identity-provider';
import {
  getUsersFilteredQuery,
  getUsersForGroupQuery,
  deleteUser,
  createUser,
  updateUser,
  getAllUsersImportQuery,
  getCatalogueItemsForUser,
  sendReminderEmail,
} from '../../queries_mutations/users';
import {
  addUserToGroup,
  removeUserFromGroup,
} from '../../queries_mutations/groups';
import { handleReturnError } from '../components/handleReturnError';
import { backendClient } from '../components/backend';
import { cognitoClient } from '../components/cognito';
import { paginatePaginatedRequest } from '../../util/queries';
import { mapLimit } from 'modern-async';
import { CourseType } from '../contentfulCatalogueItems';
import { v4 as uuid } from 'uuid';
import {
  CreateResult,
  DataProvider,
  DeleteManyResult,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  UpdateResult,
} from 'react-admin';
import {
  GetAllUsersImportQuery,
  GetCatalogueItemsForUserQuery,
  GetUsersFilteredQueryVariables,
  UsersOrderBy,
} from 'types/gql/graphql';
import { truthyFilter } from '../../util/types';
import invariant from 'tiny-invariant';
import { filterObject, makePlaceholder } from 'dataProviders/utils';
import { allAttributesQuery } from 'dataProviders/components/attributes';
import { cognitoEnabled, userPoolId } from 'dataProviders/components/env';

const baseFields = ['email', 'givenName', 'familyName'];
const cognitoMapping = {
  email: 'email',
  givenName: 'given_name',
  familyName: 'family_name',
};

type InviteCognitoParams = {
  id: string;
};

type ResendInvitationsParams = {
  ids: string[];
};

type CreateCognitoOnlyParams = {
  entity: {
    id: string;
    email: string;
    [name: string]: string;
  };
};

type CreateBackendOnlyParams = {
  entity: {
    id: string;
    email: string;
    [name: string]: string;
  };
};

type EnableUserParams = {
  id: string;
};

type DisableUserParams = {
  id: string;
};

type AddToGroupParams = {
  id: string;
  group_id: string;
  allowBackendFail?: boolean;
};

type RemoveFromGroupParams = {
  id: string;
  group_id: string;
};

type EnabledAttributes = Awaited<ReturnType<typeof allAttributesQuery>>;

const transformFromCognito = (
  user: AdminGetUserResponse | UserType,
  attributes: EnabledAttributes
) => {
  const attrs =
    ('Attributes' in user
      ? user.Attributes
      : 'UserAttributes' in user
      ? user.UserAttributes
      : undefined) || [];

  const cognitoAttributeValues = Object.fromEntries(
    attrs
      .filter((attr): attr is { Name: string; Value: string } =>
        Boolean(attr.Name && attr.Value)
      )
      .map(attr => [attr.Name, attr.Value] as const)
  );
  const fixedAttributes = Object.fromEntries(
    Object.entries(cognitoMapping).map(([beKey, cognitoKey]) => [
      beKey,
      cognitoAttributeValues[cognitoKey] ?? null,
    ])
  );
  const cognitoAttributes = Object.fromEntries(
    attributes
      .map(({ id, cognitoName }) =>
        cognitoName && cognitoAttributeValues[cognitoName]
          ? ([id, cognitoAttributeValues[cognitoName]] as const)
          : null
      )
      .filter(truthyFilter)
  );

  return {
    ...fixedAttributes,
    id: user.Username,
    status: user.UserStatus,
    enabled: user.Enabled,
    attributes: cognitoAttributes,
  };
};

type BackendUser = {
  id: string | null;
  givenName?: string | null | undefined;
  familyName?: string | null | undefined;
  email?: string | null | undefined;
  groupsUsers?: {
    nodes?:
      | ({
          groupId: string;
        } | null)[]
      | null;
  };
  attributes?: string | null | undefined;
};
const transformFromBackend = (user: BackendUser) => {
  let attributes;
  try {
    if (!user.attributes) throw Error();
    attributes = JSON.parse(user.attributes);
  } catch {
    attributes = {};
  }

  return {
    id: user.id,
    givenName: user.givenName,
    familyName: user.familyName,
    email: user.email,
    attributes,
    groups: user?.groupsUsers?.nodes ?? [],
  };
};

type UserModRecord = {
  id?: string;
  email?: string;
  familyName?: string;
  givenName?: string;
  attributes?: Record<string, string>;
};

const transformToCognito = (
  userData: UserModRecord,
  attributes: EnabledAttributes = []
): AttributeType[] => {
  const standardFields = Object.entries(cognitoMapping).map(
    ([field, cognitoField]) => [
      cognitoField,
      userData[field as keyof Omit<UserModRecord, 'attributes'>],
    ]
  );
  const attributeFields = attributes
    .map(attr => {
      if (!attr.cognitoName) return null;
      if (userData.attributes?.[attr.id] === undefined) return null;
      return [attr.cognitoName, userData.attributes[attr.id]];
    })
    .filter(truthyFilter);
  return [
    ...standardFields,
    ...attributeFields,
    ['email_verified', 'true'],
  ].map(([key, value]) => ({
    Name: key,
    Value: value ?? undefined,
  }));
};

function order(field: string, direction: string): UsersOrderBy {
  const fieldMap = {
    email: 'Email',
    givenName: 'GivenName',
    familyName: 'FamilyName',
  } as const;
  const dir = direction === 'ASC' ? 'Asc' : 'Desc';
  if (field in fieldMap) {
    return UsersOrderBy[`${fieldMap[field as keyof typeof fieldMap]}${dir}`];
  }
  return UsersOrderBy.EmailAsc;
}

async function getPotentiallyFilteredUsers(params: GetListParams) {
  return await paginatePaginatedRequest(
    (start: number, perPage: number) => {
      const offset = (params.pagination.page - 1) * params.pagination.perPage;
      return backendClient
        .query({
          query: getUsersFilteredQuery,
          variables: {
            perPage: perPage,
            offset: offset + start,
            order: order(params.sort.field, params.sort.order),
            filter: params.filter.q
              ? {
                  or: [
                    { email: { includesInsensitive: params.filter.q } },
                    { givenName: { includesInsensitive: params.filter.q } },
                    { familyName: { includesInsensitive: params.filter.q } },
                    {
                      attributes: {
                        containsAsInsensitiveText: params.filter.q,
                      },
                    },
                  ],
                }
              : null,
          },
        })
        .then(data => ({
          data: data.data.users?.nodes || [],
          total: data.data.users?.totalCount,
        }));
    },
    params.pagination.perPage,
    r =>
      r.reduce(
        (p, v) => {
          p.total = v.total;
          p.data.push(...v.data);
          return p;
        },
        { data: [], total: 0 }
      ),
    record => record.data.length === 0
  );
}

const dataProvider: DataProvider = {
  getList: async (resource, params): Promise<GetListResult<any>> => {
    console.log('getList (users)', params);
    try {
      if (params.filter.not_in_group) {
        const condition = { groupId: params.filter.not_in_group };

        const data_ids_not_in = await backendClient.query({
          query: getUsersForGroupQuery,
          variables: {
            perPage: 1000,
            offset: 0,
            condition: condition,
          },
        });
        const { nodes = [] } = data_ids_not_in.data.groupsUsers || {};

        const ids = nodes.map(node => node?.user?.id).filter(truthyFilter);
        const filter: GetUsersFilteredQueryVariables['filter'] = {
          not: { id: { in: ids } },
        };

        if (params.filter.email && params.filter.email !== '') {
          filter.email = { includesInsensitive: params.filter.email };
        }

        const data_users = await paginatePaginatedRequest(
          (start, perPage) => {
            const offset =
              (params.pagination.page - 1) * params.pagination.perPage;
            return backendClient
              .query({
                query: getUsersFilteredQuery,
                variables: {
                  perPage: perPage,
                  offset: offset + start,
                  order: order(params.sort.field, params.sort.order),
                  filter,
                },
              })
              .then(data => ({
                data: data.data.users?.nodes || [],
                total: data.data.users?.totalCount,
              }));
          },
          params.pagination.perPage,
          r =>
            r.reduce(
              (p, v) => {
                p.total = v.total;
                p.data.push(...v.data);
                return p;
              },
              { data: [], total: 0 }
            ),
          record => record.data.length === 0
        );
        return {
          total: data_users.total,
          data: data_users.data.filter(truthyFilter).map(transformFromBackend),
        };
      }

      const res = await getPotentiallyFilteredUsers(params);

      return {
        total: res.total,
        data: res.data.filter(truthyFilter).map(transformFromBackend),
      };
    } catch (error) {
      return handleReturnError({
        error,
        title: 'getList user error',
        message: 'An error occured while retrieving the user list',
      });
    }
  },
  getOne: async (resource, params: GetOneParams & { email?: string }) => {
    console.log('getOne (users)', params);
    console.log({ params });

    const attributes = await allAttributesQuery();

    if (!params.id && params.email) {
      try {
        const data = await backendClient.query({
          query: getUsersFilteredQuery,
          variables: {
            perPage: 1000,
            offset: 0,
            filter: { email: { equalTo: params.email } },
          },
        });
        if (data.data.users?.totalCount === 1) {
          params.id = data.data.users?.nodes?.[0]?.id;
        }
      } catch (error) {
        return handleReturnError({
          error,
          title: 'getOne user error',
          message: 'An error occured while retrieving the user',
        });
      }
    }

    if (!params.id) {
      return { data: {} } as { data: any };
    }

    const backendUser = await backendClient.query({
      query: getUsersFilteredQuery,
      variables: {
        perPage: 100,
        offset: 0,
        filter: { id: { equalTo: params.id } },
      },
    });

    const [backendUserParsed] =
      backendUser.data.users?.nodes
        .filter(truthyFilter)
        .map(transformFromBackend) || [];

    const resultData = {
      ...backendUserParsed,
      status: 'UNKNOWN_COGNITO_DISABLED',
      enabled: 'UNKNOWN_COGNITO_DISABLED',
    };

    if (cognitoEnabled) {
      try {
        const command = new AdminGetUserCommand({
          UserPoolId: userPoolId,
          Username: params.id,
        });
        const cognitoResult = await cognitoClient().send(command);

        Object.assign(
          resultData,
          transformFromCognito(cognitoResult, attributes)
        );
      } catch (error) {
        return handleReturnError({
          error,
          title: 'getOne user error',
          message: 'An error occured while retrieving the user',
        });
      }
    }

    return {
      data: {
        backend: backendUserParsed,
        ...resultData,
      },
    };
  },
  getMany: async (resource, params): Promise<GetManyResult<any>> => {
    console.log('getMany (users)', params);

    try {
      const data = await backendClient.query({
        query: getUsersFilteredQuery,
        variables: {
          perPage: 1000,
          offset: 0,
          order: UsersOrderBy.PrimaryKeyDesc,
          filter: { id: { in: params.ids.map(String) } },
        },
      });
      return {
        data:
          data.data.users?.nodes
            .filter(truthyFilter)
            .map(transformFromBackend) || [],
      };
    } catch (error) {
      return handleReturnError({
        error,
        title: 'getMany user error',
        message: 'An error occured while retrieving the users',
      });
    }
  },
  getManyReference: async (
    resource,
    params
  ): Promise<GetManyReferenceResult<any>> => {
    console.log('getManyReference (users)', params);

    if (params.target === 'group_id' && params.id) {
      try {
        const data: {
          data: ReturnType<typeof transformFromBackend>[];
          total: number;
        } = {
          data: [],
          total: 0,
        };
        const singlePage = Boolean(params.pagination);
        let page = singlePage ? params.pagination.page - 1 : 0;
        const perPage = singlePage ? params.pagination.perPage : 100;

        while (true) {
          const val = await backendClient
            .query({
              query: getUsersForGroupQuery,
              variables: {
                perPage,
                offset: page * perPage,
                condition: {
                  groupId: String(params.id),
                },
              },
            })
            .then(data => ({
              data:
                data.data.groupsUsers?.nodes
                  .map(node => node?.user)
                  .filter(truthyFilter)
                  .map(user => transformFromBackend(user)) || [],
              total: data.data.groupsUsers?.totalCount || 0,
            }));
          console.log(`Fetching page ${page + 1} of ${perPage} items`);
          data.total = val.total;
          data.data.push(...val.data);
          if (
            singlePage ||
            data.data.length >= data.total ||
            val.data.length === 0
          ) {
            break;
          }
          page += 1;
        }
        return data;
      } catch (error) {
        return handleReturnError({
          error,
          title: 'getManyReference user error',
          message: 'An error occured while retrieving the associated users',
        });
      }
    }
    return Promise.reject('not implemented yet');
  },
  create: async (resource, params): Promise<CreateResult<any>> => {
    console.log('create (users)', params);

    const attributes = await allAttributesQuery();

    let userData: UserModRecord = {};

    if (cognitoEnabled) {
      try {
        const command = new AdminCreateUserCommand({
          UserPoolId: userPoolId,
          UserAttributes: transformToCognito(params.data, attributes),
          Username: params.data.email,
        });

        const cognitoResult = await cognitoClient().send(command);

        invariant(cognitoResult.User, 'No cognito user was found');

        Object.assign(
          userData,
          transformFromCognito(cognitoResult.User, attributes)
        );
      } catch (error) {
        return handleReturnError({
          error,
          title: 'create user on cognito error',
          message: 'An error occured while creating the user',
        });
      }
    } else {
      userData = {
        id: uuid(),
        ...filterObject(params.data, baseFields),
        attributes: params.data.attributes || {},
      };
    }

    try {
      await backendClient.mutate({
        mutation: createUser,
        variables: {
          input: {
            user: {
              ...filterObject(userData, [...baseFields, 'id', 'attributes']),
              attributes: JSON.stringify(userData?.attributes ?? {}),
            },
          },
        },
      });

      return { data: userData };
    } catch (error) {
      return handleReturnError({
        error,
        title: 'create user on graphql error',
        message: 'An error occured while creating the user',
      });
    }
  },
  inviteCognito: async (resource: string, params: InviteCognitoParams) => {
    console.log('inviteCognito (users)', params);
    try {
      const data = await backendClient.mutate({
        mutation: sendReminderEmail,
        variables: {
          input: {
            id: params.id,
          },
        },
      });
      return {
        data: data.data?.sendReminderEmail?.user,
      };
    } catch (error) {
      return handleReturnError({
        error,
        title: 'inviting user on graphql error',
        message: 'An error occured while inviting the user',
      });
    }
  },
  resendInvitations: async (
    resource: string,
    params: ResendInvitationsParams
  ) => {
    console.log('resendInvitations (users)', params);
    const failed = [];
    const succeeded = [];
    const skipped = [];

    const attributes = await allAttributesQuery();

    for (const id of params.ids) {
      try {
        const command = new AdminGetUserCommand({
          UserPoolId: userPoolId,
          Username: id,
        });

        const result = await cognitoClient().send(command);

        const { status } = transformFromCognito(result, attributes);
        if (status !== 'FORCE_CHANGE_PASSWORD') {
          skipped.push(id);
          continue;
        }
        await backendClient.mutate({
          mutation: sendReminderEmail,
          variables: {
            input: {
              id,
            },
          },
        });
        succeeded.push(id);
      } catch (e) {
        failed.push(id);
      }
    }
    return { data: { failed, succeeded, skipped } };
  },
  createCognitoOnly: async (
    resource: string,
    params: CreateCognitoOnlyParams
  ) => {
    console.log('createCognitoOnly (users)', params);
    try {
      const attributes = await allAttributesQuery();
      const command = new AdminCreateUserCommand({
        UserPoolId: userPoolId,
        UserAttributes: transformToCognito(params.entity, attributes),
        Username: params.entity.email,
      });

      const data = await cognitoClient().send(command);
      invariant(data.User, 'A new user should be created!');

      const user = transformFromCognito(data.User, attributes);

      const mutation = await backendClient.mutate({
        mutation: updateUser,
        variables: {
          input: {
            id: params.entity.id,
            patch: {
              id: user.id,
            },
          },
        },
      });
      return {
        data: mutation.data?.updateUser?.user,
      };
    } catch (error) {
      return handleReturnError({
        error,
        title: 'create user on cognito error',
        message: 'An error occured while creating the user',
      });
    }
  },
  createBackendOnly: async (
    resource: string,
    params: CreateBackendOnlyParams
  ) => {
    console.log('createBackendOnly (users)', params);
    try {
      const mutation = await backendClient.mutate({
        mutation: createUser,
        variables: {
          input: {
            user: {
              id: params.entity.id,
              ...filterObject(params.entity, baseFields),
              attributes: JSON.stringify(params.entity.attributes || {}),
            },
          },
        },
      });
      return { data: mutation.data?.createUser?.user };
    } catch (error) {
      return handleReturnError({
        error,
        title: 'create user on graphql error',
        message: 'An error occured while creating the user',
      });
    }
  },
  update: async (resource, params): Promise<UpdateResult<any>> => {
    console.log('update (users)', params);
    const attributes = await allAttributesQuery();

    const user = {
      ...filterObject(params.data, baseFields),
      attributes: JSON.stringify(params.data.attributes || {}),
    };

    try {
      await backendClient.mutate({
        mutation: updateUser,
        variables: {
          input: {
            id: String(params.id),
            patch: {
              ...user,
            },
          },
        },
      });
    } catch (error) {
      return handleReturnError({
        error,
        title: 'update user on graphql error',
        message: 'An error occured while updating the user',
      });
    }

    if (cognitoEnabled) {
      const fields = transformToCognito(params.data, attributes);
      const updates = fields.filter(update => update.Value != null);
      const deletes = fields
        .filter(update => update.Value == null)
        .map(update => update.Name)
        .filter(truthyFilter);

      const updateCommand = new AdminUpdateUserAttributesCommand({
        UserPoolId: userPoolId,
        Username: String(params.id),
        UserAttributes: updates,
      });
      const deleteCommand = new AdminDeleteUserAttributesCommand({
        UserPoolId: userPoolId,
        Username: String(params.id),
        UserAttributeNames: deletes,
      });
      if (updates.length) {
        await cognitoClient().send(updateCommand);
      }
      if (deletes.length) {
        await cognitoClient().send(deleteCommand);
      }
    }

    return { data: params.data };
  },
  updateMany: makePlaceholder('users')('updateMany'),
  delete: async (resource, params): Promise<DeleteResult<any>> => {
    console.log('delete (users)', params);
    const promises = [];

    if (cognitoEnabled) {
      const command = new AdminDeleteUserCommand({
        UserPoolId: userPoolId,
        Username: String(params.id),
      });

      promises.push(
        cognitoClient()
          .send(command)
          .then(
            () => ({
              data: params.previousData,
            }),
            error => ({
              error,
              title: 'delete user on cognito error',
              message: 'An error occured while deleting the user',
            })
          )
      );
    }

    promises.push(
      backendClient
        .mutate({
          mutation: deleteUser,
          variables: {
            input: {
              id: String(params.id),
            },
          },
        })
        .then(
          () => ({
            data: params.previousData,
          }),
          error => ({
            error,
            title: 'delete user on graphql error',
            message: 'An error occured while deleting the user',
          })
        )
    );

    const results = await Promise.all(promises);
    return { data: results };
  },
  deleteMany: async (resource, params): Promise<DeleteManyResult<any>> => {
    console.log('deleteMany (users)', params);
    const promises = await mapLimit(
      params.ids,
      async id => {
        if (cognitoEnabled) {
          try {
            const command = new AdminDeleteUserCommand({
              UserPoolId: userPoolId,
              Username: String(id),
            });
            await cognitoClient().send(command);
          } catch (error) {
            console.error({
              error,
              title: 'bulk delete on cognito error',
              message: 'An error occured while deleting the user',
            });
          }
        }

        try {
          await backendClient.mutate({
            mutation: deleteUser,
            variables: {
              input: {
                id: String(id),
              },
            },
          });
        } catch (error) {
          console.error({
            error,
            title: 'bulk delete on backend error',
            message: 'An error occured while deleting the user',
          });
        }
        return id;
      },
      10
    );
    const data = await Promise.all(promises);
    return { data };
  },
  enableUser: (resource: string, params: EnableUserParams) => {
    console.log('enableUser (users)', params);
    if (!cognitoEnabled) {
      return handleReturnError({
        error: new Error('Cognito is disabled'),
        title: 'enable user error',
        message: 'An error occured while enabling the user',
      });
    }
    const command = new AdminEnableUserCommand({
      UserPoolId: userPoolId,
      Username: params.id,
    });

    return cognitoClient()
      .send(command)
      .then(
        data => ({
          data: data,
        }),
        error =>
          handleReturnError({
            error,
            title: 'enable user error',
            message: 'An error occured while enabling the user',
          })
      );
  },
  disableUser: (resource: string, params: DisableUserParams) => {
    console.log('disableUser (users)', params);
    if (!cognitoEnabled) {
      return handleReturnError({
        error: new Error('Cognito is disabled'),
        title: 'enable user error',
        message: 'An error occured while enabling the user',
      });
    }
    const command = new AdminDisableUserCommand({
      UserPoolId: userPoolId,
      Username: params.id,
    });

    return cognitoClient()
      .send(command)
      .then(
        data => ({
          data: data,
        }),
        error =>
          handleReturnError({
            error,
            title: 'disable user error',
            message: 'An error occured while disabling the user',
          })
      );
  },
  addToGroup: async (resource: string, params: AddToGroupParams) => {
    console.log('addToGroup (groups)', params);

    const promises = [];
    if (cognitoEnabled) {
      const command = new AdminAddUserToGroupCommand({
        UserPoolId: userPoolId,
        Username: params.id,
        GroupName: params.group_id,
      });
      promises.push(
        cognitoClient()
          .send(command)
          .then(
            data => ({
              data: data,
            }),
            error =>
              handleReturnError({
                error,
                title: 'addToGroup on cognito error',
                message: 'An error occured while processing the addToGroup',
              })
          )
      );
    }

    promises.push(
      backendClient
        .mutate({
          mutation: addUserToGroup,
          variables: {
            input: {
              groupsUser: {
                groupId: params.group_id,
                userId: params.id,
              },
            },
          },
        })
        .then(
          data => ({
            data: data,
          }),
          error => {
            if (params.allowBackendFail) {
              console.error(`addToGroup on graphql error: ${error.message}`);
              return { data: null };
            }
            return handleReturnError({
              error,
              title: 'addToGroup on graphql error',
              message: 'An error occured while processing the addToGroup',
            });
          }
        )
    );

    const results = await Promise.all(promises);
    return { data: results };
  },
  removeFromGroup: async (resource: string, params: RemoveFromGroupParams) => {
    console.log('removeFromGroup (groups)', params);

    const promises = [];
    if (cognitoEnabled) {
      const command = new AdminRemoveUserFromGroupCommand({
        UserPoolId: userPoolId,
        Username: params.id,
        GroupName: params.group_id,
      });
      promises.push(
        cognitoClient()
          .send(command)
          .then(
            data => ({
              data: data,
            }),
            error =>
              handleReturnError({
                error,
                title: 'removeFromGroup on cognito error',
                message:
                  'An error occured while processing the removeFromGroup',
              })
          )
      );
    }
    promises.push(
      backendClient
        .mutate({
          mutation: removeUserFromGroup,
          variables: {
            input: {
              userId: params.id,
              groupId: params.group_id,
            },
          },
        })
        .then(
          data => ({
            data: data,
          }),
          error =>
            handleReturnError({
              error,
              title: 'removeFromGroup on graphql error',
              message: 'An error occured while processing the removeFromGroup',
            })
        )
    );

    const results = await Promise.all(promises);
    return { data: results };
  },
  getAllListImport: async (resource: string, params: {}) => {
    console.log('getAllListImport (users)', params);
    const data: {
      data: NonNullable<GetAllUsersImportQuery['users']>['nodes'];
      total: number;
    } = {
      data: [],
      total: 0,
    };
    try {
      let page = 0;
      const perPage = 3000;
      while (true) {
        const val = await backendClient
          .query({
            query: getAllUsersImportQuery,
            variables: { perPage, offset: page * perPage },
          })
          .then(data => ({
            data: data.data.users?.nodes || [],
            total: data.data.users?.totalCount || 0,
          }));
        console.log(`Fetching page ${page + 1} of ${perPage} items`);
        data.total = val.total;
        data.data.push(...val.data);
        if (data.data.length >= data.total || val.data.length === 0) {
          break;
        }
        page += 1;
      }
    } catch (error) {
      return handleReturnError({
        error,
        title: 'getAllListImport user error',
        message: 'An error occured while retrieving the user getAllListImport',
      });
    }
    return data;
  },
  getAllFromCognito: async (
    resource: string,
    params: { updateNumberOfCognitoUsers: (nr: number) => void }
  ) => {
    console.log('getAllFromCognito (users)', params);
    if (!cognitoEnabled) {
      return { data: [] };
    }

    const updateNumberOfCognitoUsers = params.updateNumberOfCognitoUsers;
    const attributes = await allAttributesQuery();

    let pageToken: string | undefined = 'first_page';
    const users = [];

    try {
      while (pageToken !== undefined) {
        const command: ListUsersCommand = new ListUsersCommand({
          UserPoolId: userPoolId,
          Limit: 60,
          PaginationToken: pageToken === 'first_page' ? undefined : pageToken,
        });
        const data = await cognitoClient().send(command);
        users.push(
          ...(data.Users?.map(user => transformFromCognito(user, attributes)) ||
            [])
        );
        if (updateNumberOfCognitoUsers) {
          updateNumberOfCognitoUsers(users.length);
        }
        pageToken = data.PaginationToken || undefined;
      }
      return { data: users };
    } catch (error) {
      return handleReturnError({
        error,
        title: 'getAllFromCognito users error',
        message:
          'An error occured while retrieving the users getAllFromCognito',
      });
    }
  },
  getAllCatalogueItems: async (resource: string, params: { id: string }) => {
    console.log('getAllCatalogueItems (users)', params);

    type CatalogueRecord = {
      mandatory: string[];
      accessible: string[];
      courseType: CourseType;
      id: string;
    };

    const catalogueItems: Record<string, CatalogueRecord> = {};
    const data: {
      data: NonNullable<GetCatalogueItemsForUserQuery['groupsUsers']>['nodes'];
      total: number;
    } = {
      data: [],
      total: 0,
    };

    try {
      let page = 0;
      const perPage = 100;
      while (true) {
        const val = await backendClient
          .query({
            query: getCatalogueItemsForUser,
            variables: {
              condition: { userId: params.id },
              perPage,
              offset: page * perPage,
            },
          })
          .then(data => ({
            data: data.data.groupsUsers?.nodes || [],
            total: data.data.groupsUsers?.totalCount || 0,
          }));
        console.log(`Fetching page ${page + 1} of ${perPage} items`);
        data.total = val.total;
        data.data.push(...val.data);
        if (data.data.length >= data.total || val.data.length === 0) {
          break;
        }
        page += 1;
      }

      data.data.filter(truthyFilter).forEach(groupsUser => {
        const groupName = groupsUser.group?.name;
        if (!groupName) return;
        groupsUser.group?.catalogueItemsGroups.nodes
          .filter(truthyFilter)
          .forEach(catalogueItem => {
            const catalogueItemId = catalogueItem.catalogueItemId;
            const course = catalogueItem.course;
            if (!catalogueItemId || !course) return;

            if (!catalogueItems[catalogueItemId]) {
              catalogueItems[catalogueItemId] = {
                mandatory: [],
                accessible: [],
                courseType: CourseType.Accessible,
                id: catalogueItemId,
              };
            }

            catalogueItems[catalogueItemId][
              course.toLowerCase() as Lowercase<keyof typeof CourseType>
            ].push(groupName);

            if (course === CourseType.Mandatory) {
              catalogueItems[catalogueItemId].courseType = CourseType.Mandatory;
            }
          });
      });
    } catch (error) {
      return handleReturnError({
        error,
        title: 'getAllCatalogueItems user error',
        message:
          'An error occured while retrieving the user getAllCatalogueItems',
      });
    }

    return {
      data: catalogueItems,
    };
  },
};

export default dataProvider;

import isString from 'lodash/isString';

export async function paginatePaginatedRequest<Data, Result>(
  func: (start: number, page: number) => Data | Promise<Data>,
  pageSize: number,
  reducer: (results: Data[]) => Result,
  breaker?: (result: Data) => boolean
): Promise<Result> {
  const maxPage = 1000;
  // pages need to be zero-indexed
  const pages = Math.ceil(pageSize / maxPage) - 1;
  console.log(`paginating request for ${pageSize} into ${pages + 1}`);
  const results = [];
  for (let page = 0; page <= pages; page += 1) {
    const start = page * maxPage;
    // Make sure we don't overshoot the requested pagesize
    const currPageSize = page !== pages ? maxPage : pageSize - page * maxPage;
    const result = await func(start, currPageSize);
    results.push(result);
    if (breaker && breaker(result)) {
      break;
    }
  }
  return reducer(results);
}

type GetAllByCursorOptions = {
  max?: number;
  pageSize?: number;
};

type GetAllByCursorFunc<Data> = (
  pageSize: number,
  cursor: string | null | undefined
) => Promise<Data>;

type QueryResponse = {
  data: Record<string, QueryField | string | null>;
};

type QueryField = {
  nodes: any[];
  totalCount: number;
  pageInfo: {
    endCursor?: string | null | undefined;
    hasNextPage: boolean;
  };
};

type Result<Data> = {
  data: Data[];
  total: number;
};

export async function getAllByCursor<
  FieldType extends QueryField,
  ReturnData extends QueryResponse
>(
  func: GetAllByCursorFunc<ReturnData>,
  fieldName: string,
  options: GetAllByCursorOptions = {}
) {
  const { max = null, pageSize = 1000 } = options;
  type NodeType = NonNullable<FieldType['nodes']> extends (infer U)[]
    ? U
    : never;
  const result: Result<NodeType> = {
    data: [],
    total: -1,
  };
  let cursor: string | null | undefined = null;
  while (true) {
    const response: ReturnData = await func(pageSize, cursor);
    const procData = response.data[fieldName];
    if (!procData || isString(procData)) break;
    cursor = procData?.pageInfo?.endCursor;
    result.data.push(...(procData?.nodes || []));
    result.total = procData.totalCount;
    if (!procData.pageInfo.hasNextPage || (max && result.data.length > max)) {
      break;
    }
  }
  return result;
}

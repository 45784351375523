import { DataProvider } from 'react-admin';
import { startSyncComparison } from './components/syncParts';
import { makePlaceholder } from './utils';

const syncPlaceholder = makePlaceholder('sync');

const dataProvider: DataProvider = {
  getList: syncPlaceholder('getList'),
  getOne: syncPlaceholder('getOne'),
  getMany: syncPlaceholder('getMany'),
  getManyReference: syncPlaceholder('getManyReference'),
  // @ts-ignore
  create: async (resource, params) => {
    console.log('create (sync)', params);

    const results = await startSyncComparison({
      updateCounts: params.data.updateCounts,
    });

    return { data: { id: 1, results: results } };
  },
  update: syncPlaceholder('update'),
  updateMany: syncPlaceholder('updateMany'),
  delete: syncPlaceholder('delete'),
  deleteMany: syncPlaceholder('deleteMany'),
};

export default dataProvider;

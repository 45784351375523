import { Auth } from '@aws-amplify/auth';
import { Signer } from 'aws-appsync-auth-link';
import * as Url from 'url';

export const appsyncSignedFetch = async (
  url: string,
  init: Record<string, any>
) => {
  const service = 'appsync';
  const USER_AGENT_HEADER = 'x-amz-user-agent';
  const USER_AGENT = `aws-amplify`;
  const credentials = Auth.currentCredentials().then(creds =>
    Auth.essentialCredentials(creds)
  );
  const region = Auth.configure().region;
  const { accessKeyId, secretAccessKey, sessionToken } = await credentials;

  const { host, path } = Url.parse(url);

  const formatted = {
    body: init.body,
    method: 'POST',
    headers: {
      accept: '*/*',
      'content-type': 'application/json; charset=UTF-8',
    },
    service,
    region,
    url,
    host,
    path,
  };

  const { headers } = Signer.sign(formatted, {
    access_key: accessKeyId,
    secret_key: secretAccessKey,
    session_token: sessionToken,
  });

  const req = {
    ...init,
    ...formatted,
    headers: {
      ...init.headers,
      ...formatted.headers,
      ...headers,
      [USER_AGENT_HEADER]: USER_AGENT,
    },
  };

  return fetch(url, req);
};

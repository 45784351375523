import { mapLimit } from 'modern-async';
import { Identifier } from 'react-admin';

import usersDataProvider from '../cognitoUsers';
import groupsDataProvider from '../cognitoGroups';
import { readFirstWorksheet, getAttributeLists } from './excel';
import { truthyFilter } from '../../util/types';

export type GroupResultType = 'existing' | 'new' | 'does not exist' | 'removed';
export type GroupResult = [string, GroupResultType];
type UserRecord = {
  id: Identifier;
  groups: string[];
  [name: string]: any;
};
export type Row = {
  result: UserRecord;
  existingUser: boolean;
  errorOnUpsert?: typeof Error;
  groups: GroupResult[];
};

type Group = { name: string };

type User = {
  email: string;
  id: Identifier;
  groupsUsers: {
    nodes: {
      group: Group;
    }[];
  };
};

type ReadRowsArgs = {
  workbook: Parameters<typeof readFirstWorksheet>[0];
  rowProc: Function;
  updateNumberOfResults: (number: number) => any;
  updateNumberOfResultsDone: (number: number) => any;
  dryRun: boolean;
};
const readRows = async ({
  workbook,
  rowProc,
  updateNumberOfResults,
  updateNumberOfResultsDone,
  dryRun,
}: ReadRowsArgs) => {
  const { attributeMapping, ...attributes } = await getAttributeLists();
  const content = (
    await readFirstWorksheet<UserRecord | null>(workbook, attributes)
  ).filter(truthyFilter);

  const usersArray: { data: User[] } = await usersDataProvider.getAllListImport(
    'users',
    {}
  );
  const usersMap = Object.fromEntries(
    usersArray.data.map(u => [
      u.email,
      {
        email: u.email,
        id: u.id,
        groups: u.groupsUsers.nodes.map(g => g.group.name),
      },
    ])
  );

  const groupsArray: { data: Group[] } =
    await groupsDataProvider.getAllListImport('groups', {});
  const groupsMap = Object.fromEntries(
    groupsArray.data.map(g => [g.name, true])
  );

  updateNumberOfResults(content.length);

  return mapLimit(
    content,
    async (obj, index) => {
      updateNumberOfResultsDone(index + 1);
      return rowProc({
        obj,
        currentObject: usersMap[obj.email],
        groupsMap,
        attributeMapping,
        dryRun,
      });
    },
    2
  );
};

type ProcFieldArgs = {
  obj: UserRecord;
  currentObject: UserRecord;
  groupsMap: Record<string, boolean>;
  attributeMapping: Record<string, string>;
  dryRun: boolean;
};
const procField = async ({
  obj,
  currentObject,
  groupsMap,
  attributeMapping,
  dryRun,
}: ProcFieldArgs) => {
  const existingUserId = currentObject && currentObject.id;

  const groupsForUser: string[] = [];
  if (existingUserId) {
    groupsForUser.push(...currentObject.groups);
  }

  const newGroups = obj.groups.filter(group => group !== 'Administrators');
  const newGroupsForUser = newGroups.filter(x => !groupsForUser.includes(x));
  const removedGroupsForUser = groupsForUser.filter(
    x => !newGroups.includes(x) && x !== 'Administrators'
  );

  const newGroupsForUserThatExist: string[] = [];

  const groups = [
    ...newGroups.map(initialGroup => {
      let status = 'existing';
      if (newGroupsForUser.find(i => i === initialGroup)) {
        if (groupsMap[initialGroup]) {
          status = 'new';
          newGroupsForUserThatExist.push(initialGroup);
        } else {
          status = 'does not exist';
        }
      }

      return [initialGroup, status];
    }),
    ...removedGroupsForUser.map(group => [group, 'removed']),
  ];

  const attributes = Object.fromEntries(
    Object.entries(attributeMapping)
      .map(([name, id]) => (obj[name] ? [id, obj[name]] : null))
      .filter(truthyFilter)
  );
  const userObj = {
    id: obj.id,
    email: obj.email,
    givenName: obj.givenName,
    familyName: obj.familyName,
    attributes,
  };

  let errorOnUpsert = undefined;
  if (!dryRun) {
    let id: Identifier | undefined = undefined;
    if (existingUserId) {
      id = existingUserId;
      try {
        await usersDataProvider.update('users', {
          id: existingUserId,
          data: userObj,
          previousData: {},
        });
      } catch (e) {
        errorOnUpsert = e;
      }
    } else {
      try {
        const user = (await usersDataProvider.create('users', {
          data: userObj,
        })) as { data: User };
        id = user.data?.id;
      } catch (e) {
        errorOnUpsert = e;
      }
    }

    newGroupsForUserThatExist.forEach(group => {
      usersDataProvider.addToGroup('users', {
        id,
        group_id: group,
      });
    });

    removedGroupsForUser.forEach(group => {
      usersDataProvider.removeFromGroup('users', {
        id,
        group_id: group,
      });
    });
  }

  return {
    result: userObj,
    existingUser: Boolean(existingUserId),
    errorOnUpsert,
    groups,
  };
};

export const processWorkbook = async ({
  dryRun = false,
  ...opts
}: Omit<ReadRowsArgs, 'rowProc'>) => {
  const rows = await readRows({
    ...opts,
    rowProc: procField,
    dryRun,
  });
  return { rows, dryRun };
};

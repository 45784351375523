import { ComponentProps, useCallback, useState, MouseEvent } from 'react';
import { styled } from '@mui/material/styles';
import {
  Button,
  useNotify,
  useRefresh,
  useEditController,
  ReferenceInput,
  AutocompleteInput,
  ReferenceManyField,
  Pagination,
  TextField,
  SelectInput,
  useDataProvider,
  useRecordContext,
} from 'react-admin';
import { useMutation } from 'react-query';
import DisableIcon from '@mui/icons-material/HighlightOff';
import AddIcon from '@mui/icons-material/Add';
import { CourseType } from '../dataProviders/contentfulCatalogueItems';
import CustomizableDatagrid from './customizableDataGrid/CustomizableDatagrid';
import { groupFields } from '../resources/groups';
import { catalogueItemFields } from '../resources/catalogueItems';
import { tenantFields } from '../resources/tenants';
import { Stack } from '@mui/material';
import { useAttributes } from './AttributesContext';

const StyledAutocompleteInput = styled(AutocompleteInput)`
  width: 300px;

  .RaAutocompleteInput-textField {
    margin-block: 0;
  }
`;

type RRecord = {
  id: string;
};

type RemoveFromGroupButtonProps = {
  otherResource: string;
  otherResourceName: string;
  listGroups: boolean;
  connected_record?: RRecord;
} & ComponentProps<typeof Button>;

const RemoveFromGroupButton = ({
  otherResource,
  otherResourceName,
  listGroups,
  connected_record,
  ...props
}: RemoveFromGroupButtonProps) => {
  const record = useRecordContext();
  const type = 'removeFromGroup';
  const userFriendlyResourceName = otherResourceName.replace('_', ' ');
  const notification = listGroups
    ? `The group has been removed from the ${userFriendlyResourceName}`
    : `The ${userFriendlyResourceName} has been removed from the group`;
  const id = listGroups ? connected_record?.id : record?.id;
  const group_id = listGroups ? record?.id : connected_record?.id;

  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const { mutate: remove, isLoading } = useMutation(
    () =>
      dataProvider[type](otherResource, { group_id, id, tenantInternalId: id }),
    {
      onSuccess: data => {
        refresh();
        notify(notification);
      },
      onError: (error: Error) =>
        notify(
          `Error while removing ${otherResourceName} from group: ${error.message}`,
          { type: 'warning' }
        ),
    }
  );

  const handlerWithoutProp = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      remove();
    },
    [remove]
  );

  return (
    <Button
      {...props}
      label='Remove connection'
      onClick={handlerWithoutProp}
      disabled={isLoading}
      startIcon={<DisableIcon />}
      color='secondary'
    />
  );
};

type AddToGroupProps = {
  groupId: string | number;
  id: string | number;
  tenantId?: string;
  courseTypeToAdd?: string;
};

type AddToGroupAutoCompleteProps = {
  listGroups: boolean;
  otherResource: string;
  otherResourceName: string;
  displayAttribute: string;
};

export const AddToGroupAutoComplete = ({
  listGroups,
  otherResource,
  otherResourceName,
  displayAttribute,
}: AddToGroupAutoCompleteProps) => {
  const record = useRecordContext();
  const userFriendlyResourceName = otherResourceName.replace(/_/g, ' ');
  const label = listGroups
    ? 'Group'
    : userFriendlyResourceName.charAt(0).toUpperCase() +
      userFriendlyResourceName.slice(1);
  const notification = listGroups
    ? `The group has been added to the ${userFriendlyResourceName}`
    : `The ${userFriendlyResourceName} has been added to the group`;
  const source = listGroups ? 'group_id' : `${otherResourceName}_id`;
  const reference = listGroups ? 'groups' : otherResource;
  const errorResourceName = listGroups ? 'group' : userFriendlyResourceName;
  const filterName = listGroups
    ? `not_for_${otherResourceName}`
    : 'not_in_group';
  const relationTypeChoice = otherResource === 'catalogue_items';

  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const { mutate: addToGroupCall, isLoading } = useMutation<
    any,
    any,
    AddToGroupProps
  >(
    ({ groupId, id, tenantId, courseTypeToAdd }) =>
      dataProvider.addToGroup(otherResource, {
        group_id: groupId,
        id,
        tenantId,
        courseType: courseTypeToAdd,
      }),
    {
      onSuccess: () => {
        refresh();
        notify(notification);
      },
      onError: error =>
        notify(
          `Error while adding ${userFriendlyResourceName}: ${error.message}`,
          { type: 'warning' }
        ),
    }
  );
  const [toAdd, changeToAdd] = useState<string>();
  const [tenantId, changeTenantId] = useState<string>();
  const [courseTypeToAdd, changeCourseTypeToAdd] = useState<string>();

  const changeToAddOption = async (value: string) => {
    changeToAdd(value);
    if (['user'].includes(userFriendlyResourceName)) return;
    if (record.tenantId) {
      changeTenantId(record.tenantId);
      return;
    }

    type Tenant = {
      tenantId: string;
      tenantInternalId: string;
    };
    const tenantData = (await dataProvider['GET_LIST']('tenants', {
      filter: {},
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'title', order: 'ASC' },
    })) as { data: Tenant[] };

    const [tenant] = tenantData.data.filter(
      ({ tenantInternalId }) => tenantInternalId === value
    );

    changeTenantId(tenant?.tenantId);
  };

  const addToGroup = () => {
    const id = listGroups ? record.id : toAdd;
    const group_id = listGroups ? toAdd : record.id;
    if (!toAdd) {
      return notify(`You need to choose a ${errorResourceName}`, {
        type: 'warning',
      });
    }
    if (relationTypeChoice && courseTypeToAdd == null) {
      return notify(`You need to choose a relation type`, { type: 'warning' });
    }

    console.log({
      groupId: group_id!,
      id: id!,
      tenantId,
      courseTypeToAdd,
    });

    return addToGroupCall({
      groupId: group_id!,
      id: id!,
      tenantId,
      courseTypeToAdd,
    });
  };

  return (
    <Stack direction={'row'} spacing={2}>
      <ReferenceInput
        label={label}
        source={source}
        reference={reference}
        allowEmpty={false}
        filter={{ [filterName]: record.id }}
        disabled={isLoading}>
        <StyledAutocompleteInput
          optionText={displayAttribute}
          onChange={changeToAddOption}
          filterToQuery={(searchText: string) => ({
            [displayAttribute]: searchText,
          })}
        />
      </ReferenceInput>
      {relationTypeChoice ? (
        <SelectInput
          source='Type of relation'
          choices={[
            { id: CourseType.Mandatory, name: 'Mandatory' },
            { id: CourseType.Accessible, name: 'Accessible' },
          ]}
          onChange={e => changeCourseTypeToAdd(e.target.value)}
          sx={{ marginBlockEnd: 0 }}
        />
      ) : null}
      <Button
        label='Add'
        onClick={addToGroup}
        disabled={isLoading}
        startIcon={<AddIcon />}
        variant='contained'
        size='large'
        color='primary'
        sx={{ alignSelf: 'flex-start', position: 'relative', top: 4 }}
      />
    </Stack>
  );
};

const GroupPagination = (props: ComponentProps<typeof Pagination>) => (
  <Pagination rowsPerPageOptions={[10, 20, 50, 100]} {...props} />
);

type ListGroupsProps = {
  addLabel?: string;
  label?: string;
  listGroups?: boolean;
  otherResource?: string;
  otherResourceName?: string;
  displayAttribute?: string;
} & ComponentProps<typeof RemoveFromGroupButton>;
export const ListGroups = ({
  addLabel,
  label,
  listGroups,
  otherResource,
  otherResourceName,
  displayAttribute,
  ...props
}: ListGroupsProps) => {
  const { record } = useEditController(props);
  const reference = listGroups ? 'groups' : otherResource;
  const target = listGroups ? `${otherResourceName}_id` : 'group_id';

  const usedProps = { listGroups, otherResource, otherResourceName };
  const attributes = useAttributes();
  let fields = groupFields({ sortable: false });

  if (listGroups) {
    if (otherResource === 'catalogue_items') {
      fields = fields.concat(
        <TextField
          sortable={false}
          source='courseType'
          label='Type of relation'
        />
      );
    }
  } else {
    switch (otherResource) {
      case 'catalogue_items':
        fields = catalogueItemFields({ sortable: false }).concat(
          <TextField
            sortable={false}
            key='courseType'
            source='courseType'
            label='Type of relation'
          />
        );
        break;
      case 'tenants':
        fields = tenantFields({ sortable: false });
        break;
      default:
        fields = [
          <TextField
            sortable={false}
            key='email'
            source='email'
            label='Email'
          />,
          <TextField
            sortable={false}
            key='givenName'
            source='givenName'
            label='Given name'
          />,
          <TextField
            sortable={false}
            key='familyName'
            source='familyName'
            label='Family name'
          />,
          ...attributes.map(field => (
            <TextField
              sortable={false}
              source={`attributes.${field.id}`}
              key={`attributes.${field.id}`}
              label={field.name ?? field?.cognitoName ?? field.id}
            />
          )),
        ];
        break;
    }
  }

  return (
    <ReferenceManyField
      label={reference}
      reference={reference}
      target={target}
      pagination={<GroupPagination />}
      perPage={20}>
      <CustomizableDatagrid storeContext={`.${reference}`}>
        {fields}
        <RemoveFromGroupButton
          connected_record={record}
          {...usedProps}
          sx={{ float: 'right' }}
        />
      </CustomizableDatagrid>
    </ReferenceManyField>
  );
};

type GroupConnectionComponentProps = {
  otherResource: string;
  otherResourceName: string;
  listGroups: boolean;
  displayAttribute: string;
  addToGroupProps?: Omit<
    ComponentProps<typeof AddToGroupAutoComplete>,
    'otherResource' | 'otherResourceName' | 'listGroups' | 'displayAttribute'
  >;
  listGroupsProps?: Omit<
    ComponentProps<typeof ListGroups>,
    'otherResource' | 'otherResourceName' | 'listGroups' | 'displayAttribute'
  >;
};

const GroupConnectionComponent = ({
  otherResource,
  otherResourceName,
  listGroups,
  addToGroupProps,
  listGroupsProps,
  displayAttribute,
}: GroupConnectionComponentProps) => {
  const sharedProps = {
    listGroups,
    otherResource,
    otherResourceName,
    displayAttribute,
  };
  return (
    <>
      <AddToGroupAutoComplete {...sharedProps} {...(addToGroupProps || {})} />
      <ListGroups {...sharedProps} {...(listGroupsProps || {})} />
    </>
  );
};

export default GroupConnectionComponent;

import type { WorkBook } from 'xlsx';
import { allAttributesQuery } from './attributes';
import { truthyFilter } from '../../util/types';

export const BASE_FIELDS = ['email', 'givenName', 'familyName'];
export const EXTRA_FIELDS = ['groups'];
export const MANDATORY_MARKER = ' (mandatory)';

export async function getAttributeLists({
  baseFields = BASE_FIELDS,
  extraFields = EXTRA_FIELDS,
} = {}) {
  const attributes = await allAttributesQuery();
  const attributeMapping = Object.fromEntries(
    attributes.map(({ id, key }) => [key, id])
  );
  const reverseAttributeMapping = Object.fromEntries(
    attributes.map(({ id, key }) => [id, key])
  );

  const allowedAttributes = [
    ...baseFields,
    ...attributes.map(({ key }) => key),
    ...extraFields,
  ];

  const mandatoryAttributes = [
    ...baseFields,
    ...attributes
      .filter(({ requiredImport }) => requiredImport)
      .map(({ key }) => key),
  ];

  return {
    attributeMapping,
    allowedAttributes,
    mandatoryAttributes,
    reverseAttributeMapping,
  };
}

export async function readFirstWorksheet<T>(
  workbook: WorkBook,
  {
    allowedAttributes,
    mandatoryAttributes,
  }: Pick<
    Awaited<ReturnType<typeof getAttributeLists>>,
    'allowedAttributes' | 'mandatoryAttributes'
  >
): Promise<T[]> {
  const XLSX = await import('xlsx');
  const makeHeaderMap = (val: unknown) => {
    if (typeof val !== 'string') return null;
    if (val.endsWith(MANDATORY_MARKER))
      val = val.slice(0, -1 * MANDATORY_MARKER.length);
    if (typeof val !== 'string') return null;
    if (!allowedAttributes.includes(val)) return null;
    return val;
  };
  const first_sheet_name = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[first_sheet_name];
  const range = XLSX.utils.decode_range(worksheet['!ref']!);
  const content = [];
  let headerMap: (string | null)[] = [];
  for (let r = range.s.r; r <= range.e.r; r += 1) {
    const headerRow = range.s.r === r;
    const row = [];
    for (let c = range.s.c; c <= range.e.c; c += 1) {
      const cellAddr = XLSX.utils.encode_cell({ r, c });
      const cell = worksheet[cellAddr];
      let value = null;
      if (cell && cell.w) {
        value = cell.w;
      }
      if (value && cell.t === 's') {
        value = value.trim();
      }
      row.push(value);
    }
    if (headerRow) {
      headerMap = row.map(makeHeaderMap);
      const missingMandatory = mandatoryAttributes.filter(
        // eslint-disable-next-line no-loop-func
        attr => !headerMap.includes(attr)
      );
      if (missingMandatory.length) {
        throw new Error(
          `Excel file is missing the following mandatory fields: ${missingMandatory.join(
            ', '
          )}`
        );
      }
      continue;
    }

    const values = getValues<T>(row, headerMap, mandatoryAttributes);
    if (values) content.push(values);
  }
  return content;
}

const getValues = <T>(
  valueArray: string[],
  headerMap: (string | null)[],
  mandatoryAttributes: string[]
): T | null => {
  const resultObject = Object.fromEntries(
    headerMap
      .map((element, index) => {
        if (element === null) return null;
        if (element === 'groups') {
          return [
            element,
            (valueArray[index] || '')
              .split('|')
              .map(string => string.trim())
              .filter(truthyFilter),
          ] as const;
        }
        return [element, valueArray[index] || ''] as const;
      })
      .filter(truthyFilter)
  );

  const filled = mandatoryAttributes.every(e => resultObject[e]);

  if (!filled) return null;

  return resultObject;
};

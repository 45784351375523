import { DashboardMenuItem, MenuItemLink } from 'react-admin';
import SchoolIcon from '@mui/icons-material/School';
import PeopleIcon from '@mui/icons-material/People';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AssignmentIcon from '@mui/icons-material/Assignment';

export const CustomMenu = () => (
  <div style={{ marginTop: '16px' }}>
    <DashboardMenuItem />
    <MenuItemLink
      to='/users'
      primaryText='Users'
      leftIcon={<AccountBoxIcon />}
    />
    <MenuItemLink to='/groups' primaryText='Groups' leftIcon={<PeopleIcon />} />
    <MenuItemLink
      to='/tenants'
      primaryText='Tenants'
      leftIcon={<SchoolIcon />}
    />
    <MenuItemLink
      to='/catalogue_items'
      primaryText='Catalogue Items'
      leftIcon={<AssignmentIcon />}
    />
  </div>
);

import { CloudSync } from '@mui/icons-material';
import CustomizableDatagrid from 'components/customizableDataGrid/CustomizableDatagrid';
import { ComponentProps, MouseEvent } from 'react';
import {
  BooleanField,
  BooleanInput,
  Button,
  Edit,
  EditButton,
  List,
  Pagination,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import { useMutation } from 'react-query';

const fieldLabels = {
  cognitoName: 'Cognito Name',
  requiredImport: 'Required in import file',
};

export const RunCognitoImportButton = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const { mutate: fetchAttributes, isLoading } = useMutation<
    { data: string[] },
    Error,
    MouseEvent<HTMLButtonElement>
  >(() => dataProvider.getAllFromCognito('attributes', {}), {
    onSuccess: ({ data }) => {
      notify(`${data.length} attributes have been retrieved from cognito.`);
      refresh();
    },
    onError: error =>
      notify(
        `Error while getting new attributes from cognito: ${error.message}`,
        {
          type: 'warning',
        }
      ),
  });

  return (
    <Button
      label='Get new attributes from cognito'
      disabled={isLoading}
      onClick={fetchAttributes}>
      <CloudSync />
    </Button>
  );
};

const AttributePagination = () => (
  <Pagination rowsPerPageOptions={[10, 20, 50, 100]} />
);

const AttributeActions = () => (
  <Toolbar>
    <RunCognitoImportButton />
  </Toolbar>
);

export const AttributeList = () => {
  return (
    <List
      pagination={<AttributePagination />}
      perPage={50}
      actions={<AttributeActions />}
      exporter={false}
      sort={{ field: 'name', order: 'ASC' }}>
      <CustomizableDatagrid>
        <TextField source='key' />
        <TextField source='name' />
        <TextField source='cognitoName' label={fieldLabels['cognitoName']} />
        <TextField source='description' sortable={false} />
        <BooleanField source='enabled' sortable={false} />
        <BooleanField
          source='requiredImport'
          sortable={false}
          label={fieldLabels['requiredImport']}
        />
        <EditButton sx={{ float: 'right' }} />
      </CustomizableDatagrid>
    </List>
  );
};

export const AttributeEditTitle = () => {
  const record = useRecordContext();
  return (
    <>
      {record?.name
        ? `[Attribute] ${record?.name}${
            record?.cognitoName ? ` (${record?.cognitoName})` : ''
          }`
        : 'Attribute'}
    </>
  );
};

const AttributeEditToolbar = (
  props: Omit<ComponentProps<typeof Toolbar>, 'children'>
) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const AttributeEdit = () => {
  return (
    <Edit title={<AttributeEditTitle />}>
      <SimpleForm toolbar={<AttributeEditToolbar />}>
        <TextInput
          source='key'
          fullWidth
          helperText='Used as field name in excel in- and exports. Changing this will make old exports not import correctly'
        />
        <TextInput source='name' fullWidth helperText='Display name' />
        <TextInput
          source='description'
          fullWidth
          helperText='Shown as helper text (like this) on the form'
        />
        <TextInput
          source='cognitoName'
          label={fieldLabels['cognitoName']}
          disabled
          fullWidth
        />
        <BooleanInput
          source='enabled'
          fullWidth
          helperText='Will treat this field as not existing for most purposes'
        />
        <BooleanInput
          source='requiredImport'
          label={fieldLabels['requiredImport']}
          helperText='Require this field for imports'
          fullWidth
        />
      </SimpleForm>
    </Edit>
  );
};

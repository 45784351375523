import usersDataProvider from '../cognitoUsers';
import groupsDataProvider from '../cognitoGroups';
import { mapLimit } from 'modern-async';
import { readFirstWorksheet } from './excel';
import { Identifier } from 'react-admin';

type UserRecord = {
  id: Identifier;
  email: string;
  attributes: Record<string, string>;
  groups: string[];
};

export type Group = { name: string };

type User = {
  email: string;
  id: Identifier;
  groupsUsers: {
    nodes: {
      group: Group;
    }[];
  };
};

export type Row = {
  result: UserRecord;
  existingUser: boolean;
  admin: boolean;
  errorOnDelete?: typeof Error;
  groups: string[];
};

type ReadRowsArgs = {
  workbook: Parameters<typeof readFirstWorksheet>[0];
  rowProc: Function;
  updateNumberOfResults: (number: number) => any;
  updateNumberOfResultsDone: (number: number) => any;
  dryRun: boolean;
};
const readRows = async ({
  workbook,
  rowProc,
  updateNumberOfResults,
  updateNumberOfResultsDone,
  dryRun,
}: ReadRowsArgs) => {
  const attributes = {
    allowedAttributes: ['email'],
    mandatoryAttributes: ['email'],
  };
  const content = await readFirstWorksheet<UserRecord>(workbook, attributes);

  const usersArray = (await usersDataProvider.getAllListImport(
    'users',
    {}
  )) as { data: User[] };
  const usersMap = Object.fromEntries(
    usersArray.data.map(u => [
      u.email,
      { id: u.id, groups: u.groupsUsers.nodes.map(g => g.group.name) },
    ])
  );

  const groupsArray = (await groupsDataProvider.getAllListImport(
    'groups',
    {}
  )) as { data: Group[] };
  const groupsMap = Object.fromEntries(
    groupsArray.data.map(g => [g.name, true])
  );

  updateNumberOfResults(content.length);

  return await mapLimit(
    content,
    async (obj, index) => {
      updateNumberOfResultsDone(index + 1);
      return rowProc({
        obj,
        currentObject: usersMap[obj.email],
        groupsMap,
        dryRun,
      });
    },
    10
  );
};

type ProcFieldArgs = {
  obj: UserRecord;
  currentObject: UserRecord;
  dryRun: boolean;
};
const procField = async ({ obj, currentObject, dryRun }: ProcFieldArgs) => {
  const existingUserId = currentObject && currentObject.id;

  let groupsForUser: string[] = [];
  if (existingUserId) {
    groupsForUser = currentObject.groups;
  }

  let errorOnDelete = undefined;
  if (!dryRun && existingUserId && !groupsForUser.includes('Administrators')) {
    try {
      await usersDataProvider.delete('users', {
        id: existingUserId,
      });
    } catch (e) {
      errorOnDelete = e;
    }
  }

  return {
    result: obj,
    existingUser: !!existingUserId,
    admin: groupsForUser.includes('Administrators'),
    errorOnDelete,
    groups: groupsForUser,
  };
};

export const processWorkbook = async ({
  dryRun = false,
  ...opts
}: Omit<ReadRowsArgs, 'rowProc'>) => {
  const rows = await readRows({
    ...opts,
    rowProc: procField,
    dryRun,
  });
  console.log(rows);
  return { rows, dryRun };
};

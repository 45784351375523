import { ComponentProps, useState } from 'react';
import {
  SimpleForm,
  FileInput,
  FileField,
  Create,
  SaveButton,
  Toolbar,
  required,
  useNotify,
} from 'react-admin';
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  List,
  ListItem,
  ListItemText,
  Link,
  Typography,
  Button,
  LinearProgress,
  Box,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DeleteIcon from '@mui/icons-material/Delete';
import { Row } from 'dataProviders/components/massDeleter';

type Result = { rows: Row[]; dryRun: boolean };

type ImportToolbarProps = {
  updateNumberOfResults: (input: number) => void;
  updateNumberOfResultsDone: (input: number) => void;
} & ComponentProps<typeof Toolbar>;

const ImportToolbar = ({
  updateNumberOfResults,
  updateNumberOfResultsDone,
  ...props
}: ImportToolbarProps) => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
      }}
      {...props}>
      <SaveButton
        type='button'
        sx={{
          backgroundColor: 'error.main',
          '&:hover': {
            backgroundColor: 'error.dark',
          },
        }}
        icon={<DeleteIcon />}
        label='Delete users'
        transform={data => ({
          updateNumberOfResults,
          updateNumberOfResultsDone,
          ...data,
        })}
      />
      <SaveButton
        type='button'
        sx={{ margin: '10px' }}
        icon={<DeleteIcon />}
        label='Dry run'
        transform={data => ({
          updateNumberOfResults,
          updateNumberOfResultsDone,
          dry_run: true,
          ...data,
        })}
        color='secondary'
      />
    </Toolbar>
  );
};

const validateFile = [required()];

const getStatus = (row: Row) => {
  if (row.errorOnDelete) {
    return String(row.errorOnDelete);
  }
  if (row.existingUser) {
    if (row.admin) {
      return 'Administrator (cannot be deleted)';
    }
    return 'Deleted';
  } else {
    return 'Not deleted as user did not exist';
  }
};

type GroupListCellProps = { groups?: string[] };
const GroupListCell = ({ groups = [] }: GroupListCellProps) => {
  return (
    <TableCell>
      <List dense={true} disablePadding>
        {groups.map(groupName => (
          <ListItem key={groupName} disableGutters sx={{ paddingBlock: 0 }}>
            <ListItemText primary={groupName} sx={{ marginBlock: 0 }} />
          </ListItem>
        ))}
      </List>
    </TableCell>
  );
};

type ResultTableRowProps = { row: Row; index: number; dryRun: boolean };
const ResultTableRow = ({ row, index, dryRun }: ResultTableRowProps) => {
  return (
    <TableRow key={index}>
      <TableCell component='th' scope='row'>
        {row.result.email}
      </TableCell>
      <TableCell>
        {row.admin
          ? 'Administrator (cannot be deleted)'
          : row.existingUser
          ? 'Yes'
          : 'No'}
      </TableCell>
      <GroupListCell groups={row.groups} />
      {dryRun ? null : <TableCell>{getStatus(row)}</TableCell>}
    </TableRow>
  );
};

const chunkyArray = <A,>(array: A[], size: number): A[][] => {
  let result = [];
  for (let i = 0, j = array.length; i < j; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};

type ResultTableRowGroupProps = {
  index: number;
  chunkSize: number;
  max: number;
  dryRun: boolean;
  rows: Row[];
};
const ResultTableRowGroup = ({
  index,
  chunkSize,
  max,
  dryRun,
  rows,
}: ResultTableRowGroupProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        key={index + '-chunk'}
        sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell colSpan={6}>
          <Button
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            Results {index * chunkSize + 1} -{' '}
            {Math.min((index + 1) * chunkSize, max)}
          </Button>
        </TableCell>
      </TableRow>

      {open
        ? rows.map((row, index) => (
            <ResultTableRow
              key={row.result.id ?? index}
              index={index}
              row={row}
              dryRun={dryRun}
            />
          ))
        : null}
    </>
  );
};

type ResultTableProps = { rows: Row[]; dryRun: boolean };
const ResultTable = ({ rows, dryRun }: ResultTableProps) => {
  const chunkSize = 100;
  return (
    <>
      <Typography variant='h6' sx={{ margin: '20px 0' }}>
        {dryRun
          ? 'A dry run was executed, results:'
          : 'The following users have been deleted:'}
      </Typography>
      <Table aria-label='simple table' size='small'>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>User exists?</TableCell>
            <TableCell>Groups</TableCell>
            {dryRun ? null : <TableCell>Status</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {chunkyArray(rows, chunkSize).map((rowsSubset, index) => (
            <ResultTableRowGroup
              key={index}
              index={index}
              chunkSize={chunkSize}
              max={rows.length}
              rows={rowsSubset}
              dryRun={dryRun}
            />
          ))}
        </TableBody>
      </Table>
    </>
  );
};

type LinearProgressWithLabelProps = {
  currentValue: number;
  total: number;
} & ComponentProps<typeof LinearProgress>;
const LinearProgressWithLabel = ({
  currentValue,
  total,
  ...props
}: LinearProgressWithLabelProps) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          {...props}
          sx={{ width: '100%' }}
          variant='determinate'
          value={Math.round((currentValue * 100) / total)}
        />
      </Box>
      <Box sx={{ minWidth: 100 }}>
        <Typography variant='body2'>
          {currentValue} / {total}
        </Typography>
      </Box>
    </Box>
  );
};

export const MassDeleteCreate = () => {
  const notify = useNotify();

  const [results, updateResults] = useState<Row[]>([]);
  const [dryRun, updateDryRun] = useState(false);
  const [numberOfResultsDone, updateNumberOfResultsDone] = useState(0);
  const [numberOfResults, updateNumberOfResults] = useState(0);

  const onSuccess = (data: { results: Result }) => {
    notify(`See the results below`);
    updateResults(data.results.rows);
    updateDryRun(data.results.dryRun);
  };

  const exportFile = async () => {
    const XLSX = await import('xlsx');
    const sampleData = [
      ['email (mandatory)'],
      ['sample email 1'],
      ['sample email 2'],
      ['sample email 3'],
    ];
    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.aoa_to_sheet(sampleData);
    XLSX.utils.book_append_sheet(wb, ws, 'mass delete example');

    XLSX.writeFile(wb, 'mass delete example.xlsx', { compression: true });
  };
  return (
    <Create
      mutationOptions={{
        onSuccess,
      }}>
      <SimpleForm
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
        }}
        toolbar={
          <ImportToolbar
            updateNumberOfResults={updateNumberOfResults}
            updateNumberOfResultsDone={updateNumberOfResultsDone}
          />
        }>
        <Typography variant='h4'>Mass delete users</Typography>
        <Typography>
          Below you can upload an Excel file to mass delete users. The Excel
          should be a list of e-mail addresses of the users that should be
          deleted. You can see the results of this in the table below, both when
          executing an import and when doing a dry run. We always advise doing a
          dry run first so you can see the results. Please note that
          Administrators can not be deleted with this tool. If you want to
          delete an Administrator, please use the users list on the left to
          select the user to first remove them from the Administrators group,
          and then delete their account.
        </Typography>
        <FileInput
          source='file'
          accept='application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          label='Excel file containing users to delete'
          validate={validateFile}>
          <FileField source='src' title='title' />
        </FileInput>
        <Typography>Not sure how to configure your file?</Typography>
        <Link onClick={exportFile}>Download the sample Excel file</Link>
        {numberOfResults !== numberOfResultsDone ? (
          <LinearProgressWithLabel
            currentValue={numberOfResultsDone}
            total={numberOfResults}
          />
        ) : null}

        {results.length > 0 && numberOfResults === numberOfResultsDone ? (
          <ResultTable key='resultTableDelete' rows={results} dryRun={dryRun} />
        ) : null}
      </SimpleForm>
    </Create>
  );
};

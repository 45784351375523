import {
  getTenantsFiltered,
  getTenant,
  addGroupToTenant,
  removeGroupFromTenant,
  getTenantsForGroupFiltered,
} from '../../queries_mutations/tenants';
import { handleReturnError } from '../components/handleReturnError';
import { getContentfulClient } from '../components/contentful';
import { backendClient } from '../components/backend';
import {
  DataProvider,
  GetManyReferenceResult,
  GetManyResult,
  GetOneResult,
} from 'react-admin';
import { CourseType } from 'types/gql/graphql';
import { getList } from './list';
import { transformFromContentful } from './transform';
import { makePlaceholder } from '../utils';
import { truthyFilter } from '../../util/types';

const tenantsPlaceholder = makePlaceholder('tenants');

type AddToGroupParams = {
  tenantId: string;
  id: string;
  group_id: string;
};

type RemoveFromGroupParams = {
  tenantInternalId: string;
  group_id: string;
};

const dataProvider: DataProvider = {
  getList,
  getOne: async (resource, params): Promise<GetOneResult<any>> => {
    console.log('getOne (tenants)', params);

    const contentfulClient = await getContentfulClient();
    return contentfulClient
      .query({
        query: getTenant,
        variables: {
          id: params.id,
        },
      })
      .then(
        data => ({
          data: data.data.academy
            ? transformFromContentful(data.data.academy)
            : undefined,
        }),
        error =>
          handleReturnError({
            error,
            title: 'getOne tenants  error',
            message: 'An error occured while retrieving the tenant',
          })
      );
  },
  getMany: async (resource, params): Promise<GetManyResult<any>> => {
    console.log('getMany (tenants)', params);
    const ids = params.ids.map(i => String(i));
    const contentfulClient = await getContentfulClient();
    return contentfulClient
      .query({
        query: getTenantsFiltered,
        variables: {
          perPage: 1000,
          offset: 0,
          where: { sys: { id_in: ids } },
        },
      })
      .then(
        data => ({
          data:
            data.data.academyCollection?.items
              .filter(truthyFilter)
              .map(transformFromContentful) || [],
        }),
        error =>
          handleReturnError({
            error,
            title: 'getMany tenants error',
            message: 'An error occured while retrieving the tenants',
          })
      );
  },
  getManyReference: async (
    resource,
    params
  ): Promise<GetManyReferenceResult<any>> => {
    console.log('getManyReference (tenants)', params);
    if (params.target === 'group_id') {
      try {
        const data_ids = await backendClient.query({
          query: getTenantsForGroupFiltered,
          variables: {
            perPage: params.pagination.perPage,
            offset: (params.pagination.page - 1) * params.pagination.perPage,
            condition: {
              groupId: String(params.id),
            },
          },
        });

        const ids =
          data_ids.data.tenantsGroups?.nodes
            .map(node => node?.tenantInternalId)
            .filter(truthyFilter) || [];

        const contentfulClient = await getContentfulClient();
        const data = await contentfulClient.query({
          query: getTenantsFiltered,
          variables: {
            perPage: 1000,
            offset: 0,
            where: { sys: { id_in: ids } },
          },
        });

        const items =
          data.data.academyCollection?.items.filter(truthyFilter).map(node => {
            const tenant = transformFromContentful(node);
            const courseType = data_ids.data.tenantsGroups?.nodes
              .filter(truthyFilter)
              .find(element => element.tenantInternalId === tenant.id)?.course;
            return { ...tenant, courseType };
          }) || [];

        return {
          data: items,
          total: data_ids.data.tenantsGroups?.totalCount,
        };
      } catch (error) {
        handleReturnError({
          error,
          title: 'getManyReference tenant error',
          message: 'An error occured while retrieving the associated tenants',
        });
      }
    }
    return Promise.reject('not implemented yet');
  },
  create: tenantsPlaceholder('create'),
  update: tenantsPlaceholder('update'),
  updateMany: tenantsPlaceholder('updateMany'),
  delete: tenantsPlaceholder('delete'),
  deleteMany: tenantsPlaceholder('deleteMany'),
  addToGroup: (resource: string, params: AddToGroupParams) => {
    console.log('addToGroup (tenants)', params);

    return backendClient
      .mutate({
        mutation: addGroupToTenant,
        variables: {
          input: {
            tenantsGroup: {
              tenantId: params.tenantId,
              tenantInternalId: params.id,
              groupId: params.group_id,
              course: CourseType.Accessible, //params.courseType,
            },
          },
        },
      })
      .then(
        data => ({
          data: data,
        }),
        error =>
          handleReturnError({
            error,
            title: 'addToGroup on graphql error',
            message: 'An error occured while processing the addToGroup',
          })
      );
  },
  removeFromGroup: (resource: string, params: RemoveFromGroupParams) => {
    console.log('removeFromGroup (tenants)', params);

    return backendClient
      .mutate({
        mutation: removeGroupFromTenant,
        variables: {
          input: {
            tenantInternalId: params.tenantInternalId,
            groupId: params.group_id,
          },
        },
      })
      .then(
        data => ({
          data: data,
        }),
        error =>
          handleReturnError({
            error,
            title: 'removeFromGroup on graphql error',
            message: 'An error occured while processing the removeFromGroup',
          })
      );
  },
};

export default dataProvider;

import { Auth } from '@aws-amplify/auth';
import {
  awsRegion,
  cognitoDomain,
  identityPoolId,
  userPoolId,
  userPoolWebClientId,
} from 'dataProviders/components/env';
import { AuthProvider } from 'react-admin';

Auth.configure({
  identityPoolId,
  region: awsRegion,
  userPoolId,
  userPoolWebClientId,
  mandatorySignIn: true,
  oauth: {
    domain: cognitoDomain,
    scope: [
      'phone',
      'email',
      'profile',
      'openid',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: window.location.origin,
    redirectSignOut: `${window.location.origin}/login`,
    responseType: 'token',
  },
});

const authProvider: AuthProvider = {
  login: () => {
    return Auth.federatedSignIn();
  },
  logout: () => {
    console.log('logout');
    return Auth.signOut();
  },
  checkError: async (error: string) => {
    console.log('checkError', error);
    if (error === 'NotAuthorizedException') {
      throw new Error(error);
    }
  },
  checkAuth: () => {
    console.log('checkAuth');
    return Auth.currentAuthenticatedUser();
  },
  getPermissions: async () => {
    console.log('getPermissions');
    return 'Unknown method';
  },
  getIdentity: async () => {
    console.log('getIdentity');
    const userData = await Auth.currentAuthenticatedUser().catch(e => {
      console.log('Not signed in');
      throw e;
    });
    return {
      id: userData.username,
      fullName: `${userData.attributes.given_name} ${userData.attributes.family_name}`,
    };
  },
};

export default authProvider;

export function makePlaceholder(resource: string) {
  return (func: string) =>
    async (_resource: string, params: Record<string, any>) => {
      console.log(`${func} (${resource})`, params);
      throw new Error('Not implemented yet');
    };
}

export function filterObject(obj: Record<string, any>, allowedKeys: string[]) {
  return Object.fromEntries(
    Object.entries(obj).filter(([key]) => allowedKeys.includes(key))
  );
}

export function readFileDataAsBinary(file: Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = event => {
      resolve(event.target?.result);
    };

    reader.onerror = err => {
      reject(err);
    };

    reader.readAsBinaryString(file);
  });
}

import {
  useState,
  useEffect,
  useCallback,
  ComponentProps,
  MouseEvent,
  SetStateAction,
  useMemo,
} from 'react';
import {
  useDataProvider,
  SimpleForm,
  Create,
  SaveButton,
  Toolbar,
  useNotify,
  useGetList,
  Confirm,
} from 'react-admin';
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Typography,
  Button,
  Link,
} from '@mui/material';
import { useMutation } from 'react-query';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ActionDelete from '@mui/icons-material/Delete';
import inflection from 'inflection';
import {
  Counts,
  GroupCompareRecord,
  GroupsUsersResultRow,
  GROUP_ATTRIBUTES,
  startSyncComparison,
  SyncCounts,
  SyncState,
  UpdateCounts,
  UserCompareRecord,
  UserGroupSyncState,
  USER_ATTRIBUTES,
} from 'dataProviders/components/syncParts';
import { getErrorMessage } from '../util/types';
import { useAttributes } from 'components/AttributesContext';

const rowStyles = {
  good: { backgroundColor: 'paleGreen' },
  bad: { backgroundColor: 'lightSalmon' },
};

const deleteButtonSX = {
  color: 'error.main',
  '&:hover': {
    backgroundColor: 'error.dark',
    // Reset on mouse devices
    '@media (hover: none)': {
      backgroundColor: 'transparent',
    },
  },
};

function makeUpdateCountsDeep(updateCounts: UpdateCounts) {
  return (
    field: keyof SyncCounts,
    type: keyof Counts,
    value: SetStateAction<number>
  ) =>
    updateCounts(prev => ({
      ...prev,
      [field]: {
        ...prev[field],
        [type]: typeof value === 'function' ? value(prev[field][type]) : value,
      },
    }));
}
export type DeepUpdateCounts = ReturnType<typeof makeUpdateCountsDeep>;

const H6 = ({
  children,
}: Pick<ComponentProps<typeof Typography>, 'children'>) => (
  <Typography children={children} variant='h6' sx={{ margin: '20px 0 ' }} />
);

type SourceSystem = 'backend' | 'cognito';

type ImportToolbarProps = {
  updateCounts: DeepUpdateCounts;
} & Omit<ComponentProps<typeof Toolbar>, 'children'>;
const ImportToolbar = ({ updateCounts, ...props }: ImportToolbarProps) => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
      }}
      {...props}>
      <SaveButton
        label='check sync status'
        type='button'
        alwaysEnable={true}
        transform={data => ({
          updateCounts,
          ...data,
        })}
      />
    </Toolbar>
  );
};

type EntityDeleteButtonProps = {
  resource: string;
  updateChanged: Function;
  identifier: string;
  record: {
    id: string | number;
  };
};
const EntityDeleteButton = ({
  resource,
  updateChanged,
  identifier,
  record,
}: EntityDeleteButtonProps) => {
  const singularResource = inflection.inflect(resource, 1);
  const dataProvider = useDataProvider();

  const notify = useNotify();
  const { mutate: deleteEntity, isLoading: deleteEntityLoading } = useMutation<
    any,
    any,
    MouseEvent<HTMLButtonElement>
  >(() => dataProvider.delete(resource, { id: String(record.id) }), {
    onSuccess: () => {
      notify(`The ${singularResource} has been deleted`);
      setOpen(false);
      updateChanged(true);
    },
    onError: () => {
      notify(`The ${singularResource} has been deleted`);
      setOpen(false);
      updateChanged(true);
    },
  });
  const [isOpen, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button
        size='medium'
        onClick={handleClick}
        disabled={deleteEntityLoading}
        sx={deleteButtonSX}
        startIcon={<ActionDelete />}>
        Delete {singularResource}
      </Button>
      <Confirm
        isOpen={isOpen}
        loading={deleteEntityLoading}
        title={`Delete ${identifier}`}
        content={`Are you sure you want to delete this ${singularResource}?`}
        onConfirm={deleteEntity}
        onClose={handleDialogClose}
      />
    </>
  );
};

type RemoveUserFromGroupButtonProps = {
  updateChanged: Function;
  field?: string;
  identifier?: string;
  groupId: string;
  user: { id: string };
};
const RemoveUserFromGroupButton = ({
  updateChanged,
  groupId,
  user,
}: RemoveUserFromGroupButtonProps) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { mutate: deleteEntity, isLoading: deleteEntityLoading } = useMutation<
    any,
    any,
    MouseEvent<HTMLButtonElement>
  >(
    () =>
      dataProvider.removeFromGroup('users', { id: user.id, group_id: groupId }),
    {
      onSuccess: () => {
        notify(`The user has been removed from the group`);
        setOpen(false);
        updateChanged(true);
      },
      onError: () => {
        notify(`The user has been removed from the group`);
        setOpen(false);
        updateChanged(true);
      },
    }
  );
  const [isOpen, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button
        size='medium'
        onClick={handleClick}
        disabled={deleteEntityLoading}
        sx={deleteButtonSX}
        startIcon={<ActionDelete />}>
        Remove user from group
      </Button>
      <Confirm
        isOpen={isOpen}
        loading={deleteEntityLoading}
        title={`Remove user from group`}
        content={`Are you sure you want to remove this user from the group?`}
        onConfirm={deleteEntity}
        onClose={handleDialogClose}
      />
    </>
  );
};

type UserGroupCompareRow = GroupCompareRecord | UserCompareRecord;
// type Row = UserGroupCompareRow | GroupsUsersResultRow;

type ResultTableRowGroupProps = {
  row: UserGroupCompareRow;
  resource: string;
  index: number;
};
const ResultTableRowGroup = ({ row, resource }: ResultTableRowGroupProps) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const attributeFields = useAttributes();

  const [changed, updateChanged] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    updateChanged(false);
  }, [row]);
  const singularResource = inflection.inflect(resource, 1);

  const attributes = resource === 'users' ? USER_ATTRIBUTES : GROUP_ATTRIBUTES;
  const identifier =
    resource === 'users'
      ? (row.backend && row.backend.email) || (row.cognito && row.cognito.email)
      : (row.backend && row.backend.name) || (row.cognito && row.cognito.name);

  let action = 'decideOnUpdate';
  let entity = row.backend;
  let name = `Go to ${singularResource} edit`;

  if (!row.backend) {
    action = 'createBackendOnly';
    entity = row.cognito;
    name = 'Send to Backend';
  }
  if (!row.cognito) {
    action = 'createCognitoOnly';
    entity = row.backend;
    name = 'Send to Cognito';
  }

  const { mutate: syncToCognito, isLoading: loadingSyncToCognito } =
    useMutation<any, any, MouseEvent<HTMLButtonElement>>(
      () => dataProvider[action](resource, { entity }),
      {
        onSuccess: () => {
          notify(`The ${singularResource} has been sent to ${name}`);
          updateChanged(true);
        },
        onError: error => {
          notify(
            `Error while adding the ${singularResource} to ${name}, ${error?.message}`,
            { type: 'warning' }
          );
        },
      }
    );

  if (!entity) return null;

  const actions =
    row.cognito && row.backend ? (
      <Link
        href={`/${resource}/${entity.id}?syncIssues`}
        target='_blank'
        rel='noopener noreferrer'>
        <Button color='primary' variant='contained'>
          {name}
        </Button>
      </Link>
    ) : (
      <>
        {' '}
        <Button
          color='primary'
          variant='contained'
          onClick={syncToCognito}
          disabled={loadingSyncToCognito}>
          {name}
        </Button>
        <EntityDeleteButton
          updateChanged={updateChanged}
          resource={resource}
          record={entity}
          identifier={identifier}
        />
      </>
    );

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell colSpan={3}>
          <Button
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            {changed ? '[was changed, reload sync state to check again] ' : ''}
            {identifier}
          </Button>
        </TableCell>
        <TableCell>{changed ? null : actions}</TableCell>
      </TableRow>

      {open ? (
        <>
          {attributes.map(attr => {
            const rowStyle =
              row.backend?.[attr] !== row.cognito?.[attr]
                ? { backgroundColor: 'lightSalmon' }
                : {};
            return (
              <TableRow key={attr} style={rowStyle}>
                <TableCell component='th' scope='row'>
                  {attr}
                </TableCell>
                <TableCell>{row.backend?.[attr] ?? '[No value]'}</TableCell>
                <TableCell>{row.cognito?.[attr] ?? '[No value]'}</TableCell>
                <TableCell />
              </TableRow>
            );
          })}
          {resource === 'users'
            ? attributeFields.map(({ id, name }) => {
                const rowStyle =
                  row.backend &&
                  row.cognito &&
                  row.backend.attributes[id] !== row.cognito.attributes[id]
                    ? { backgroundColor: 'lightSalmon' }
                    : {};
                return (
                  <TableRow key={id} style={rowStyle}>
                    <TableCell component='th' scope='row'>
                      {name}
                    </TableCell>
                    <TableCell>
                      {row.backend?.attributes[id] ?? '[No value]'}
                    </TableCell>
                    <TableCell>
                      {row.cognito?.attributes[id] ?? '[No value]'}
                    </TableCell>
                    <TableCell />
                  </TableRow>
                );
              })
            : null}
        </>
      ) : null}
    </>
  );
};

type BulkGroupAssignmentButtonProps = {
  rows: GroupsUsersResultRow[];
  missing?: SourceSystem;
};
function BulkGroupAssignmentButton({
  rows,
  missing,
}: BulkGroupAssignmentButtonProps) {
  const [progress, setProgress] = useState(0);
  const [busy, setBusy] = useState(false);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const resource = 'users';

  const trigger: { name: string | null } = {
    name: null,
  };

  if (missing === 'cognito') {
    trigger.name = `Send all ${rows.length} group assignments to cognito`;
  } else if (missing === 'backend') {
    trigger.name = `Send all ${rows.length} group assignments to backend`;
  } else {
    // Don't do anything!
  }

  const doUpdate = useCallback(async () => {
    setBusy(true);

    const method = dataProvider.addToGroup;

    for (let i = 0; i < rows.length; i += 1) {
      const [group, user] = rows[i];

      try {
        await method(resource, {
          id: user.id,
          group_id: group,
          allowBackendFail: missing === 'cognito',
        });
        setProgress(i => i + 1);
      } catch (error) {
        notify(
          `Error while processing ${user.id} & ${group}, ${getErrorMessage(
            error
          )}`,
          { type: 'warning' }
        );
        setBusy(false);
        return;
      }
    }

    notify(`${rows.length} ${resource} have been processed`);
  }, [rows, dataProvider, notify, missing]);

  if (!trigger.name || rows.length === 0 || progress === rows.length) {
    return null;
  }

  if (busy) {
    trigger.name = `Processed ${progress}/${rows.length} ${resource}`;
  }

  return (
    <Button
      color='primary'
      variant='contained'
      onClick={doUpdate}
      disabled={busy}>
      {trigger.name}
    </Button>
  );
}

type BulkUserButtonProps = {
  rows: UserCompareRecord[];
};
const BulkUserButton = ({ rows }: BulkUserButtonProps) => {
  const [progress, setProgress] = useState(0);
  const [busy, setBusy] = useState(false);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const resource = 'users';

  const summary = rows.reduce(
    (p, { cognito, backend }) => ({
      cognito: Boolean(p.cognito && cognito),
      backend: Boolean(p.backend && backend),
    }),
    { cognito: true, backend: true }
  );

  type TriggerType = {
    field?: SourceSystem;
    action: string | null;
    name: string | null;
  };
  const trigger: TriggerType = {
    action: null,
    name: null,
  };

  if (!summary.backend && !summary.cognito) {
    // Don't do anything
  } else if (!summary.backend) {
    trigger.action = 'createBackendOnly';
    trigger.field = 'cognito';
    trigger.name = `Send all ${rows.length} ${resource} to backend`;
  } else if (!summary.cognito) {
    trigger.action = 'createCognitoOnly';
    trigger.field = 'backend';
    trigger.name = `Send all ${rows.length} ${resource} to cognito`;
  }

  const doUpdate = useCallback(async () => {
    if (!trigger.action || !trigger.field) return;
    setBusy(true);

    const method = dataProvider[trigger.action];

    for (let i = 0; i < rows.length; i += 1) {
      const row = rows[i];
      const entity = row[trigger.field];

      try {
        await method(resource, { entity });
        setProgress(i => i + 1);
      } catch (error) {
        notify(
          `Error while processing ${entity?.email}, ${getErrorMessage(error)}`,
          {
            type: 'warning',
          }
        );
        setBusy(false);
        return;
      }
    }

    notify(`${rows.length} ${resource} have been processed`);
  }, [dataProvider, trigger.action, trigger.field, resource, rows, notify]);

  if (!trigger.name || rows.length === 0 || progress === rows.length) {
    return null;
  }

  if (busy) {
    trigger.name = `Processed ${progress}/${rows.length} ${resource}`;
  }

  return (
    <Button
      color='primary'
      variant='contained'
      onClick={doUpdate}
      disabled={busy}>
      {trigger.name}
    </Button>
  );
};

type ResultTableProps = {
  rows: UserGroupCompareRow[];
  title: string;
  resource: string;
};
const ResultTable = ({ rows, title, resource }: ResultTableProps) => {
  const longTable = rows.length > 200;
  const [showLongTable, setShowLongTable] = useState(false);

  return (
    <>
      <H6>{title}</H6>
      {resource === 'users' ? (
        <BulkUserButton rows={rows as UserCompareRecord[]} />
      ) : null}{' '}
      <DownloadAsJSONButton data={rows} filename={resource}>
        Download data as .json
      </DownloadAsJSONButton>{' '}
      {longTable && !showLongTable ? (
        <Button
          color='primary'
          variant='contained'
          onClick={() => setShowLongTable(true)}>
          Show very long table ({rows.length} {resource})
        </Button>
      ) : null}
      {!longTable || showLongTable ? (
        <Table aria-label='simple table' size='small'>
          <colgroup>
            <col width='25%' />
            <col width='25%' />
            <col width='25%' />
            <col width='25%' />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>Key</TableCell>
              <TableCell>Backend</TableCell>
              <TableCell>Cognito</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <ResultTableRowGroup
                key={row.backend?.id || row.cognito?.id}
                index={index}
                row={row}
                resource={resource}
              />
            ))}
          </TableBody>
        </Table>
      ) : null}
    </>
  );
};

type GroupsUsersResultTableRowGroupProps = {
  row: GroupsUsersResultRow;
  missing: string;
};
const GroupsUsersResultTableRowGroup = ({
  row,
  missing,
}: GroupsUsersResultTableRowGroupProps) => {
  const [changed, updateChanged] = useState(false);
  const dataProvider = useDataProvider();
  useEffect(() => {
    updateChanged(false);
  }, [row]);
  const [group, user] = row;
  const notify = useNotify();
  const { mutate: syncTo, isLoading: loadingSyncTo } = useMutation<
    any,
    any,
    MouseEvent<HTMLButtonElement>
  >(() => dataProvider.addToGroup('users', { id: user.id, group_id: group }), {
    onSuccess: () => {
      notify(`The user has been added to the group`);
      updateChanged(true);
    },
    onError: () => {
      notify(`The user has been added to the group`);
      updateChanged(true);
    },
  });

  const actions = (
    <>
      {' '}
      <Button
        color='primary'
        variant='contained'
        onClick={syncTo}
        disabled={loadingSyncTo}>
        {missing === 'backend' ? 'Add to backend' : 'Add to Cognito'}
      </Button>
      <RemoveUserFromGroupButton
        updateChanged={updateChanged}
        groupId={group}
        user={user}
      />
    </>
  );

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component='th' scope='row'>
          <>
            {changed ? '[reload sync state to check again] ' : ''}
            {group}
          </>
        </TableCell>
        <TableCell scope='row'>{user.email}</TableCell>
        <TableCell scope='row'>
          {missing === 'backend' ? 'no' : 'yes'}
        </TableCell>
        <TableCell scope='row'>
          {missing === 'cognito' ? 'no' : 'yes'}
        </TableCell>
        <TableCell>{changed ? null : actions}</TableCell>
      </TableRow>
    </>
  );
};

type GroupsUsersResultTableProps = {
  rows: GroupsUsersResultRow[];
  title: string;
  resource?: string;
  missing: SourceSystem;
};
const GroupsUsersResultTable = ({
  rows,
  title,
  missing,
  resource,
}: GroupsUsersResultTableProps) => {
  const longTable = rows.length > 200;
  const [showLongTable, setShowLongTable] = useState(false);

  return (
    <>
      <H6>{title}</H6>
      <DownloadAsJSONButton data={rows} filename={resource}>
        Download data as .json
      </DownloadAsJSONButton>{' '}
      <BulkGroupAssignmentButton rows={rows} missing={missing} />{' '}
      {longTable && !showLongTable ? (
        <Button
          color='primary'
          variant='contained'
          onClick={() => setShowLongTable(true)}>
          Show very long table ({rows.length} {resource})
        </Button>
      ) : null}
      {!longTable || showLongTable ? (
        <Table aria-label='simple table' size='small'>
          <colgroup>
            <col width='20%' />
            <col width='20%' />
            <col width='10%' />
            <col width='10%' />
            <col width='40%' />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>Group</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Backend</TableCell>
              <TableCell>Cognito</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <GroupsUsersResultTableRowGroup
                key={`${row[0]}-${row[1].id}`}
                row={row}
                missing={missing}
              />
            ))}
          </TableBody>
        </Table>
      ) : null}
    </>
  );
};

type CountTableRowProps = Pick<ComponentProps<typeof TableRow>, 'sx'> & {
  count: number;
  name: string;
};
const CountTableRow = ({ name, count, sx }: CountTableRowProps) => {
  return (
    <TableRow sx={sx} key={name}>
      <TableCell component='th' scope='row'>
        {name}
      </TableCell>
      <TableCell align='right'>{count}</TableCell>
    </TableRow>
  );
};

type DownloadAsJSONButtonProps = {
  data: any;
  filename?: string;
} & ComponentProps<typeof Button>;
const DownloadAsJSONButton = ({
  children,
  data,
  filename = 'data',
  ...props
}: DownloadAsJSONButtonProps) => {
  function handler() {
    const json = JSON.stringify(data);
    const blob = new Blob([json], { type: 'application/json' });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = filename + '.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <Button color='primary' variant='contained' {...props} onClick={handler}>
      {children}
    </Button>
  );
};

function Compare({ compare }: { compare: Partial<SyncState<any>> }) {
  return (
    <>
      {(compare.cognitoMissing?.length ?? 0) > 0 ? (
        <CountTableRow
          name='Missing from Cognito'
          count={compare.cognitoMissing?.length ?? 0}
          sx={rowStyles.bad}
        />
      ) : null}
      {(compare.backendMissing?.length ?? 0) > 0 ? (
        <CountTableRow
          name='Missing from Backend'
          count={compare.backendMissing?.length ?? 0}
          sx={rowStyles.good}
        />
      ) : null}
      {(compare.notMatching?.length ?? 0) > 0 ? (
        <CountTableRow
          name='Out of sync'
          count={compare.notMatching?.length ?? 0}
          sx={rowStyles.good}
        />
      ) : null}
      {(compare.matching?.length ?? 0) > 0 ? (
        <CountTableRow
          name='Matching / In sync'
          count={compare.matching?.length ?? 0}
          sx={rowStyles.good}
        />
      ) : null}
    </>
  );
}

type ResultStateProps = {
  resource: string;
  compare: Partial<SyncState<UserGroupCompareRow>> | undefined;
};
function ResultState({ resource, compare }: ResultStateProps) {
  if (!compare) return null;
  return (
    <>
      {compare.cognitoMissing?.length ? (
        <ResultTable
          title={`${resource} missing from Cognito`}
          rows={compare.cognitoMissing}
          resource={resource}
        />
      ) : null}
      {compare.backendMissing?.length ? (
        <ResultTable
          title={`${resource} missing from Backend`}
          rows={compare.backendMissing}
          resource={resource}
        />
      ) : null}
      {compare.notMatching?.length ? (
        <ResultTable
          title={`${resource} not matching in attributes`}
          rows={compare.notMatching}
          resource={resource}
        />
      ) : null}
    </>
  );
}

type FullSyncState = {
  users: SyncState<UserCompareRecord>;
  groups: SyncState<GroupCompareRecord>;
  usersGroups: UserGroupSyncState;
};

type ResultsProps = {
  resource: string;
  counts: Counts;
  compare: Partial<SyncState<any>> | undefined;
};
function Results({ resource, compare, counts }: ResultsProps) {
  return (
    <>
      <H6>Results ({resource})</H6>
      <Table size='small'>
        <TableHead>
          <CountTableRow name='Total in Backend' count={counts.backend} />
        </TableHead>
        <TableBody>
          {counts.cognito > 0 ? (
            <CountTableRow
              name='Retrieved from Cognito'
              count={counts.cognito}
            />
          ) : null}
          {!compare ? null : <Compare compare={compare} />}
        </TableBody>
      </Table>
    </>
  );
}

export const SyncCreate = () => {
  const notify = useNotify();

  const [counts, updateCounts] = useState<SyncCounts>({
    users: { cognito: 0, backend: 0 },
    groups: { cognito: 0, backend: 0 },
    usersGroups: { cognito: 0, backend: 0 },
  });
  const deepUpdateCounts = useMemo(
    () => makeUpdateCountsDeep(updateCounts),
    []
  );

  const { total: userTotal } = useGetList('users');
  const { total: groupTotal } = useGetList('groups');

  const [syncState, updateSyncState] = useState<FullSyncState | undefined>();

  const [doneProcessing, updateDoneProcessing] = useState(false);

  useEffect(() => {
    if (userTotal) deepUpdateCounts('users', 'backend', userTotal);
    if (groupTotal) deepUpdateCounts('groups', 'backend', groupTotal);
  }, [deepUpdateCounts, groupTotal, userTotal]);

  type SyncData = {
    results: Awaited<ReturnType<typeof startSyncComparison>>;
  };
  const onSuccess = (data: SyncData) => {
    notify(`See the results below`);

    const { users, groups, usersGroups } = data.results.rows;

    updateSyncState({ users, groups, usersGroups });

    updateDoneProcessing(true);
  };

  return (
    <Create mutationOptions={{ onSuccess }}>
      <SimpleForm
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
        }}
        toolbar={<ImportToolbar updateCounts={deepUpdateCounts} />}>
        <Typography variant='h4'>Sync databases</Typography>
        <Typography>
          For the LMS we use 2 databases, Cognito and RDS. It can happen that we
          have a synchronization issue. On this page you can start the
          comparison of the databases and check the results.
        </Typography>
        <Typography>This process might take a while.</Typography>
        <Results
          resource='users'
          counts={counts.users}
          compare={syncState?.users}
        />
        <Results
          resource='groups'
          counts={counts.groups}
          compare={syncState?.groups}
        />

        {counts.usersGroups.backend ? (
          <>
            <Results
              resource='groups - users connection'
              counts={counts.usersGroups}
              compare={syncState?.usersGroups}
            />
            {doneProcessing ? (
              <Table size='small'>
                <TableBody>
                  <CountTableRow
                    name='Matching / In sync'
                    count={
                      counts.usersGroups.backend -
                      (syncState?.users?.cognitoMissing?.length ?? 0)
                    }
                    sx={rowStyles.good}
                  />
                </TableBody>
              </Table>
            ) : null}
          </>
        ) : null}

        <ResultState compare={syncState?.users} resource='users' />
        <ResultState compare={syncState?.groups} resource='groups' />
        {syncState?.usersGroups.cognitoMissing?.length ? (
          <GroupsUsersResultTable
            key='groupsUsersMissingCognito'
            title={'Group user relations missing in Cognito'}
            rows={syncState.usersGroups.cognitoMissing}
            missing='cognito'
          />
        ) : null}
        {syncState?.usersGroups.backendMissing?.length ? (
          <GroupsUsersResultTable
            key='groupsUsersMissingBackend'
            title={'Group user relations missing in Backend'}
            rows={syncState.usersGroups.backendMissing}
            missing='backend'
          />
        ) : null}
      </SimpleForm>
    </Create>
  );
};

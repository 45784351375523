import { createContext, ReactNode, useContext } from 'react';
import { useQuery } from 'react-query';
import invariant from 'tiny-invariant';
import { Attribute } from 'types/gql/graphql';
import { allAttributesQueryOptions } from 'dataProviders/components/attributes';

type EnabledAttribute = Omit<Attribute, 'nodeId' | 'enabled' | '__typename'>;

const AttributeContext = createContext<EnabledAttribute[] | undefined>(
  undefined
);

export const useAttributes = () => {
  const attributes = useContext(AttributeContext);
  invariant(attributes, 'The AttributeContext is missing');
  return attributes;
};

export function AttributeProvider({ children }: { children: ReactNode }) {
  const { data, isLoading } = useQuery(allAttributesQueryOptions);
  return (
    <AttributeContext.Provider value={isLoading || !data ? [] : data}>
      {children}
    </AttributeContext.Provider>
  );
}

import {
  AdminContext,
  AdminUI,
  defaultI18nProvider,
  localStorageStore,
  Resource,
} from 'react-admin';
import { BrowserRouter } from 'react-router-dom';
import { queryClient } from 'dataProviders/components/queryClient';
import authProvider from './authentication/authProvider';
import LoginPage from './authentication/LoginPage';

import dataProvider from './dataProviders/dataProvider';
import { UserList, UserEdit, UserCreate } from './resources/users';
import { AttributeEdit, AttributeList } from 'resources/attributes';
import { ImportCreate } from './resources/import';
import { MassDeleteCreate } from './resources/massDelete';
import { SyncCreate } from './resources/sync';
import { GroupList, GroupEdit, GroupCreate } from './resources/groups';
import {
  CatalogueItemList,
  CatalogueItemEdit,
} from './resources/catalogueItems';
import { TenantList, TenantEdit } from './resources/tenants';
import { Dashboard } from './components/Dashboard';
import { CustomLayout } from './components/CustomLayout';
import { AttributeProvider } from 'components/AttributesContext';
import {
  userPoolWebClientId,
  cognitoEnabled,
  commit,
  userPoolId,
  version,
  identityPoolId,
} from 'dataProviders/components/env';

const store = localStorageStore();

declare global {
  interface Window {
    komensky: Record<string, any>;
  }
}

const App = () => {
  window.komensky = {
    version,
    commit,
    poolId: userPoolId,
    clientId: userPoolWebClientId,
    identityPoolId,
  };

  return (
    <BrowserRouter>
      <AdminContext
        store={store}
        queryClient={queryClient}
        i18nProvider={defaultI18nProvider}
        authProvider={authProvider}
        dataProvider={dataProvider}>
        <AttributeProvider>
          <AdminUI
            requireAuth
            disableTelemetry
            title={'Komensky User Management'}
            dashboard={Dashboard}
            loginPage={LoginPage}
            layout={CustomLayout}>
            <Resource name='import' create={ImportCreate} />
            <Resource name='mass-delete' create={MassDeleteCreate} />
            {cognitoEnabled ? (
              <Resource name='sync' create={SyncCreate} />
            ) : (
              <></>
            )}
            <Resource
              name='users'
              list={UserList}
              edit={UserEdit}
              create={UserCreate}
            />
            <Resource
              name='groups'
              list={GroupList}
              edit={GroupEdit}
              create={GroupCreate}
            />
            <Resource name='tenants' list={TenantList} edit={TenantEdit} />
            <Resource
              name='catalogue_items'
              list={CatalogueItemList}
              edit={CatalogueItemEdit}
            />
            <Resource
              name='attributes'
              list={AttributeList}
              edit={AttributeEdit}
            />
          </AdminUI>
        </AttributeProvider>
      </AdminContext>
    </BrowserRouter>
  );
};

export default App;

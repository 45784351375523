import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

const TableHeaderCell = styled(TableCell)`
  font-weight: bold;
`;

type Group = any;

export type CatalogueItem = {
  courseType: string;
  id: string;
  catalogue_item_id: string;
  accessible: Group[];
  mandatory: Group[];
};

const AssignedItemsGroupList = ({ groups }: { groups: string[] }) => {
  return (
    <Table aria-label='simple table' size='small'>
      <TableBody>
        {groups.map(name => (
          <TableRow key={name}>
            <TableCell sx={{ paddingLeft: '0', borderBottom: 'none' }}>
              {name}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

type AssignedItemsContentProps = {
  catalogueItemsForUser: CatalogueItem[];
  catalogueItems: CatalogueItem[];
};
const AssignedItemsContent = ({
  catalogueItemsForUser,
  catalogueItems,
}: AssignedItemsContentProps) => (
  <>
    {Object.values(catalogueItemsForUser)
      .sort((a, b) => a.courseType.localeCompare(b.courseType))
      .map(catalogueItem => (
        <TableRow key={catalogueItem.id}>
          <TableCell>
            {
              catalogueItems.find(ci => ci && ci.id === catalogueItem.id)
                ?.catalogue_item_id
            }
          </TableCell>
          <TableCell>{catalogueItem.courseType.toLowerCase()}</TableCell>
          <TableCell>
            <AssignedItemsGroupList groups={catalogueItem.accessible.sort()} />
          </TableCell>
          <TableCell>
            <AssignedItemsGroupList groups={catalogueItem.mandatory.sort()} />
          </TableCell>
        </TableRow>
      ))}
  </>
);

type AssignedItemsTableProps = {
  catalogueItemsForUser: CatalogueItem[];
  catalogueItems: CatalogueItem[];
};
export const AssignedItemsTable = ({
  catalogueItemsForUser,
  catalogueItems,
}: AssignedItemsTableProps) => {
  let content = (
    <TableRow>
      <TableCell colSpan={4}>
        User doesn't have any assigned content based on its current groups.
      </TableCell>
    </TableRow>
  );

  if (
    catalogueItemsForUser &&
    catalogueItems &&
    Object.keys(catalogueItemsForUser).length > 0
  ) {
    content = (
      <AssignedItemsContent
        catalogueItemsForUser={catalogueItemsForUser}
        catalogueItems={catalogueItems}
      />
    );
  }

  return (
    <Table aria-label='simple table' size='small'>
      <colgroup>
        <col width='25%' />
        <col width='25%' />
        <col width='25%' />
        <col width='25%' />
      </colgroup>
      <TableHead>
        <TableRow>
          <TableHeaderCell>Catalogue Item</TableHeaderCell>
          <TableHeaderCell>Course Type</TableHeaderCell>
          <TableHeaderCell>Groups: Accessible</TableHeaderCell>
          <TableHeaderCell>Groups: Mandatory</TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>{content}</TableBody>
    </Table>
  );
};

import { ComponentProps, MouseEvent } from 'react';
import {
  List,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  EditButton,
  DeleteButton,
  SaveButton,
  Toolbar,
  BulkDeleteButton,
  TabbedForm,
  FormTab,
  NumberField,
  Filter,
  useRecordContext,
  Pagination,
  regex,
  required,
  Button,
  useNotify,
  useGetManyReference,
  Identifier,
  useDataProvider,
} from 'react-admin';
import { useMutation } from 'react-query';
import CustomizableDatagrid from '../components/customizableDataGrid/CustomizableDatagrid';
import GroupConnectionComponent from '../components/GroupConnection';
import { Grid, Typography } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import { useSearchParams } from 'react-router-dom';
import { cognitoEnabled } from 'dataProviders/components/env';

const GroupFilter = (
  props: Omit<ComponentProps<typeof Filter>, 'children'>
) => (
  <Filter {...props}>
    <TextInput label='Search' source='q' alwaysOn />
  </Filter>
);

const GroupBulkActionButtons = (
  props: ComponentProps<typeof BulkDeleteButton>
) => <BulkDeleteButton mutationMode='pessimistic' {...props} />;

const GroupDeleteButton = (props: ComponentProps<typeof DeleteButton>) => {
  const record = useRecordContext();
  if (record.id === 'Administrators') {
    return null;
  }
  return (
    <DeleteButton
      mutationMode='pessimistic'
      {...props}
      confirmTitle={`Delete ${record.name}`}
      confirmContent={'Are you sure you want to delete this group?'}
    />
  );
};

const GroupPagination = () => (
  <Pagination rowsPerPageOptions={[10, 20, 50, 100]} />
);

export const groupFields = ({
  sortable = true,
}: { sortable?: boolean } = {}) => {
  return [
    <TextField sortable={sortable} key='name' source='name' label='groupID' />,
    <TextField
      sortable={sortable}
      key='description'
      source='description'
      label='Name & Description'
    />,
    <NumberField sortable={sortable} key='user_count' source='user_count' />,
    <NumberField
      sortable={sortable}
      key='catalogue_item_count'
      source='catalogue_item_count'
    />,
    <NumberField
      sortable={sortable}
      key='tenant_count'
      source='tenant_count'
    />,
  ];
};

export const GroupList = () => {
  return (
    <List
      filters={<GroupFilter />}
      bulkActionButtons={<GroupBulkActionButtons />}
      pagination={<GroupPagination />}
      perPage={50}
      exporter={false}
      sort={{ field: 'name', order: 'ASC' }}>
      <CustomizableDatagrid>
        {groupFields()}
        <EditButton />
        <GroupDeleteButton />
      </CustomizableDatagrid>
    </List>
  );
};

const GroupEditToolbar = (props: ComponentProps<typeof Toolbar>) => (
  <Toolbar
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
    }}
    {...props}>
    <SaveButton />
    <GroupDeleteButton />
  </Toolbar>
);

export const cognitoLabel = (field: string) => {
  if (field === 'name') {
    return 'groupID';
  }
  if (field === 'description') {
    return 'Name & Description';
  }
  field
    .replace(/^custom:([a-z])/gi, (_match, p1) => p1.toUpperCase())
    .replace(/^([a-z])/gi, match => match.toUpperCase())
    .replace(/_/gi, ' ');
};

const validateNoWhiteSpace = regex(
  /^[\p{L}\p{M}\p{S}\p{N}\p{P}]+$/u,
  'The groupID may not contain any spaces or other whitespace characters. Please remove these characters and try again.'
);

const validateName = [
  required('The groupID is required'),
  validateNoWhiteSpace,
];

type CognitoInputsOptions = {
  prefix?: string;
  disabled?: boolean;
};
const cognitoInputs = (
  attrs: string[],
  { prefix, disabled }: CognitoInputsOptions
) => {
  const prefixString = prefix ? `${prefix}.` : '';

  return attrs.map(field => {
    const otherProps: Partial<ComponentProps<typeof TextInput>> = {};
    if (field === 'name') {
      otherProps.validate = validateName;
    }
    return (
      <TextInput
        disabled={disabled}
        fullWidth
        helperText={false}
        key={`${prefixString}${field}`}
        label={cognitoLabel(field)}
        source={`${prefixString}${field}`}
        {...otherProps}
      />
    );
  });
};

type Result<T> = { data: T };

type Invitations = {
  succeeded: string[];
  skipped: string[];
  failed: string[];
};

const ResendInvitationsButton = ({ id }: { id: Identifier }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const { data } = useGetManyReference('users', {
    target: 'group_id',
    id,
    sort: { field: 'id', order: 'DESC' },
    meta: 'groups',
  });
  const ids = data?.map(({ id }) => id) || [];

  const { mutate: inviteUser, isLoading } = useMutation<
    Result<Invitations>,
    Error,
    MouseEvent<HTMLButtonElement>
  >(() => dataProvider.resendInvitations('users', { ids }), {
    onSuccess: ({ data }) => {
      notify(
        `The users have been invited: ${data.succeeded.length} successful, ${data.skipped.length} skipped, ${data.failed.length} failed`
      );
    },
    onError: error =>
      notify(`Error while inviting users: ${error.message}`, {
        type: 'warning',
      }),
  });

  return cognitoEnabled ? (
    <Button
      label='Resend invitations to non-validated users'
      disabled={isLoading}
      onClick={inviteUser}
      variant='contained'
      color='primary'
      size='medium'>
      <MailIcon />
    </Button>
  ) : null;
};

const GroupEditForm = () => {
  const { id } = useRecordContext();
  return (
    <TabbedForm toolbar={<GroupEditToolbar />}>
      <FormTab label='summary'>
        <GroupEditSummaryTab />
      </FormTab>
      <FormTab label='users'>
        <Grid container spacing={2} direction='row-reverse'>
          <Grid item>
            <ResendInvitationsButton id={id} />
          </Grid>
        </Grid>
        <GroupConnectionComponent
          listGroups={false}
          otherResource='users'
          otherResourceName='user'
          displayAttribute='email'
        />
      </FormTab>
      <FormTab label='catalogue items'>
        <GroupConnectionComponent
          listGroups={false}
          otherResource='catalogue_items'
          otherResourceName='catalogue_item'
          displayAttribute='catalogue_item_id'
        />
      </FormTab>
      <FormTab label='tenants'>
        <GroupConnectionComponent
          listGroups={false}
          otherResource='tenants'
          otherResourceName='tenant'
          displayAttribute='title'
        />
      </FormTab>
    </TabbedForm>
  );
};

const GroupEditTitle = () => {
  const record = useRecordContext();
  return <>{record ? `[Group] ${record.name}` : 'Group'}</>;
};

const GroupEditSummaryTab = () => {
  const [searchParams] = useSearchParams();
  const syncIssues = searchParams.has('syncIssues');
  if (syncIssues) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography>Cognito values</Typography>
          {cognitoInputs(['name'], { disabled: true })}
          {cognitoInputs(['description'], {})}
        </Grid>
        <Grid item xs={6}>
          <Typography>Backend values</Typography>
          {cognitoInputs(['name', 'description'], {
            prefix: 'backend',
            disabled: true,
          })}
        </Grid>
      </Grid>
    );
  }
  return (
    <>
      {cognitoInputs(['name'], { disabled: true })}
      {cognitoInputs(['description'], {})}
    </>
  );
};

export const GroupEdit = () => {
  return (
    <Edit title={<GroupEditTitle />}>
      <GroupEditForm />
    </Edit>
  );
};

export const GroupCreate = () => (
  <Create>
    <SimpleForm warnWhenUnsavedChanges>
      {cognitoInputs(['name'], {})}
      {cognitoInputs(['description'], {})}
    </SimpleForm>
  </Create>
);

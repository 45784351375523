import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Title, useGetList } from 'react-admin';
import {
  Box,
  CardHeader,
  Grid,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Typography,
} from '@mui/material';
import { useQuery } from '@apollo/client';
import { backendClient } from '../dataProviders/components/backend';
import { getGenericStats } from '../queries_mutations/generic';
import { ReactElement } from 'react';

export const Dashboard = () => {
  const { data: stats } = useQuery(getGenericStats, { client: backendClient });
  const tenantItemCount = useGetList('tenants');
  const catalogueItemCount = useGetList('catalogue_items');

  const statsRows = [
    { key: 'Users count', value: stats?.users?.totalCount },
    { key: 'Groups count', value: stats?.groups?.totalCount },
    { key: 'Tenant count', value: tenantItemCount.total },
    { key: 'Catalogue item count', value: catalogueItemCount.total },
  ];

  return (
    <Box m={3}>
      <Grid container sx={{ flexGrow: 1 }} spacing={2}>
        <Title title='Komensky Admin Dashboard' />
        <Grid item xs={12}>
          <CardWithTitle header='Welcome to the Komensky Admin interface'>
            <Typography style={{ padding: '0 20px' }}>
              Here you can manage your company's users and groups that have
              access to the Komensky platform, assign relevant content to
              groups, and decide which content can be shown per tenant.
            </Typography>
          </CardWithTitle>
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          <CardWithTitle header='Statistics'>
            <KeyValueTable rows={statsRows} />
          </CardWithTitle>
        </Grid>
      </Grid>
    </Box>
  );
};

type CardWithTitleProps = {
  header: string;
  children?: ReactElement;
};
const CardWithTitle = ({ header, children }: CardWithTitleProps) => (
  <Card>
    <CardContent>
      <>
        <CardHeader title={header} />
        {children}
      </>
    </CardContent>
  </Card>
);

type KeyValueTableRow = {
  key: string;
  value: string | number | undefined;
};
type KeyValueTableProps = {
  rows: KeyValueTableRow[];
};
const KeyValueTable = ({ rows }: KeyValueTableProps) => (
  <Table aria-label='simple table'>
    <TableBody>
      {rows.map(row => (
        <TableRow key={row.key}>
          <TableCell component='th' scope='row'>
            {row.key}
          </TableCell>
          <TableCell>{row.value}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

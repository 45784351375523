import { backendClient } from 'dataProviders/components/backend';
import { getContentfulClient } from 'dataProviders/components/contentful';
import { handleReturnError } from 'dataProviders/components/handleReturnError';
import {
  GetCatalogueItemsFilteredQueryVariables,
  GetCatalogueItemsQueryVariables,
  LearningConceptOrder,
} from 'types/gql/graphql';
import {
  getCatalogueItems,
  getCatalogueItemsFiltered,
  getCatalogueItemsForGroup,
  getCatalogueItemsGroupCounts,
} from 'queries_mutations/catalogue_items';
import { GetListParams, GetListResult } from 'react-admin';
import { truthyFilter } from '../../util/types';
import { transformFromContentful } from './transform';

function order(field: string, direction: string): LearningConceptOrder {
  const fieldMap = {
    id: 'SysId',
    catalogue_item_id: 'Id',
    type: 'Type',
    title: 'Title',
  } as const;
  const dir = direction === 'ASC' ? 'Asc' : 'Desc';
  if (field in fieldMap) {
    return LearningConceptOrder[
      `${fieldMap[field as keyof typeof fieldMap]}${dir}`
    ];
  }
  return LearningConceptOrder.IdAsc;
}

async function getNotInGroup(params: GetListParams) {
  const data_ids_not_in = await backendClient.query({
    query: getCatalogueItemsForGroup,
    variables: {
      condition: {
        groupId: params.filter.not_in_group,
      },
    },
  });

  const ids = data_ids_not_in.data.catalogueItemsGroups?.nodes
    .map(node => node?.catalogueItemId)
    .filter(truthyFilter);

  const filter: GetCatalogueItemsFilteredQueryVariables['where'] = {
    sys: { id_not_in: ids },
  };

  if (params.filter.catalogue_item_id) {
    filter.id_contains = params.filter.catalogue_item_id;
  }

  const contentfulClient = await getContentfulClient();
  const data_catalogue_items = await contentfulClient.query({
    query: getCatalogueItemsFiltered,
    variables: {
      perPage: params.pagination.perPage,
      offset: (params.pagination.page - 1) * params.pagination.perPage,
      order: order(params.sort.field, params.sort.order),
      where: filter,
    },
  });
  return {
    data:
      data_catalogue_items.data.learningConceptCollection?.items
        .filter(truthyFilter)
        .map(transformFromContentful) || [],
    total: data_catalogue_items.data.learningConceptCollection?.total,
  };
}

async function getPossiblyFilteredCatalogueItems(params: GetListParams) {
  const contentfulClient = await getContentfulClient();
  const variables:
    | GetCatalogueItemsQueryVariables
    | GetCatalogueItemsFilteredQueryVariables = {
    perPage: params.pagination.perPage,
    offset: (params.pagination.page - 1) * params.pagination.perPage,
    order: order(params.sort.field, params.sort.order),
  };

  if (!params.filter.q) {
    const data = await contentfulClient.query({
      query: getCatalogueItems,
      variables,
    });
    return data.data.learningConceptCollection;
  }

  const data = await contentfulClient.query({
    query: getCatalogueItemsFiltered,
    variables: {
      perPage: params.pagination.perPage,
      offset: (params.pagination.page - 1) * params.pagination.perPage,
      order: order(params.sort.field, params.sort.order),
      where: {
        OR: [
          { sys: { id_contains: params.filter.q } },
          { id_contains: params.filter.q },
          { title_contains: params.filter.q },
          { description_contains: params.filter.q },
          { type_contains: params.filter.q },
        ],
      },
    },
  });
  return data.data.learningConceptCollection;
}

export async function getList(
  resource: string,
  params: GetListParams
): Promise<GetListResult<any>> {
  console.log('getList (catalogue items)', params);

  try {
    if (params.filter.not_in_group) {
      return getNotInGroup(params);
    }

    const { items = [], total = 0 } =
      (await getPossiblyFilteredCatalogueItems(params)) || {};

    const ids = items.filter(truthyFilter).map(i => i.sys.id);
    const data_catalogue_item_group_counts = await backendClient.query({
      query: getCatalogueItemsGroupCounts,
      variables: {
        filter: {
          catalogueItemId: {
            in: ids,
          },
        },
      },
    });

    const mapped = items.filter(truthyFilter).map(i => {
      return {
        groupCount: data_catalogue_item_group_counts.data.catalogueItems?.nodes
          .filter(truthyFilter)
          .find(j => j.catalogueItemId === i.sys.id)?.groupCount,
        ...i,
      };
    });

    return {
      data: mapped.map(transformFromContentful),
      total,
    };
  } catch (error) {
    return handleReturnError({
      error,
      title: 'getList catalogue items error',
      message: 'An error occured while retrieving the catalogue items list',
    });
  }
}

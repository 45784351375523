import { getAttributesQuery } from 'queries_mutations/attributes';
import { truthyFilter } from '../../util/types';
import { backendClient } from './backend';
import { queryClient } from './queryClient';

export const allAttributesQueryOptions = {
  queryKey: ['attributes', 'allEnabled'],
  queryFn: () =>
    backendClient
      .query({
        query: getAttributesQuery,
        variables: { filter: { enabled: { equalTo: true } } },
      })
      .then(
        ({ data }) =>
          data.attributes?.nodes
            .filter(node => node?.enabled)
            .filter(truthyFilter) ?? []
      ),
  staleTime: 3.6e6,
  cacheTime: 3.6e6,
};

export const allAttributesQuery = async () => {
  return queryClient.fetchQuery(allAttributesQueryOptions);
};

import { graphql } from 'types/gql';
import {
  ContentfulArgs,
  ContentfulItem,
  ContentfulResponse,
} from 'dataProviders/components/contentful';

// CONTENTFUL
export type ContentfulTenant = ContentfulItem<{
  academyId: string;
  academyTitle: string;
  isPrimary: boolean;
  groupCount?: number;
}>;
/*
  Contentful [Query]
  Get all tenants, with pagination and ordering
*/
export type CtfTenantArgs = ContentfulArgs<ContentfulTenant>;
export type GetTenantsData = ContentfulResponse<
  'academyCollection',
  ContentfulTenant
>;
export type GetTenantsArgs = Pick<
  CtfTenantArgs,
  'perPage' | 'offset' | 'order'
>;
export const getTenants = graphql(`
  query GetTenants($perPage: Int!, $offset: Int!, $order: [AcademyOrder]) {
    academyCollection(limit: $perPage, skip: $offset, order: $order) {
      total
      items {
        academyId
        academyTitle
        isPrimary
        sys {
          id
        }
      }
    }
  }
`);

/*
  Contentful [Query]
  Get all tenants, with pagination, ordering and filtering
*/
export type GetTenantsFilteredArgs = Pick<
  CtfTenantArgs,
  'perPage' | 'offset' | 'order' | 'where'
>;
export const getTenantsFiltered = graphql(`
  query GetTenantsFiltered(
    $perPage: Int!
    $offset: Int!
    $where: AcademyFilter!
    $order: [AcademyOrder]
  ) {
    academyCollection(
      limit: $perPage
      skip: $offset
      where: $where
      order: $order
    ) {
      total
      items {
        academyId
        academyTitle
        isPrimary
        sys {
          id
        }
      }
    }
  }
`);

/*
  Contentful [Query]
  Get a single tenant by id
*/
export type GetTentantType = {
  academy: ContentfulTenant;
};
export type GetTenantArgs = Pick<CtfTenantArgs, 'id'>;
export const getTenant = graphql(`
  query GetTenant($id: String!) {
    academy(id: $id) {
      academyId
      academyTitle
      isPrimary
      sys {
        id
      }
    }
  }
`);

// BACKEND

/*
  Backend [Query]
  Get all tenants with group counts
*/
export const getTenantsGroupCounts = graphql(`
  query GetTenantsGroupCounts($filter: TenantFilter!) {
    tenants(filter: $filter) {
      nodes {
        tenantId
        tenantInternalId
        groupCount
      }
    }
  }
`);

/*
  Backend [Query]
  Get all groups for one or more tenants, specified in the filter, with pagination
*/
export const getGroupsForTenantQuery = graphql(`
  query GetGroupsInTenant(
    $perPage: Int!
    $offset: Int!
    $condition: TenantsGroupCondition!
  ) {
    tenantsGroups(first: $perPage, offset: $offset, condition: $condition) {
      totalCount
      nodes {
        course
        group {
          name
          description
          groupsUsers {
            totalCount
          }
          tenantsGroups {
            totalCount
          }
          catalogueItemsGroups {
            totalCount
          }
        }
      }
    }
  }
`);

/*
  Backend [Query]
  Get all tenants for one or more groups, specified in the filter
*/
export const getTenantsForGroup = graphql(`
  query GetTenantsForGroup($condition: TenantsGroupCondition!) {
    tenantsGroups(condition: $condition) {
      totalCount
      nodes {
        tenantId
        tenantInternalId
      }
    }
  }
`);

/*
  Backend [Query]
  Get all tenants for one or more groups, specified in the filter, with pagination
*/
export const getTenantsForGroupFiltered = graphql(`
  query GetTenantsForGroupFiltered(
    $perPage: Int!
    $offset: Int!
    $condition: TenantsGroupCondition!
  ) {
    tenantsGroups(first: $perPage, offset: $offset, condition: $condition) {
      totalCount
      nodes {
        course
        tenantId
        tenantInternalId
      }
    }
  }
`);

/*
  Backend [Mutation]
  Add a group to a tenant
*/
export const addGroupToTenant = graphql(`
  mutation AddGroupToTenant($input: CreateTenantsGroupInput!) {
    createTenantsGroup(input: $input) {
      tenantsGroup {
        tenantId
        tenantInternalId
        groupId
        course
      }
    }
  }
`);

/*
  Backend [Mutation]
  Remove a group from a tenant
*/
export const removeGroupFromTenant = graphql(`
  mutation RemoveGroupFromTenant($input: RemoveGroupFromTenantInput!) {
    removeGroupFromTenant(input: $input) {
      tenantsGroup {
        tenantInternalId
        groupId
      }
    }
  }
`);

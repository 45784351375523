import { ComponentProps, MouseEvent } from 'react';
import {
  BulkDeleteButton,
  Button,
  DeleteButton,
  useDataProvider,
  useGetOne,
  useNotify,
  useRecordContext,
  useUnselectAll,
} from 'react-admin';
import { useMutation } from 'react-query';
import MailIcon from '@mui/icons-material/Mail';

type UserDeleteButtonProps = ComponentProps<typeof DeleteButton> & {
  type?: string;
  field?: any;
};
export const UserDeleteButton = ({
  type,
  field,
  ...props
}: UserDeleteButtonProps) => {
  const record = useRecordContext();
  return (
    <DeleteButton
      mutationMode='pessimistic'
      confirmTitle={`Delete ${record.email}`}
      confirmContent={'Are you sure you want to delete this user?'}
      {...props}
    />
  );
};

type ResendInvitationsButtonProps = {
  selectedIds?: string[];
};

type ResendInvitationsResult = {
  data: {
    succeeded: string[];
    skipped: string[];
    failed: string[];
  };
};

export const ResendInvitationsButton = ({
  selectedIds,
}: ResendInvitationsButtonProps) => {
  const notify = useNotify();
  const unselectAll = useUnselectAll('users');
  const dataProvider = useDataProvider();

  const { mutate: inviteUser, isLoading } = useMutation<
    ResendInvitationsResult,
    Error,
    MouseEvent<HTMLButtonElement>
  >(() => dataProvider.resendInvitations('users', { ids: selectedIds }), {
    onSuccess: ({ data }) => {
      notify(
        `The users have been invited: ${data.succeeded.length} successful, ${data.skipped.length} skipped, ${data.failed.length} failed`
      );
      unselectAll();
    },
    onError: error =>
      notify(`Error while inviting users: ${error.message}`, {
        type: 'warning',
      }),
  });

  return (
    <Button
      label='Resend invitations'
      disabled={isLoading}
      onClick={inviteUser}>
      <MailIcon />
    </Button>
  );
};

type UserBulkActionButtonsProps = ComponentProps<
  typeof ResendInvitationsButton
> &
  ComponentProps<typeof BulkDeleteButton>;
export const UserBulkActionButtons = (props: UserBulkActionButtonsProps) => (
  <>
    <BulkDeleteButton mutationMode='optimistic' {...props} />
    <ResendInvitationsButton {...props} />
  </>
);

type ResendInvitationButtonProps = {
  id: string | number;
};
export const ResendInvitationButton = (props: ResendInvitationButtonProps) => {
  type User = {
    id: string;
    status: string;
  };
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { data, isLoading } = useGetOne<User>('users', {
    id: String(props.id),
  });
  const { mutate: inviteUser, isLoading: isMutating } = useMutation<
    void,
    Error,
    MouseEvent<HTMLButtonElement>
  >(() => dataProvider.inviteCognito('users', data), {
    onSuccess: () => {
      notify('The user has been invited');
    },
    onError: error =>
      notify(`Error while inviting user: ${error.message}`, {
        type: 'warning',
      }),
  });

  return !isLoading && data?.status === 'FORCE_CHANGE_PASSWORD' ? (
    <Button
      startIcon={<MailIcon />}
      variant='outlined'
      size='medium'
      label='Resend invitation'
      onClick={inviteUser}
      disabled={isMutating}
    />
  ) : (
    <></>
  );
};

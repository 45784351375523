import { cloneElement, ComponentProps, ReactElement, MouseEvent } from 'react';
import { styled } from '@mui/material/styles';
import {
  List,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  EditButton,
  Filter,
  SaveButton,
  Toolbar,
  Button,
  useNotify,
  useRefresh,
  SingleFieldList,
  ChipField,
  TabbedForm,
  FormTab,
  Pagination,
  TopToolbar,
  CreateButton,
  ExportButton,
  useGetMany,
  ArrayField,
  useRecordContext,
  useDataProvider,
  BooleanField,
  Labeled,
} from 'react-admin';
import { useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import CustomizableDatagrid from '../../components/customizableDataGrid/CustomizableDatagrid';
import DisableIcon from '@mui/icons-material/HighlightOff';
import EnableIcon from '@mui/icons-material/CheckBoxRounded';
import GroupConnectionComponent from '../../components/GroupConnection';
import { Link as RouterLink } from 'react-router-dom';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid, Typography } from '@mui/material';
import { AssignedItemsTable, CatalogueItem } from './subviews';
import {
  ResendInvitationButton,
  UserBulkActionButtons,
  UserDeleteButton,
} from './buttons';
import { userExporter } from './exporter';
import { useAttributes } from 'components/AttributesContext';
import { cognitoEnabled } from 'dataProviders/components/env';

type ResponseType<T> = {
  data: T[];
};

const UserFilter = (props: Omit<ComponentProps<typeof Filter>, 'children'>) => (
  <Filter {...props}>
    <TextInput label='Search' source='q' alwaysOn />
  </Filter>
);

type UserListActionsProps = {
  filters?: ReactElement;
};
const UserListActions = ({ filters }: UserListActionsProps) => {
  return (
    <TopToolbar>
      <>
        {filters ? cloneElement(filters, { context: 'button' }) : null}
        <CreateButton />
        <ExportButton maxResults={1000000} />
        <Button component={RouterLink} to='/import/create' label='Import Excel'>
          <ImportExportIcon />
        </Button>
        <Button
          sx={{ color: 'error.main' }}
          component={RouterLink}
          to='/mass-delete/create'
          label='Mass Delete'>
          <DeleteIcon />
        </Button>
      </>
    </TopToolbar>
  );
};

const UserPagination = () => (
  <Pagination rowsPerPageOptions={[10, 20, 50, 100]} />
);

export const UserList = () => {
  const attributes = useAttributes();
  return (
    <List
      filters={<UserFilter />}
      bulkActionButtons={<UserBulkActionButtons />}
      pagination={<UserPagination />}
      perPage={50}
      actions={<UserListActions />}
      exporter={userExporter}
      sort={{ field: 'email', order: 'ASC' }}>
      <CustomizableDatagrid>
        <TextField source='email' label='Email' />
        <TextField source='givenName' label='Given name' />
        <TextField source='familyName' label='Family name' />
        {attributes.map(field => (
          <TextField
            key={`attributes.${field.id}`}
            source={`attributes.${field.id}`}
            sortable={false}
            label={field.name ?? field?.cognitoName ?? field.id}
          />
        ))}
        <ArrayField label='Groups' sortable={false} source='groups'>
          <SingleFieldList>
            <ChipField source='groupId' />
          </SingleFieldList>
        </ArrayField>
        <EditButton />
        <UserDeleteButton />
      </CustomizableDatagrid>
    </List>
  );
};

const StyledToolbar = styled(Toolbar)`
  display: 'flex';
  justify-content: 'flex-start';
`;

const StyledButton = styled(Button)`
  margin: 10px;
`;

const UserEditToolbar = (props: ComponentProps<typeof Toolbar>) => {
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const { mutate: enableUser, isLoading: enableLoading } = useMutation<
    void,
    Error,
    MouseEvent<HTMLButtonElement>
  >(() => dataProvider.enableUser('users', { id: record.id }), {
    onSuccess: () => {
      refresh();
      notify('The user has been enabled');
    },
    onError: error =>
      notify(`Error while enabling user: ${error.message}`, {
        type: 'warning',
      }),
  });
  const { mutate: disableUser, isLoading: disableLoading } = useMutation<
    void,
    Error,
    MouseEvent<HTMLButtonElement>
  >(() => dataProvider.disableUser('users', { id: record.id }), {
    onSuccess: () => {
      refresh();
      notify('The user has been disabled');
    },
    onError: error =>
      notify(`Error while disabling user: ${error.message}`, {
        type: 'warning',
      }),
  });

  return (
    <StyledToolbar {...props}>
      <SaveButton />
      {cognitoEnabled ? (
        record.enabled ? (
          <StyledButton
            startIcon={<DisableIcon />}
            variant='outlined'
            size='medium'
            label='Disable user'
            onClick={disableUser}
            disabled={disableLoading}
          />
        ) : (
          <StyledButton
            startIcon={<EnableIcon />}
            variant='outlined'
            size='medium'
            label='Enable user'
            onClick={enableUser}
            disabled={enableLoading}
          />
        )
      ) : null}

      <UserDeleteButton />
    </StyledToolbar>
  );
};

const UserDetailForm = () => {
  const { id } = useRecordContext();
  const attributes = useAttributes();
  const [searchParams] = useSearchParams();
  const syncIssues = searchParams.has('syncIssues');
  if (syncIssues) {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography>Cognito values</Typography>
            <TextInput fullWidth disabled source='email' label='Email' />
            <TextInput
              fullWidth
              disabled
              source='given_name'
              label='Given name'
            />
            <TextInput
              fullWidth
              disabled
              source='family_name'
              label='Family name'
            />
            {attributes.map(field => (
              <TextInput
                fullWidth
                disabled
                key={`attributes.${field.cognitoName}`}
                source={`attributes.${field.cognitoName}`}
                label={field.name ?? field?.cognitoName ?? field.id}
              />
            ))}
            <TextInput disabled fullWidth source='status' />
            <TextInput disabled fullWidth source='enabled' />
            <TextInput disabled fullWidth source='id' />
          </Grid>
          <Grid item xs={6}>
            <Typography>Backend values</Typography>
            <TextInput
              fullWidth
              disabled
              source='backend.email'
              label='Email'
            />
            <TextInput
              fullWidth
              disabled
              source='backend.givenName'
              label='Given name'
            />
            <TextInput
              fullWidth
              disabled
              source='backend.familyName'
              label='Family name'
            />
            {attributes.map(field => (
              <TextInput
                fullWidth
                disabled
                source={`backend.attributes.${field.id}`}
                label={field.name ?? field?.cognitoName ?? field.id}
              />
            ))}
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <>
      <TextInput fullWidth source='id' disabled helperText={false} />
      <TextInput fullWidth source='email' label='Email' helperText={false} />
      <TextInput
        fullWidth
        source='givenName'
        label='Given name'
        helperText={false}
      />
      <TextInput
        fullWidth
        source='familyName'
        label='Family name'
        helperText={false}
      />
      {attributes.map(field => (
        <TextInput
          fullWidth
          helperText={field?.description ?? false}
          source={`attributes.${field.id}`}
          label={field.name ?? field?.cognitoName ?? field.id}
        />
      ))}
      {cognitoEnabled ? (
        <>
          <Labeled label='Cognito Status'>
            <TextField source='status' />
          </Labeled>
          <Labeled label='Enabled'>
            <BooleanField source='enabled' />
          </Labeled>
          <ResendInvitationButton id={id!} />
        </>
      ) : null}
    </>
  );
};

const UserEditForms = () => {
  const { id } = useRecordContext();
  const dataProvider = useDataProvider();
  const { data: { data: catalogueItemsForUser } = {} } = useQuery(
    ['users', id, 'catalog-items'],
    () => dataProvider.getAllCatalogueItems('users', { id })
  );
  const catalogueItemIds = Object.keys(catalogueItemsForUser || {});
  const { data: catalogueItems } = useGetMany(
    'catalogue_items',
    { ids: catalogueItemIds },
    {
      enabled: Boolean(catalogueItemIds.length),
    }
  ) as unknown as ResponseType<CatalogueItem>;

  return (
    <TabbedForm toolbar={<UserEditToolbar />}>
      <FormTab label='user information'>
        <UserDetailForm />
      </FormTab>
      <FormTab label='groups'>
        <GroupConnectionComponent
          listGroups={true}
          otherResource='users'
          otherResourceName='user'
          displayAttribute='name'
        />
      </FormTab>
      <FormTab label='assigned items'>
        <AssignedItemsTable
          catalogueItemsForUser={catalogueItemsForUser}
          catalogueItems={catalogueItems}
        />
      </FormTab>
    </TabbedForm>
  );
};

const UserEditTitle = () => {
  const record = useRecordContext();
  return (
    <>
      {record
        ? `[User] ${record.givenName} ${record.familyName} (${record.email})`
        : 'User'}
    </>
  );
};

export const UserEdit = () => {
  return (
    <Edit title={<UserEditTitle />}>
      <UserEditForms />
    </Edit>
  );
};

export const UserCreate = () => {
  const attributes = useAttributes();
  return (
    <Create>
      <SimpleForm warnWhenUnsavedChanges>
        <TextInput fullWidth source='email' label='Email' />
        <TextInput fullWidth source='givenName' label='Given name' />
        <TextInput fullWidth source='familyName' label='Family name' />
        {attributes.map(field => (
          <TextInput
            fullWidth
            source={`attributes.${field.id}`}
            label={field.name ?? field?.cognitoName ?? field.id}
          />
        ))}
      </SimpleForm>
    </Create>
  );
};

import invariant from 'tiny-invariant';

function getInvariantEnv(attr: string, error: string = `%var should be set`) {
  const envVar = process.env[attr];
  invariant(envVar, error.replace('%var', attr));
  return envVar;
}

export const userPoolId = getInvariantEnv(
  'REACT_APP_AWS_COGNITO_USER_POOL_ID',
  'REACT_APP_AWS_COGNITO_USER_POOL_ID should be set'
);
export const awsRegion = getInvariantEnv('REACT_APP_AWS_REGION');
export const ssmEnv = getInvariantEnv('REACT_APP_AWS_SSM_ENV');
export const appsyncGraphQLUrl = getInvariantEnv(
  'REACT_APP_BACKEND_APPSYNC_URL'
);
export const identityPoolId = getInvariantEnv(
  'REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID'
);
export const userPoolWebClientId = getInvariantEnv(
  'REACT_APP_AWS_COGNITO_CLIENT_ID'
);
export const cognitoDomain = getInvariantEnv('REACT_APP_AWS_COGNITO_DOMAIN');

export const version = process.env.REACT_APP_VERSION;
export const commit = process.env.REACT_APP_COMMIT;

export const cognitoEnabled =
  process.env.REACT_APP_AWS_COGNITO_ENABLED !== 'false';

import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { appsyncSignedFetch } from './appsyncSignedFetch';
import { appsyncGraphQLUrl } from './env';

export const backendClient = new ApolloClient({
  link: new HttpLink({
    uri: appsyncGraphQLUrl,
    fetch: appsyncSignedFetch,
  }),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
  },
});

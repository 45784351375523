import {
  getAttributeLists,
  EXTRA_FIELDS,
  MANDATORY_MARKER,
} from 'dataProviders/components/excel';
import { Exporter } from 'react-admin';

type CognitoUser = {
  email: string;
  id: string;
  status: string;
};

type ResponseType<T> = {
  data: T[];
};

type DBUser = {
  email: string;
  id: string;
  groupsUsers: {
    nodes: {
      group: {
        name: string;
      };
    }[];
  };
};

const getUserInfo = (
  record: Record<string, any>,
  groups: string[],
  cognitoStatus: string,
  attributeIds: string[]
) => {
  const attributeValues = attributeIds.map(id => record?.attributes[id] ?? '');
  return [
    record.email,
    record.givenName,
    record.familyName,
    ...attributeValues,
    groups?.filter(i => i !== 'Administrators').join('|'),
    cognitoStatus,
  ];
};

export const userExporter: Exporter = async (
  records: Record<string, any>[],
  fetchRelatedRecords,
  dataProvider
) => {
  const XLSX = await import('xlsx');
  const { attributeMapping, allowedAttributes, mandatoryAttributes } =
    await getAttributeLists({ extraFields: [...EXTRA_FIELDS, 'status'] });
  const attributeIds = Object.values(attributeMapping);

  const headers = allowedAttributes.map(name =>
    mandatoryAttributes.includes(name) ? `${name}${MANDATORY_MARKER}` : name
  );

  const usersArray = (await dataProvider.getAllListImport(
    'users',
    {}
  )) as ResponseType<DBUser>;
  const usersMap = Object.fromEntries(
    usersArray.data.map(u => [
      u.id,
      {
        id: u.id,
        email: u.email,
        groups: u.groupsUsers.nodes.map(g => g.group.name),
      },
    ])
  );

  const cognitoUsers = (await dataProvider.getAllFromCognito(
    'users',
    {}
  )) as ResponseType<CognitoUser>;
  const cognitoUsersMap = Object.fromEntries(
    cognitoUsers.data.map(u => [
      u.id,
      { email: u.email, id: u.id, status: u.status },
    ])
  );

  const values = records.map(record =>
    getUserInfo(
      record,
      usersMap[record.id]?.groups,
      cognitoUsersMap[record.id]?.status,
      attributeIds
    )
  );

  var wb = XLSX.utils.book_new();
  var ws = XLSX.utils.aoa_to_sheet([headers].concat(values));
  XLSX.utils.book_append_sheet(wb, ws, 'users');

  XLSX.writeFile(wb, 'usersExport.xlsx', { compression: true });
};

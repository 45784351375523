import invariant from 'tiny-invariant';
import { backendClient } from 'dataProviders/components/backend';
import { getContentfulClient } from 'dataProviders/components/contentful';
import { handleReturnError } from 'dataProviders/components/handleReturnError';
import {
  AcademyOrder,
  GetTenantsFilteredQueryVariables,
  GetTenantsQueryVariables,
} from 'types/gql/graphql';
import {
  getTenants,
  getTenantsFiltered,
  getTenantsForGroup,
  getTenantsGroupCounts,
} from 'queries_mutations/tenants';
import { truthyFilter } from '../../util/types';
import { transformFromContentful } from './transform';
import { GetListResult } from 'react-admin';

function order(field: string, direction: string): AcademyOrder {
  const dir = direction === 'ASC' ? 'Asc' : 'Desc';
  const fieldMap = {
    id: 'AcademyId',
    tenantId: 'AcademyId',
    tenantInternalId: 'SysId',
    title: 'AcademyTitle',
  } as const;
  if (field in fieldMap) {
    return AcademyOrder[`${fieldMap[field as keyof typeof fieldMap]}${dir}`];
  }
  return AcademyOrder.AcademyIdAsc;
}

type GetListParams = {
  sort: {
    field: string;
    order: string;
  };
  filter?: Record<string, string>;
  pagination: {
    page: number;
    perPage: number;
  };
};

async function getListNotIn(params: GetListParams) {
  invariant(
    params.filter?.not_in_group,
    'Should not attempt not_in_group filter without not_in_group set'
  );
  const data_ids_not_in = await backendClient.query({
    query: getTenantsForGroup,
    variables: {
      condition: {
        groupId: params.filter.not_in_group,
      },
    },
  });

  const ids =
    data_ids_not_in?.data?.tenantsGroups?.nodes
      .map(node => node?.tenantInternalId)
      .filter(truthyFilter) || [];

  const filter: GetTenantsFilteredQueryVariables['where'] = {};
  filter.sys = { id_not_in: ids };

  if (params?.filter?.groupId) {
    filter.academyTitle_contains = params.filter.groupId;
  }

  const contentfulClient = await getContentfulClient();
  const data_tenants = await contentfulClient.query({
    query: getTenantsFiltered,
    variables: {
      perPage: params.pagination.perPage,
      offset: (params.pagination.page - 1) * params.pagination.perPage,
      order: order(params.sort.field, params.sort.order),
      where: filter,
    },
  });
  return {
    data:
      data_tenants?.data?.academyCollection?.items
        .filter(truthyFilter)
        .map(transformFromContentful) || [],
    total: data_tenants?.data?.academyCollection?.total,
  };
}

async function getPossiblyFilteredTenants(params: GetListParams) {
  const contentfulClient = await getContentfulClient();
  const variables: GetTenantsQueryVariables | GetTenantsFilteredQueryVariables =
    {
      perPage: params.pagination.perPage,
      offset: (params.pagination.page - 1) * params.pagination.perPage,
      order: order(params.sort.field, params.sort.order),
    };
  if (!params.filter?.q) {
    const data_tenants = await contentfulClient.query({
      query: getTenants,
      variables,
    });
    return data_tenants.data.academyCollection;
  }
  const data_tenants = await contentfulClient.query({
    query: getTenantsFiltered,
    variables: {
      ...variables,
      where: {
        OR: [
          { sys: { id_contains: params.filter.q } },
          { academyId_contains: params.filter.q },
          { academyTitle_contains: params.filter.q },
        ],
      },
    },
  });
  return data_tenants.data.academyCollection;
}

// Garbage any type to make React Admin happy
export async function getList(
  resource: string,
  params: GetListParams
): Promise<GetListResult<any>> {
  console.log('getList (tenants)', params);

  try {
    if (params.filter?.not_in_group) {
      return getListNotIn(params);
    }

    const tenantData = await getPossiblyFilteredTenants(params);
    const { items = [], total = 0 } = tenantData || {};
    const ids = tenantData?.items.filter(truthyFilter).map(i => i.sys.id);
    const data_tenant_group_counts = await backendClient.query({
      query: getTenantsGroupCounts,
      variables: {
        filter: {
          tenantInternalId: {
            in: ids,
          },
        },
      },
    });

    const mapped = items.filter(truthyFilter).map(ctfTenant => {
      return {
        groupCount: data_tenant_group_counts.data.tenants?.nodes
          .filter(truthyFilter)
          .find(rel => rel.tenantInternalId === ctfTenant.sys.id)?.groupCount,
        ...ctfTenant,
      };
    });

    return {
      data: mapped.map(transformFromContentful),
      total,
    };
  } catch (error) {
    return handleReturnError({
      error,
      title: 'getList tenants error',
      message: 'An error occured while retrieving the tenants list',
    });
  }
}

import {
  DataProvider,
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import { filterObject, makePlaceholder } from './utils';
import {
  createAttributeMutation,
  getAttributesQuery,
  updateAttributeMutation,
} from 'queries_mutations/attributes';
import { backendClient } from 'dataProviders/components/backend';
import { cognitoClient } from 'dataProviders/components/cognito';
import { queryClient } from 'dataProviders/components/queryClient';
import invariant from 'tiny-invariant';
import { DescribeUserPoolCommand } from '@aws-sdk/client-cognito-identity-provider';
import { v4 as uuid } from 'uuid';
import { truthyFilter } from '../util/types';
import { userPoolId } from './components/env';

const attributePlaceholder = makePlaceholder('attributes');

async function getList(
  resource: string,
  params: Partial<GetListParams>
): Promise<GetListResult<any>> {
  const attributes = await backendClient.query({
    query: getAttributesQuery,
    variables: { filter: null },
  });
  return {
    total: attributes.data.attributes?.totalCount ?? 0,
    data: attributes.data.attributes?.nodes ?? [],
  };
}

async function getOne(
  resource: string,
  params: GetOneParams
): Promise<GetOneResult<any>> {
  const attributes = await backendClient.query({
    query: getAttributesQuery,
    variables: { filter: { id: { equalTo: String(params.id) } } },
  });
  const [attribute] = attributes.data.attributes?.nodes ?? [];
  invariant(attribute, `No attribute found for id ${params.id}`);
  return { data: attribute };
}

async function update(
  resource: string,
  params: UpdateParams
): Promise<UpdateResult<any>> {
  const allowList = ['key', 'name', 'description', 'enabled', 'requiredImport'];
  const patch = filterObject(params.data, allowList);
  await backendClient.mutate({
    mutation: updateAttributeMutation,
    variables: { input: { id: String(params.id), patch } },
  });
  queryClient.invalidateQueries(['attributes']);
  return { data: params.data };
}

async function getAllFromCognito(resource: string, params: {}): Promise<any> {
  const attributes = await backendClient.query({
    query: getAttributesQuery,
    variables: { filter: null },
  });
  const existingFields =
    attributes.data.attributes?.nodes
      .map(attribute => attribute?.cognitoName)
      .filter(truthyFilter) ?? [];

  const client = await cognitoClient();
  const command = new DescribeUserPoolCommand({ UserPoolId: userPoolId });
  const result = await client.send(command);
  if (!result?.UserPool?.SchemaAttributes) return;
  const data: string[] = [];
  for (const attribute of result.UserPool.SchemaAttributes) {
    if (attribute.Name?.slice(0, 7) !== 'custom:') continue;
    if (!attribute.Mutable) continue;
    if (existingFields.includes(attribute.Name)) continue;

    const attributeInput = {
      id: uuid(),
      cognitoName: attribute.Name,
      key: attribute.Name.slice(7),
      name: attribute.Name.slice(7),
      description: null,
      enabled: true,
      requiredImport: Boolean(attribute.Required),
    };
    await backendClient.mutate({
      mutation: createAttributeMutation,
      variables: { input: { attribute: attributeInput } },
    });
    data.push(attribute.Name);
  }
  queryClient.invalidateQueries(['attributes']);
  return { data };
}

const dataProvider: DataProvider = {
  getList,
  getOne,
  getMany: attributePlaceholder('getMany'),
  getManyReference: attributePlaceholder('getManyReference'),
  create: attributePlaceholder('create'),
  update,
  updateMany: attributePlaceholder('updateMany'),
  delete: attributePlaceholder('delete'),
  deleteMany: attributePlaceholder('deleteMany'),
  getAllFromCognito,
};

export default dataProvider;

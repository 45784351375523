import { ComponentProps } from 'react';
import {
  List,
  TextField,
  TextInput,
  Filter,
  Pagination,
  minLength,
  Edit,
  TabbedForm,
  FormTab,
  EditButton,
  Toolbar,
  NumberField,
  useRecordContext,
  BooleanField,
  Labeled,
} from 'react-admin';
import CustomizableDatagrid from '../components/customizableDataGrid/CustomizableDatagrid';
import GroupConnectionComponent from '../components/GroupConnection';

const TenantFilter = (
  props: Omit<ComponentProps<typeof Filter>, 'children'>
) => (
  <Filter {...props}>
    <TextInput label='Search' source='q' alwaysOn validate={[minLength(2)]} />
  </Filter>
);

const TenantPagination = () => (
  <Pagination rowsPerPageOptions={[10, 20, 50, 100]} />
);

export const tenantFields = ({
  sortable = true,
}: { sortable?: boolean } = {}) => {
  return [
    <TextField sortable={sortable} key='tenantId' source='tenantId' />,
    <TextField
      sortable={sortable}
      key='tenantInternalId'
      source='tenantInternalId'
    />,
    <TextField sortable={sortable} key='title' source='title' />,
    <TextField key='isPrimary' source='isPrimary' sortable={false} />,
  ];
};

export const TenantList = () => {
  return (
    <List
      filters={<TenantFilter />}
      pagination={<TenantPagination />}
      perPage={50}
      bulkActionButtons={false}
      exporter={false}
      sort={{ field: 'title', order: 'ASC' }}>
      <CustomizableDatagrid>
        {tenantFields()}
        <NumberField source='groupCount' sortable={false} />
        <EditButton sx={{ float: 'right' }} />
      </CustomizableDatagrid>
    </List>
  );
};

const TenantEditToolbar = () => <Toolbar>&nbsp;</Toolbar>;

const TenantEditTitle = () => {
  const record = useRecordContext();
  return <>{record ? `[Tenant] ${record.title}` : 'Tenant'}</>;
};

export const TenantEdit = () => {
  return (
    <Edit title={<TenantEditTitle />}>
      <TabbedForm toolbar={<TenantEditToolbar />}>
        <FormTab label='summary'>
          <Labeled label='Academy Id'>
            <TextField source='tenantId' />
          </Labeled>
          <Labeled label='Contentful Id'>
            <TextField source='tenantInternalId' />
          </Labeled>
          <Labeled label='Title'>
            <TextField source='title' />
          </Labeled>
          <Labeled label='Is Primary Tenant'>
            <BooleanField source='isPrimary' looseValue={true} />
          </Labeled>
        </FormTab>
        <FormTab label='groups'>
          <GroupConnectionComponent
            listGroups={true}
            otherResource='tenants'
            otherResourceName='tenant'
            displayAttribute='name'
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

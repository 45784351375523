export type GenericTenant = {
  sys: { id: string };
  academyId?: string | null;
  academyTitle?: string | null;
  isPrimary?: boolean | null;
  groupCount?: number | null;
};

export const transformFromContentful = (tenant: GenericTenant) => ({
  id: tenant.sys.id,
  tenantInternalId: tenant.sys.id,
  tenantId: tenant.academyId,
  title: tenant.academyTitle,
  isPrimary: tenant.isPrimary,
  groupCount: tenant.groupCount || 0,
});

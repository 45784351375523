import { ChangeEvent } from 'react';

import { FieldTitle } from 'react-admin';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import IconClose from '@mui/icons-material/Close';
import Button from '@mui/material/Button';

type SelectionDialogProps = {
  columns?: { label?: string; source: string }[];
  selection?: Record<string, boolean>;
  onClose: () => any;
  resource?: string;
  onColumnClicked: (column: string) => void;
};
export default function SelectionDialog({
  columns = [],
  selection = {},
  onClose,
  resource,
  onColumnClicked,
}: SelectionDialogProps) {
  const columnClickHandler = ({
    currentTarget: { value: columnName },
  }: ChangeEvent<HTMLInputElement>) => {
    onColumnClicked(columnName);
  };

  return (
    <Dialog
      maxWidth='xs'
      aria-labelledby='ra-columns-dialog-title'
      onClose={onClose}
      open>
      <DialogTitle id='ra-columns-dialog-title'>Configuration</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map(({ source, label }) => (
            <FormControlLabel
              key={source}
              control={
                <Checkbox
                  checked={Boolean(selection[source])}
                  onChange={columnClickHandler}
                  value={source}
                />
              }
              label={
                <FieldTitle label={label} source={source} resource={resource} />
              }
            />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          <IconClose />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

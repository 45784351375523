import { Auth } from '@aws-amplify/auth';
import { Credentials } from '@aws-sdk/types';

export function awsCredentials() {
  return Auth.currentCredentials().then(
    ({ accessKeyId, sessionToken, secretAccessKey }): Credentials => ({
      accessKeyId,
      sessionToken,
      secretAccessKey,
    })
  );
}

import {
  getCatalogueItemsFiltered,
  getCatalogueItem,
  addGroupToCatalogueItem,
  removeGroupFromCatalogueItem,
  getCatalogueItemsForGroupFiltered,
} from '../../queries_mutations/catalogue_items';
import { handleReturnError } from '../components/handleReturnError';
import { getContentfulClient } from '../components/contentful';
import { backendClient } from '../components/backend';
import {
  DataProvider,
  GetManyReferenceResult,
  GetManyResult,
  GetOneResult,
} from 'react-admin';
import { makePlaceholder } from '../utils';
import { truthyFilter } from '../../util/types';
import { CourseType } from 'types/gql/graphql';
import { transformFromContentful } from './transform';
import { getList } from './list';
export { CourseType } from 'types/gql/graphql';
const catalogueItemsPlaceholder = makePlaceholder('catalogue items');

type AddToGroupParams = {
  id: string;
  group_id: string;
  courseType: CourseType;
};

type RemoveFromGroupParams = {
  id: string;
  group_id: string;
};

const dataProvider: DataProvider = {
  getList,
  getOne: async (resource, params): Promise<GetOneResult<any>> => {
    console.log('getOne (catalogue items)', params);
    try {
      const contentfulClient = await getContentfulClient();
      const data = await contentfulClient.query({
        query: getCatalogueItem,
        variables: {
          id: params.id,
        },
      });
      return {
        data: data.data.learningConcept
          ? transformFromContentful(data.data.learningConcept)
          : null,
      };
    } catch (error) {
      return handleReturnError({
        error,
        title: 'getOne catalogue items  error',
        message: 'An error occured while retrieving the catalogue item',
      });
    }
  },
  getMany: async (resource, params): Promise<GetManyResult<any>> => {
    console.log('getMany (catalogue items)', params);
    try {
      const contentfulClient = await getContentfulClient();
      const data = await contentfulClient.query({
        query: getCatalogueItemsFiltered,
        variables: {
          perPage: 1000,
          offset: 0,
          where: { sys: { id_in: params.ids.map(String) } },
        },
      });
      return {
        data:
          data.data.learningConceptCollection?.items
            .filter(truthyFilter)
            .map(transformFromContentful) || [],
      };
    } catch (error) {
      return handleReturnError({
        error,
        title: 'getMany group error',
        message: 'An error occured while retrieving the groups',
      });
    }
  },
  getManyReference: async (
    resource,
    params
  ): Promise<GetManyReferenceResult<any>> => {
    console.log('getManyReference (catalogue items)', params);

    if (params.target === 'group_id') {
      try {
        const data_ids = await backendClient.query({
          query: getCatalogueItemsForGroupFiltered,
          variables: {
            perPage: params.pagination.perPage,
            offset: (params.pagination.page - 1) * params.pagination.perPage,
            condition: {
              groupId: String(params.id),
            },
          },
        });
        const ids = data_ids.data.catalogueItemsGroups?.nodes
          .map(node => node?.catalogueItemId)
          .filter(truthyFilter);
        const filter = { sys: { id_in: ids } };
        const contentfulClient = await getContentfulClient();
        const contentfulData = await contentfulClient.query({
          query: getCatalogueItemsFiltered,
          variables: {
            perPage: 1000,
            offset: 0,
            where: filter,
          },
        });
        const data =
          data_ids.data.catalogueItemsGroups?.nodes
            .filter(truthyFilter)
            .map(node => {
              const catalogue_item =
                contentfulData.data.learningConceptCollection?.items
                  .filter(truthyFilter)
                  .find(element => node.catalogueItemId === element.sys.id);
              const returnItem = catalogue_item
                ? transformFromContentful(catalogue_item)
                : {
                    id: node.catalogueItemId,
                    catalogue_item_id: 'Not available in Contentful',
                  };
              return { ...returnItem, courseType: node.course };
            }) || [];
        return {
          data,
          total: data_ids.data.catalogueItemsGroups?.totalCount,
        };
      } catch (error) {
        return handleReturnError({
          error,
          title: 'getManyReference catalogue item error',
          message:
            'An error occured while retrieving the associated catalogue items',
        });
      }
    }
    return Promise.reject('not implemented yet');
  },
  create: catalogueItemsPlaceholder('create'),
  update: catalogueItemsPlaceholder('update'),
  updateMany: catalogueItemsPlaceholder('updateMany'),
  delete: catalogueItemsPlaceholder('delete'),
  deleteMany: catalogueItemsPlaceholder('deleteMany'),
  addToGroup: (resource: string, params: AddToGroupParams) => {
    console.log('addToGroup (catalogue_items)', params);

    return backendClient
      .mutate({
        mutation: addGroupToCatalogueItem,
        variables: {
          input: {
            catalogueItemsGroup: {
              catalogueItemId: params.id,
              groupId: params.group_id,
              course: params.courseType,
            },
          },
        },
      })
      .then(
        data => ({
          data: data,
        }),
        error =>
          handleReturnError({
            error,
            title: 'addToGroup on graphql error',
            message: 'An error occured while processing the addToGroup',
          })
      );
  },
  removeFromGroup: (resource: string, params: RemoveFromGroupParams) => {
    console.log('removeFromGroup (catalogue_items)', params);

    return backendClient
      .mutate({
        mutation: removeGroupFromCatalogueItem,
        variables: {
          input: {
            catalogueItemId: params.id,
            groupId: params.group_id,
          },
        },
      })
      .then(
        data => ({
          data: data,
        }),
        error =>
          handleReturnError({
            error,
            title: 'removeFromGroup on graphql error',
            message: 'An error occured while processing the removeFromGroup',
          })
      );
  },
};

export default dataProvider;

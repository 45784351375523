import { GroupsOrderBy } from 'types/gql/graphql';

export function order(field: string, direction: string): GroupsOrderBy {
  const dir = direction === 'ASC' ? 'Asc' : 'Desc';
  const fieldMap = {
    id: 'Name',
    name: 'Name',
    description: 'Description',
    user_count: 'GroupsUsersByGroupIdCount',
    catalogue_item_count: 'CatalogueItemsGroupsByGroupIdCount',
    tenant_count: 'TenantsGroupsByGroupIdCount',
  } as const;
  if (field in fieldMap) {
    return GroupsOrderBy[`${fieldMap[field as keyof typeof fieldMap]}${dir}`];
  }
  return GroupsOrderBy.NameAsc;
}

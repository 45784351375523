import { ComponentProps, useState } from 'react';
import {
  SimpleForm,
  FileInput,
  FileField,
  Create,
  SaveButton,
  Toolbar,
  required,
  useNotify,
} from 'react-admin';
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  List,
  ListItem,
  ListItemText,
  Link,
  Typography,
  Button,
  LinearProgress,
  Box,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Row,
  GroupResult,
  GroupResultType,
} from '../dataProviders/components/importer';
import { getAttributeLists } from '../dataProviders/components/excel';

type Result = { rows: Row[]; dryRun: boolean };

type ImportToolbarProps = {
  updateNumberOfResults: (input: number) => void;
  updateNumberOfResultsDone: (input: number) => void;
} & ComponentProps<typeof Toolbar>;

const ImportToolbar = ({
  updateNumberOfResults,
  updateNumberOfResultsDone,
  ...props
}: ImportToolbarProps) => {
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'flex-start' }} {...props}>
      <SaveButton
        type='button'
        label='import'
        transform={data => ({
          updateNumberOfResults,
          updateNumberOfResultsDone,
          ...data,
        })}
      />
      <SaveButton
        sx={{ margin: '10px' }}
        label='Dry run'
        transform={data => ({
          updateNumberOfResults,
          updateNumberOfResultsDone,
          dry_run: true,
          ...data,
        })}
        type='button'
        color='secondary'
      />
    </Toolbar>
  );
};

const validateFile = [required()];

const getStatus = (row: Row) => {
  if (row.errorOnUpsert) {
    return row.errorOnUpsert.name;
  }
  if (row.existingUser) {
    return 'Updated';
  }
  return 'Created';
};

type GroupListCellArgs = {
  groups: GroupResult[];
  state: GroupResultType;
};
const GroupListCell = ({ groups = [], state }: GroupListCellArgs) => {
  return (
    <TableCell>
      <List dense={true} disablePadding>
        {groups
          .filter(([, status]) => status === state)
          .map(([groupName]) => (
            <ListItem key={groupName} disableGutters sx={{ paddingBlock: 0 }}>
              <ListItemText primary={groupName} sx={{ marginBlock: 0 }} />
            </ListItem>
          ))}
      </List>
    </TableCell>
  );
};

type ResultTableRowProps = {
  row: Row;
  index: number;
  dryRun: boolean;
};
const ResultTableRow = ({ row, index, dryRun }: ResultTableRowProps) => {
  return (
    <TableRow key={index}>
      <TableCell component='th' scope='row'>
        {row.result.email}
      </TableCell>
      {dryRun ? <TableCell>{`${row.existingUser}`}</TableCell> : null}
      <GroupListCell groups={row.groups} state='new' />
      <GroupListCell groups={row.groups} state='existing' />
      <GroupListCell groups={row.groups} state='removed' />
      <GroupListCell groups={row.groups} state='does not exist' />
      {dryRun ? null : <TableCell>{getStatus(row)}</TableCell>}
    </TableRow>
  );
};

const chunkyArray = <T,>(array: T[], size: number): T[][] => {
  let result = [];
  for (let i = 0, j = array.length; i < j; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};

type ResultTableRowGroupProps = {
  index: number;
  chunkSize: number;
  max: number;
  dryRun: boolean;
  rows: Row[];
};
const ResultTableRowGroup = ({
  index,
  chunkSize,
  max,
  dryRun,
  rows,
}: ResultTableRowGroupProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        key={index + '-chunk'}
        sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell colSpan={6}>
          <Button
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            Results {index * chunkSize + 1} -{' '}
            {Math.min((index + 1) * chunkSize, max)}
          </Button>
        </TableCell>
      </TableRow>

      {open
        ? rows.map((row, index) => (
            <ResultTableRow
              key={row.result.id ?? index}
              index={index}
              row={row}
              dryRun={dryRun}
            />
          ))
        : null}
    </>
  );
};

const ResultTable = ({ rows, dryRun }: Result) => {
  const chunkSize = 100;
  return (
    <>
      <Typography variant='h6' sx={{ margin: '20px 0' }}>
        {dryRun
          ? 'A dry run was executed, results:'
          : 'The following users have been imported:'}
      </Typography>
      <Table aria-label='simple table' size='small'>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            {dryRun ? <TableCell>User already exists?</TableCell> : null}
            <TableCell>New groups</TableCell>
            <TableCell>Groups without change</TableCell>
            <TableCell>Deleted groups</TableCell>
            <TableCell>New groups that do not exist</TableCell>
            {dryRun ? null : <TableCell>Status</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {chunkyArray(rows, chunkSize).map((rowsSubset, index) => (
            <ResultTableRowGroup
              key={index}
              index={index}
              chunkSize={chunkSize}
              max={rows.length}
              rows={rowsSubset}
              dryRun={dryRun}
            />
          ))}
        </TableBody>
      </Table>
    </>
  );
};

type LinearProgressWithLabelProps = {
  currentValue: number;
  total: number;
} & ComponentProps<typeof LinearProgress>;
const LinearProgressWithLabel = ({
  currentValue,
  total,
  ...props
}: LinearProgressWithLabelProps) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          {...props}
          sx={{ width: '100%' }}
          variant='determinate'
          value={Math.round((currentValue * 100) / total)}
        />
      </Box>
      <Box sx={{ minWidth: 100 }}>
        <Typography variant='body2'>
          {currentValue} / {total}
        </Typography>
      </Box>
    </Box>
  );
};

const createExampleFile = async () => {
  const XLSX = await import('xlsx');
  const { allowedAttributes, mandatoryAttributes } = await getAttributeLists();

  const headers = allowedAttributes.map(columnName =>
    mandatoryAttributes.includes(columnName)
      ? `${columnName} (mandatory)`
      : columnName
  );
  const values = allowedAttributes.map(columnName =>
    columnName === 'groups' ? `GroupA|GroupB` : `sample ${columnName}`
  );
  const sampleData = [headers, values];
  var wb = XLSX.utils.book_new();
  var ws = XLSX.utils.aoa_to_sheet(sampleData);
  XLSX.utils.book_append_sheet(wb, ws, 'importExample');

  XLSX.writeFile(wb, 'importExample.xlsx', { compression: true });
};

export const ImportCreate = () => {
  const notify = useNotify();

  const [results, updateResults] = useState<Row[]>([]);
  const [dryRun, updateDryRun] = useState(false);
  const [numberOfResultsDone, updateNumberOfResultsDone] = useState(0);
  const [numberOfResults, updateNumberOfResults] = useState(0);
  const onSuccess = (data: { results: Result }) => {
    notify(`See the results below`);
    updateResults(data.results.rows);
    updateDryRun(data.results.dryRun);
  };

  const onError = (error: unknown) => {
    if (error instanceof Error) {
      notify(error.message, { type: 'error' });
      return;
    }
    throw error;
  };

  return (
    <Create
      mutationOptions={{
        onSuccess,
        onError,
      }}>
      <SimpleForm
        sx={{ display: 'flex', justifyContent: 'flex-start' }}
        toolbar={
          <ImportToolbar
            updateNumberOfResults={updateNumberOfResults}
            updateNumberOfResultsDone={updateNumberOfResultsDone}
          />
        }>
        <Typography variant='h4'>Import Users</Typography>
        <Typography>
          Below you can upload an Excel file. The users in the file will be
          updated based on the values provided. Any missing mandatory values
          will lead to no update on the user being done.
        </Typography>
        <Typography>
          You can also change which groups a user is assigned to. You can see
          the results of this in the table below, both when executing an import
          and when doing a dry run. We always advise doing a dry run first so
          you can see the results. Note that new groups cannot be created with
          this tool, so if users are assigned to a group that does not exist
          yet, you should first create the new group in the groups list on the
          left.
        </Typography>
        <Typography>
          The Administrators group membership cannot be updated with this tool.
          Any users that are Administrators will stay this way and no other
          users will be made Administrators. If you want to add or remove a user
          from the Administrators group, please use the users list on the left
          to select the user and edit their membership directly.
        </Typography>
        <FileInput
          source='file'
          accept='application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          label='Excel file to import'
          validate={validateFile}>
          <FileField source='src' title='title' />
        </FileInput>
        <Typography>Not sure how to configure your file?</Typography>
        <Link onClick={createExampleFile}>Download the sample Excel file</Link>
        {numberOfResults !== numberOfResultsDone ? (
          <LinearProgressWithLabel
            currentValue={numberOfResultsDone}
            total={numberOfResults}
          />
        ) : null}

        {results.length > 0 && numberOfResults === numberOfResultsDone ? (
          <ResultTable key='testing' rows={results} dryRun={dryRun} />
        ) : null}
      </SimpleForm>
    </Create>
  );
};

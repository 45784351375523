type GenericCognitoGroup = {
  GroupName?: string | null | undefined;
  Description?: string | null | undefined;
};
type GenericBackendGroup = {
  name?: string | null | undefined;
  description?: string | null | undefined;
  groupsUsers?:
    | {
        totalCount: number;
      }
    | undefined;
  catalogueItemsGroups?:
    | {
        totalCount: number;
      }
    | undefined;
  tenantsGroups?:
    | {
        totalCount: number;
      }
    | undefined;
};

export const transformFromCognito = (group: GenericCognitoGroup) => ({
  id: group.GroupName,
  name: group.GroupName,
  description: group.Description || null,
});

export const transformFromBackend = (group: GenericBackendGroup) => ({
  id: group.name,
  name: group.name,
  description: group.description,
  user_count: group?.groupsUsers?.totalCount,
  catalogue_item_count: group?.catalogueItemsGroups?.totalCount,
  tenant_count: group?.tenantsGroups?.totalCount,
});

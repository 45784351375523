import { graphql } from 'types/gql';

export const getAttributesQuery = graphql(`
  query GetAttributes($filter: AttributeFilter) {
    attributes(filter: $filter) {
      nodes {
        id
        cognitoName
        key
        name
        description
        enabled
        requiredImport
      }
      totalCount
    }
  }
`);

export const createAttributeMutation = graphql(`
  mutation CreateAttribute($input: CreateAttributeInput!) {
    createAttribute(input: $input) {
      attribute {
        id
      }
    }
  }
`);

export const updateAttributeMutation = graphql(`
  mutation UpdateAttribute($input: UpdateAttributeInput!) {
    updateAttribute(input: $input) {
      attribute {
        id
      }
    }
  }
`);

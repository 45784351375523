import {
  CreateGroupCommand,
  UpdateGroupCommand,
  GetGroupCommand,
  DeleteGroupCommand,
  ListGroupsCommand,
  ListUsersInGroupCommand,
  ListGroupsCommandOutput,
} from '@aws-sdk/client-cognito-identity-provider';
import {
  getGroupsFilteredQuery,
  getGroupsForUserQuery,
  createGroup,
  deleteGroup,
  updateGroup,
  getAllGroupsImportQuery,
  getUsersGroupsQuery,
} from '../../queries_mutations/groups';
import { getGroupsForCatalogueItemQuery } from '../../queries_mutations/catalogue_items';
import { getGroupsForTenantQuery } from '../../queries_mutations/tenants';
import { handleReturnError } from '../components/handleReturnError';
import { backendClient } from '../components/backend';
import { cognitoClient } from '../components/cognito';
import { getAllByCursor } from '../../util/queries';
import {
  CreateResult,
  DataProvider,
  DeleteResult,
  GetManyReferenceResult,
  GetManyResult,
  GetOneResult,
  UpdateResult,
} from 'react-admin';
import { getList } from './list';
import { transformFromBackend, transformFromCognito } from './transform';
import { GetUsersGroupsQuery, GroupsOrderBy } from 'types/gql/graphql';
import { truthyFilter } from '../../util/types';
import invariant from 'tiny-invariant';
import { makePlaceholder } from 'dataProviders/utils';
import { cognitoEnabled, userPoolId } from 'dataProviders/components/env';

const dataProvider: DataProvider = {
  getList,
  getOne: async (resource, params): Promise<GetOneResult<any>> => {
    console.log('getOne (groups)', params);

    const backendGroup = await backendClient.query({
      query: getGroupsFilteredQuery,
      variables: {
        perPage: 100,
        offset: 0,
        filter: { name: { equalTo: String(params.id) } },
      },
    });

    const [backendGroupParsed] =
      backendGroup.data.groups?.nodes
        .filter(truthyFilter)
        .map(transformFromBackend) || [];

    let groupData = backendGroupParsed;
    if (cognitoEnabled) {
      try {
        const command = new GetGroupCommand({
          UserPoolId: userPoolId,
          GroupName: params.id,
        });
        const cognitoResult = await cognitoClient().send(command);
        if (cognitoResult.Group)
          Object.assign(groupData, transformFromCognito(cognitoResult.Group));
      } catch (error) {
        return handleReturnError({
          error,
          title: 'getOne group error',
          message: 'An error occured while retrieving the group',
        });
      }
    }

    return {
      data: {
        backend: backendGroupParsed,
        ...groupData,
      },
    };
  },
  getMany: async (resource, params): Promise<GetManyResult<any>> => {
    console.log('getMany (groups)', params);
    try {
      const data = await backendClient.query({
        query: getGroupsFilteredQuery,
        variables: {
          perPage: 1000,
          offset: 0,
          order: `PRIMARY_KEY_DESC` as GroupsOrderBy,
          filter: { name: { in: params.ids.map(String) } },
        },
      });

      return {
        data:
          data.data.groups?.nodes
            .filter(truthyFilter)
            .map(transformFromBackend) || [],
      };
    } catch (error) {
      return handleReturnError({
        error,
        title: 'getMany group error',
        message: 'An error occured while retrieving the groups',
      });
    }
  },
  getManyReference: async (
    resource,
    params
  ): Promise<GetManyReferenceResult<any>> => {
    console.log('getManyReference (groups)', params);

    if (params.target === 'user_id') {
      try {
        const data = await backendClient.query({
          query: getGroupsForUserQuery,
          variables: {
            perPage: params.pagination.perPage,
            offset: (params.pagination.page - 1) * params.pagination.perPage,
            condition: {
              userId: String(params.id),
            },
          },
        });

        return {
          data:
            data.data.groupsUsers?.nodes
              .map(node => node?.group)
              .filter(truthyFilter)
              .map(transformFromBackend) || [],
          total: data.data.groupsUsers?.totalCount || 0,
        };
      } catch (error) {
        return handleReturnError({
          error,
          title: 'getManyReference group error',
          message: 'An error occured while retrieving the associated groups',
        });
      }
    }

    if (params.target === 'catalogue_item_id') {
      try {
        const data = await backendClient.query({
          query: getGroupsForCatalogueItemQuery,
          variables: {
            perPage: params.pagination.perPage,
            offset: (params.pagination.page - 1) * params.pagination.perPage,
            condition: {
              catalogueItemId: String(params.id),
            },
          },
        });
        return {
          data:
            data.data.catalogueItemsGroups?.nodes
              .filter(truthyFilter)
              .map(node => {
                if (!node?.group) return null;
                const group = transformFromBackend(node?.group);
                const courseType = node.course;
                return { ...group, courseType };
              })
              .filter(truthyFilter) || [],
          total: data.data.catalogueItemsGroups?.totalCount || 0,
        };
      } catch (error) {
        return handleReturnError({
          error,
          title: 'getManyReference group error',
          message: 'An error occured while retrieving the associated groups',
        });
      }
    }
    if (params.target === 'tenant_id') {
      try {
        const data = await backendClient.query({
          query: getGroupsForTenantQuery,
          variables: {
            perPage: params.pagination.perPage,
            offset: (params.pagination.page - 1) * params.pagination.perPage,
            condition: {
              tenantInternalId: String(params.id),
            },
          },
        });
        return {
          data:
            data.data.tenantsGroups?.nodes
              .filter(truthyFilter)
              .map(node => {
                if (!node?.group) return null;
                const group = transformFromBackend(node.group);
                const courseType = node.course;
                return { ...group, courseType };
              })
              .filter(truthyFilter) || [],
          total: data.data.tenantsGroups?.totalCount || 0,
        };
      } catch (error) {
        return handleReturnError({
          error,
          title: 'getManyReference group error',
          message: 'An error occured while retrieving the associated groups',
        });
      }
    }
    return Promise.reject('not implemented yet');
  },
  create: async (resource, params): Promise<CreateResult<any>> => {
    console.log('create (groups)', params);

    const group = {
      id: params.data.name,
      name: params.data.name,
      description: params.data.description,
    };

    if (cognitoEnabled) {
      try {
        const command = new CreateGroupCommand({
          UserPoolId: userPoolId,
          GroupName: params.data.name,
          Description: params.data.description,
        });

        const cognitoResult = await cognitoClient().send(command);

        invariant(cognitoResult.Group, 'Creating Cognito group failed');

        Object.assign(group, transformFromCognito(cognitoResult.Group));
      } catch (error) {
        return handleReturnError({
          error,
          title: 'create group on cognito error',
          message: 'An error occured while creating the group',
        });
      }
    }

    try {
      await backendClient.mutate({
        mutation: createGroup,
        variables: {
          input: {
            group: {
              name: group.name,
              description: group.description,
            },
          },
        },
      });
    } catch (error) {
      return handleReturnError({
        error,
        title: 'create group on graphql error',
        message: 'An error occured while creating the group',
      });
    }

    return { data: group };
  },
  createCognitoOnly: async (
    resource: string,
    params: { entity: { name: string; description: string } }
  ) => {
    console.log('createCognitoOnly (groups)', params);

    const command = new CreateGroupCommand({
      UserPoolId: userPoolId,
      GroupName: params.entity.name,
      Description: params.entity.description,
    });
    try {
      const data = await cognitoClient().send(command);
      return {
        data: data.Group ? transformFromCognito(data.Group) : null,
      };
    } catch (error) {
      return handleReturnError({
        error,
        title: 'create user on cognito error',
        message: 'An error occured while creating the user',
      });
    }
  },
  createBackendOnly: async (
    resource: string,
    params: { entity: { name: string; description: string } }
  ) => {
    console.log('createBackendOnly (groups)', params);
    try {
      await backendClient.mutate({
        mutation: createGroup,
        variables: {
          input: {
            group: {
              name: params.entity.name,
              description: params.entity.description,
            },
          },
        },
      });
      return {
        data: params.entity,
      };
    } catch (error) {
      return handleReturnError({
        error,
        title: 'create user on graphql error',
        message: 'An error occured while creating the user',
      });
    }
  },
  update: async (resource, params): Promise<UpdateResult<any>> => {
    console.log('update (groups)', params);

    if (cognitoEnabled) {
      try {
        const command = new UpdateGroupCommand({
          UserPoolId: userPoolId,
          GroupName: String(params.id),
          Description: params.data.description,
        });

        await cognitoClient().send(command);
      } catch (error) {
        return handleReturnError({
          error,
          title: 'update group on cognito error',
          message: 'An error occured while updating the group',
        });
      }
    }

    try {
      await backendClient.mutate({
        mutation: updateGroup,
        variables: {
          input: {
            name: String(params.id),
            patch: {
              description: params.data.description,
            },
          },
        },
      });
    } catch (error) {
      return handleReturnError({
        error,
        title: 'update group on graphql error',
        message: 'An error occured while updating the group',
      });
    }

    return {
      data: params.data,
    };
  },
  updateMany: makePlaceholder('groups')('updateMany'),
  delete: async (resource, params): Promise<DeleteResult<any>> => {
    console.log('delete (groups)', params.id);
    const promises = [];

    if (cognitoEnabled) {
      const command = new DeleteGroupCommand({
        UserPoolId: userPoolId,
        GroupName: String(params.id),
      });
      promises.push(
        cognitoClient()
          .send(command)
          .then(
            data => ({
              data: params.previousData,
            }),
            error =>
              handleReturnError({
                error,
                title: 'delete group on cognito error',
                message: 'An error occured while deleting the group',
              })
          )
      );
    }
    promises.push(
      backendClient
        .mutate({
          mutation: deleteGroup,
          variables: {
            input: {
              name: String(params.id),
            },
          },
        })
        .then(
          data => ({
            data: params.previousData,
          }),
          error =>
            handleReturnError({
              error,
              title: 'delete group on graphql error',
              message: 'An error occured while deleting the group',
            })
        )
    );

    const results = await Promise.all(promises);
    return { data: results };
  },
  deleteMany: async (resource, params) => {
    console.log('deleteMany (groups)', params);
    const ids = params.ids.map(String);
    if (cognitoEnabled) {
      await Promise.all(
        ids.map(id => {
          const command = new DeleteGroupCommand({
            UserPoolId: userPoolId,
            GroupName: id,
          });
          return cognitoClient().send(command);
        })
      );
    }

    await Promise.all(
      ids.map(id =>
        backendClient.mutate({
          mutation: deleteGroup,
          variables: {
            input: {
              name: id,
            },
          },
        })
      )
    );

    return {
      data: params.ids,
    };
  },
  getAllListImport: async (resource: string, params: {}) => {
    console.log('getAllListImport (groups)', params);
    try {
      const data = await backendClient.query({
        query: getAllGroupsImportQuery,
        variables: {},
      });
      return {
        data: data.data.groups?.nodes || [],
        total: data.data.groups?.totalCount || 0,
      };
    } catch (error) {
      return handleReturnError({
        error,
        title: 'getAllListImport group error',
        message: 'An error occured while retrieving the group getAllListImport',
      });
    }
  },
  getAllFromCognito: async (
    resource: string,
    params: { updateNumberOfCognitoGroups?: Function }
  ) => {
    console.log('getAllFromCognito (groups)', params);
    const updateNumberOfCognitoGroups = params.updateNumberOfCognitoGroups;

    let pageToken: string | undefined = 'first_page';
    const groups: ReturnType<typeof transformFromCognito>[] = [];

    try {
      while (pageToken !== null) {
        const command = new ListGroupsCommand({
          UserPoolId: userPoolId,
          Limit: 60,
          NextToken: pageToken === 'first_page' ? undefined : pageToken,
        });
        const data: ListGroupsCommandOutput = await cognitoClient().send(
          command
        );

        if (!data?.Groups) break;

        groups.push(
          ...data.Groups?.filter(truthyFilter).map(transformFromCognito)
        );
        if (updateNumberOfCognitoGroups) {
          updateNumberOfCognitoGroups(groups.length);
        }
        pageToken = data.NextToken || undefined;
        if (pageToken === undefined) break;
      }
      return { data: groups };
    } catch (error) {
      return handleReturnError({
        error,
        title: 'getAllFromCognito groups error',
        message:
          'An error occured while retrieving the groups getAllFromCognito',
      });
    }
  },
  getAllGroupsWithUsersFromCognito: async (
    resource: string,
    params: { updateNumberOfCognitoGroupsUsers: Function; groups: string[] }
  ) => {
    console.log('getAllGroupsWithUsersFromCognito (groups)', params);
    const updateNumberOfCognitoGroupsUsers =
      params.updateNumberOfCognitoGroupsUsers;
    const groupsUsers: Record<string, string[]> = {};

    try {
      for (let group of params.groups) {
        const users: string[] = [];
        let pageToken: string | undefined = 'first_page';
        while (pageToken !== null) {
          const command: ListUsersInGroupCommand = new ListUsersInGroupCommand({
            UserPoolId: userPoolId,
            GroupName: group,
            Limit: 60,
            NextToken: pageToken === 'first_page' ? undefined : pageToken,
          });
          const data = await cognitoClient().send(command);
          users.push(
            ...(data?.Users?.map(u => u?.Username).filter(truthyFilter) || [])
          );
          if (updateNumberOfCognitoGroupsUsers) {
            updateNumberOfCognitoGroupsUsers(
              (prev: number) => prev + (data?.Users?.length || 0)
            );
          }
          pageToken = data.NextToken || undefined;
          if (pageToken === undefined) break;
        }
        groupsUsers[group] = users;
      }

      return { data: groupsUsers };
    } catch (error) {
      return handleReturnError({
        error,
        title: 'getAllGroupsWithUsersFromCognito groups error',
        message:
          'An error occured while retrieving the groups getAllGroupsWithUsersFromCognito',
      });
    }
  },
  getAllGroupsWithUsersFromBackend: async (resource: string, params: {}) => {
    console.log('getAllGroupsWithUsersFromBackend (groups)', params);

    try {
      type GroupDataType = NonNullable<GetUsersGroupsQuery['groupsUsers']>;
      const results = await getAllByCursor<
        GroupDataType,
        { data: GetUsersGroupsQuery }
      >(
        (first, after) =>
          backendClient.query({
            query: getUsersGroupsQuery,
            variables: { first, after },
          }),
        'groupsUsers',
        { pageSize: 5000 }
      );

      const data: Record<string, string[]> = {};

      results.data.filter(truthyFilter).forEach(({ group, user }) => {
        if (!group?.name || !user?.id) return;
        if (!data[group.name]) data[group.name] = [];
        data[group.name].push(user.id);
      });

      return { data, total: results.total };
    } catch (error) {
      return handleReturnError({
        error,
        title: 'getAllGroupsWithUsersFromBackend groups and users error',
        message:
          'An error occured while retrieving the groups and users getAllGroupsWithUsersFromBackend',
      });
    }
  },
};

export default dataProvider;

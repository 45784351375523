import { CognitoIdentityProviderClient } from '@aws-sdk/client-cognito-identity-provider';

import { awsCredentials } from 'authentication/credentials';
import { awsRegion } from './env';

let cachedClient: InstanceType<typeof CognitoIdentityProviderClient> | null =
  null;

export const cleanCognitoClient = () =>
  new CognitoIdentityProviderClient({
    region: awsRegion,
    credentials: awsCredentials,
  });

export const cognitoClient = () => {
  if (!cachedClient) {
    cachedClient = cleanCognitoClient();
  }
  return cachedClient;
};

import { ComponentProps } from 'react';
import { Layout, UserMenu, AppBar } from 'react-admin';
import LogoutButton from '../authentication/LogoutButton';
import { CustomMenu } from './CustomMenu';
import { ReactQueryDevtools } from 'react-query/devtools';

const CustomUserMenu = () => (
  <UserMenu>
    <LogoutButton />
  </UserMenu>
);

const CustomAppBar = () => <AppBar userMenu={<CustomUserMenu />} />;

type CustomLayoutProps = Omit<ComponentProps<typeof Layout>, 'menu' | 'appBar'>;
export const CustomLayout = (props: CustomLayoutProps) => (
  <>
    <Layout {...props} menu={CustomMenu} appBar={CustomAppBar} />
    <ReactQueryDevtools initialIsOpen={false} />
  </>
);

import { DataProvider } from 'react-admin';
import { processWorkbook } from './components/massDeleter';
import { makePlaceholder, readFileDataAsBinary } from './utils';

const deletePlaceholder = makePlaceholder('mass delete');

const dataProvider: DataProvider = {
  getList: deletePlaceholder('getList'),
  getOne: deletePlaceholder('getOne'),
  getMany: deletePlaceholder('getMany'),
  getManyReference: deletePlaceholder('getManyReference'),
  // @ts-ignore
  create: async (resource, params) => {
    console.log('create (mass delete)', params);

    const XLSX = await import('xlsx');

    const data = await readFileDataAsBinary(params.data.file.rawFile);
    const workbook = XLSX.read(data, { type: 'binary' });
    const results = await processWorkbook({
      workbook,
      updateNumberOfResults: params.data.updateNumberOfResults,
      updateNumberOfResultsDone: params.data.updateNumberOfResultsDone,
      dryRun: params.data.dry_run,
    });

    return { data: { id: 1, results: results } };
  },
  update: deletePlaceholder('update'),
  updateMany: deletePlaceholder('updateMany'),
  delete: deletePlaceholder('delete'),
  deleteMany: deletePlaceholder('deleteMany'),
};

export default dataProvider;

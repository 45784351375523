import users from './cognitoUsers';
import groups from './cognitoGroups';
import catalogue_items from './contentfulCatalogueItems';
import tenants from './contentfulTenants';
import importExcel from './importExcel';
import massDelete from './massDeleteUser';
import sync from './sync';
import attributes from './attributes';

import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  GET_MANY,
  GET_MANY_REFERENCE,
  DELETE_MANY,
  DataProvider,
} from 'react-admin';

type DataProviderMapping = {
  dataProvider: DataProvider;
  resources: string[];
};
const dataProviders: DataProviderMapping[] = [
  {
    dataProvider: users,
    resources: ['users'],
  },
  {
    dataProvider: attributes,
    resources: ['attributes'],
  },
  {
    dataProvider: groups,
    resources: ['groups'],
  },
  {
    dataProvider: catalogue_items,
    resources: ['catalogue_items'],
  },
  {
    dataProvider: tenants,
    resources: ['tenants'],
  },
  {
    dataProvider: importExcel,
    resources: ['import'],
  },
  {
    dataProvider: massDelete,
    resources: ['mass-delete'],
  },
  {
    dataProvider: sync,
    resources: ['sync'],
  },
];

const mappingType = {
  [GET_LIST]: 'getList',
  [GET_ONE]: 'getOne',
  [GET_MANY]: 'getMany',
  [GET_MANY_REFERENCE]: 'getManyReference',
  [CREATE]: 'create',
  [UPDATE]: 'update',
  [UPDATE_MANY]: 'updateMany',
  [DELETE]: 'delete',
  [DELETE_MANY]: 'deleteMany',
  createCognitoOnly: 'createCognitoOnly',
  inviteCognito: 'inviteCognito',
  resendInvitations: 'resendInvitations',
  createBackendOnly: 'createBackendOnly',
  enableUser: 'enableUser',
  disableUser: 'disableUser',
  removeUserFromGroup: 'removeUserFromGroup',
  addUserToGroup: 'addUserToGroup',
  addGroupToCatalogueItem: 'addGroupToCatalogueItem',
  removeGroupFromCatalogueItem: 'removeGroupFromCatalogueItem',
  addGroupToTenant: 'addGroupToTenant',
  removeGroupFromTenant: 'removeGroupFromTenant',
  removeFromGroup: 'removeFromGroup',
  addToGroup: 'addToGroup',
  getAllListImport: 'getAllListImport',
  getAllFromCognito: 'getAllFromCognito',
  getAllGroupsWithUsersFromCognito: 'getAllGroupsWithUsersFromCognito',
  getAllGroupsWithUsersFromBackend: 'getAllGroupsWithUsersFromBackend',
  getAllCatalogueItems: 'getAllCatalogueItems',
};

const dataProvider = (
  type: string,
  resource: string,
  params: Record<string, any>
) => {
  const dataProviderMapping = dataProviders.find(dp =>
    dp.resources.includes(resource)
  );

  return dataProviderMapping?.dataProvider[
    mappingType[type as keyof typeof mappingType]
  ](resource, params);
};

export default dataProvider;

import { DataProvider } from 'react-admin';
import { processWorkbook } from './components/importer';
import { makePlaceholder, readFileDataAsBinary } from './utils';

const importPlaceholder = makePlaceholder('import');

const dataProvider: DataProvider = {
  getList: importPlaceholder('getList'),
  getOne: importPlaceholder('getOne'),
  getMany: importPlaceholder('getMany'),
  getManyReference: importPlaceholder('getManyReference'),
  // @ts-ignore
  create: async (resource, params) => {
    console.log('create (import)', params);
    const XLSX = await import('xlsx');

    const data = await readFileDataAsBinary(params.data.file.rawFile);
    const workbook = XLSX.read(data, { type: 'binary' });
    const results = await processWorkbook({
      workbook,
      updateNumberOfResults: params.data.updateNumberOfResults,
      updateNumberOfResultsDone: params.data.updateNumberOfResultsDone,
      dryRun: params.data.dry_run,
    });

    return { data: { id: 1, results } };
  },
  update: importPlaceholder('update'),
  updateMany: importPlaceholder('updateMany'),
  delete: importPlaceholder('delete'),
  deleteMany: importPlaceholder('deleteMany'),
};

export default dataProvider;

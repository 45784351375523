import { graphql } from 'types/gql';

export const getGenericStats = graphql(`
  query getStats {
    users {
      totalCount
    }
    groups {
      totalCount
    }
  }
`);

export type GenericCatalogueItem = {
  sys: { id: string };
  id?: string | null;
  type?: string | null;
  title?: string | null;
  description?: string | null;
  groupCount?: number | null;
};

export const transformFromContentful = (
  catalogue_item: GenericCatalogueItem
) => ({
  id: catalogue_item.sys.id,
  catalogue_item_id: catalogue_item.id,
  type: catalogue_item.type,
  title: catalogue_item.title,
  description: catalogue_item.description,
  groupCount: catalogue_item.groupCount ? catalogue_item.groupCount : 0,
});

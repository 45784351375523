import usersDataProvider from '../cognitoUsers';
import groupsDataProvider from '../cognitoGroups';

import { Identifier } from 'react-admin';
import { SetStateAction } from 'react';
import { DeepUpdateCounts } from 'resources/sync';

export type SyncCounts = {
  users: Counts;
  groups: Counts;
  usersGroups: Counts;
};

export type Counts = {
  backend: number;
  cognito: number;
};

export type UpdateCounts = (val: SetStateAction<SyncCounts>) => void;

type BEUser = {
  id: Identifier;
  email: string;
  [name: string]: any;
};

type CognitoUser = {
  id: Identifier;
  status: string;
  email: string;
  enabled: boolean;
  [name: string]: any;
};

export type UserCompareRecord = {
  backend?: BEUser;
  cognito?: CognitoUser;
};

type BEGroup = {
  id: Identifier;
  name: string;
  [name: string]: any;
};

type CognitoGroup = {
  id: Identifier;
  status: string;
  name: string;
  description: string;
  [name: string]: any;
};

export type GroupCompareRecord = {
  backend?: BEGroup;
  cognito?: CognitoGroup;
};

type UserGroupRelationRecords = Record<string, string[]>;

export type GroupsUsersResultRow = [string, { id: string; email?: string }];

export type SyncState<T> = {
  backendMissing: T[];
  cognitoMissing: T[];
  notMatching: T[];
  matching: T[];
};

export type UserGroupSyncState = Pick<
  SyncState<GroupsUsersResultRow>,
  'backendMissing' | 'cognitoMissing'
>;

type ReadDatabasesArgs = {
  updateCounts: DeepUpdateCounts;
};

export const GROUP_ATTRIBUTES = ['name', 'description'];
export const USER_ATTRIBUTES = ['id', 'email', 'givenName', 'familyName'];

function compareUser(backendUser: BEUser, cognitoUser: CognitoUser) {
  if (USER_ATTRIBUTES.some(k => backendUser[k] !== cognitoUser[k]))
    return false;
  const attributes = [
    ...Object.keys(backendUser.attributes),
    ...Object.keys(cognitoUser.attributes),
  ];
  if (
    attributes.some(
      k => backendUser.attributes[k] !== cognitoUser.attributes[k]
    )
  )
    return false;
  return true;
}

async function getUserCompare(updateCounts: DeepUpdateCounts) {
  const backendUsers = (await usersDataProvider.getList('users', {
    filter: {},
    pagination: {
      perPage: 1000000,
      page: 1,
    },
    sort: {
      field: 'email',
      order: 'ASC',
    },
  })) as { data: BEUser[]; total: number };

  updateCounts('users', 'backend', backendUsers.total);
  const updateNumberOfCognitoUsers = (count: SetStateAction<number>) =>
    updateCounts('users', 'cognito', count);

  const cognitoUsers = (await usersDataProvider.getAllFromCognito('users', {
    updateNumberOfCognitoUsers,
  })) as { data: CognitoUser[] };

  const usersMap: Record<string, UserCompareRecord> = {};

  backendUsers.data.forEach(user => {
    usersMap[user.id] = { backend: user };
  });

  cognitoUsers.data.forEach(user => {
    if (!usersMap[user.id]) {
      usersMap[user.id] = {};
    }
    usersMap[user.id].cognito = user;
  });

  const userState: SyncState<UserCompareRecord> = {
    cognitoMissing: [],
    backendMissing: [],
    notMatching: [],
    matching: [],
  };

  Object.values(usersMap).forEach(user => {
    if (!user.cognito) {
      return userState.cognitoMissing.push(user);
    }
    if (!user.backend) {
      return userState.backendMissing.push(user);
    }
    if (!compareUser(user.backend, user.cognito)) {
      return userState.notMatching.push(user);
    }
    userState.matching.push(user);
  });
  return { userState, usersMap };
}

async function getGroupCompare(updateCounts: DeepUpdateCounts) {
  const backendGroups = (await groupsDataProvider.getList('groups', {
    filter: {},
    pagination: {
      perPage: 1000000,
      page: 1,
    },
    sort: {
      field: 'name',
      order: 'ASC',
    },
  })) as { data: BEGroup[]; total: number };

  updateCounts('groups', 'backend', backendGroups.total);
  const updateNumberOfCognitoGroups = (count: SetStateAction<number>) =>
    updateCounts('groups', 'cognito', count);

  const cognitoGroups = (await groupsDataProvider.getAllFromCognito('groups', {
    updateNumberOfCognitoGroups,
  })) as { data: CognitoGroup[] };

  const groupsMap: Record<string, GroupCompareRecord> = {};

  backendGroups.data.forEach(group => {
    groupsMap[group.id] = { backend: group };
  });

  cognitoGroups.data.forEach(group => {
    if (groupsMap[group.id]) {
      groupsMap[group.id].cognito = group;
    } else {
      groupsMap[group.id] = { cognito: group };
    }
  });

  const groupState: SyncState<GroupCompareRecord> = {
    cognitoMissing: [],
    backendMissing: [],
    notMatching: [],
    matching: [],
  };

  Object.values(groupsMap).forEach(group => {
    if (!group.cognito) {
      return groupState.cognitoMissing.push(group);
    }
    if (!group.backend) {
      return groupState.backendMissing.push(group);
    }
    if (
      !GROUP_ATTRIBUTES.every(
        attr => group.cognito?.[attr] === group.backend?.[attr]
      )
    ) {
      return groupState.notMatching.push(group);
    }
    groupState.matching.push(group);
  });
  return { groupState, cognitoGroups, groupsMap };
}

type GroupCompareDB = {
  usersMap: Record<string, any>;
  cognitoGroups: { data: any[] };
  groupsMap: Record<string, any>;
};

async function getUserGroupCompare(
  updateCounts: DeepUpdateCounts,
  { usersMap, cognitoGroups, groupsMap }: GroupCompareDB
) {
  const backendUsersInGroups =
    (await groupsDataProvider.getAllGroupsWithUsersFromBackend(
      'groups',
      {}
    )) as { data: UserGroupRelationRecords; total: number };

  updateCounts('usersGroups', 'backend', backendUsersInGroups.total);
  const updateNumberOfCognitoGroupsUsers = (count: SetStateAction<number>) =>
    updateCounts('usersGroups', 'cognito', count);

  const cognitoUsersInGroups =
    (await groupsDataProvider.getAllGroupsWithUsersFromCognito('groups', {
      groups: cognitoGroups.data.map(group => group.id),
      updateNumberOfCognitoGroupsUsers,
    })) as { data: UserGroupRelationRecords; total: number };

  type UserGroupRelationIdRecord = readonly [string, string];
  const usersGroupsMissingCognitoId: UserGroupRelationIdRecord[] = [];
  const usersGroupsMissingBackendId: UserGroupRelationIdRecord[] = [];

  Object.keys(groupsMap).forEach(groupId => {
    const cognitoUsers = cognitoUsersInGroups.data[groupId] || [];
    const backendUsers = backendUsersInGroups.data[groupId] || [];

    usersGroupsMissingCognitoId.push(
      ...Array.from(
        backendUsers.filter(userId => !cognitoUsers.includes(userId)),
        userId => [groupId, userId] as const
      )
    );
    usersGroupsMissingBackendId.push(
      ...Array.from(
        cognitoUsers.filter(user => !backendUsers.includes(user)),
        userId => [groupId, userId] as const
      )
    );
  });

  const mapUserGroupToResult = ([
    group,
    user,
  ]: UserGroupRelationIdRecord): GroupsUsersResultRow => [
    group,
    {
      id: user,
      email: usersMap[user]?.backend?.email ?? usersMap[user]?.cognito?.email,
    },
  ];

  const userGroupState: UserGroupSyncState = {
    backendMissing: usersGroupsMissingBackendId.map(mapUserGroupToResult),
    cognitoMissing: usersGroupsMissingCognitoId.map(mapUserGroupToResult),
  };

  return { userGroupState };
}

async function readDatabases({ updateCounts }: ReadDatabasesArgs) {
  const { userState, usersMap } = await getUserCompare(updateCounts);
  const { groupState, cognitoGroups, groupsMap } = await getGroupCompare(
    updateCounts
  );
  const { userGroupState } = await getUserGroupCompare(updateCounts, {
    cognitoGroups,
    groupsMap,
    usersMap,
  });

  return {
    users: userState,
    groups: groupState,
    usersGroups: userGroupState,
  };
}

export const startSyncComparison = async (ops: ReadDatabasesArgs) => {
  const rows = await readDatabases(ops);
  return { rows };
};

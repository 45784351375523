import { backendClient } from 'dataProviders/components/backend';
import { handleReturnError } from 'dataProviders/components/handleReturnError';
import { GetGroupsFilteredQueryVariables } from 'types/gql/graphql';
import { getGroupsForCatalogueItemQuery } from 'queries_mutations/catalogue_items';
import {
  getGroupsFilteredQuery,
  getGroupsForUserQuery,
  getGroupsQuery,
} from 'queries_mutations/groups';
import { getGroupsForTenantQuery } from 'queries_mutations/tenants';
import { GetListParams, GetListResult } from 'react-admin';
import { truthyFilter } from '../../util/types';
import { transformFromBackend } from './transform';
import { order } from './utils';

async function getFilteredNodes(params: GetListParams) {
  const variables = { perPage: 1000, offset: 0, order: `PRIMARY_KEY_ASC` };

  if (params.filter.not_for_user) {
    const data = await backendClient.query({
      query: getGroupsForUserQuery,
      variables: {
        ...variables,
        condition: { userId: params.filter.not_for_user },
      },
    });

    return data.data.groupsUsers?.nodes.filter(truthyFilter) || [];
  }

  if (params.filter.not_for_catalogue_item) {
    const data = await backendClient.query({
      query: getGroupsForCatalogueItemQuery,
      variables: {
        ...variables,
        condition: { catalogueItemId: params.filter.not_for_catalogue_item },
      },
    });

    return data.data.catalogueItemsGroups?.nodes.filter(truthyFilter) || [];
  }

  if (params.filter.not_for_tenant) {
    const data = await backendClient.query({
      query: getGroupsForTenantQuery,
      variables: {
        ...variables,
        condition: { tenantId: params.filter.not_for_tenant },
      },
    });

    return data.data.tenantsGroups?.nodes.filter(truthyFilter) || [];
  }
  return [];
}

export async function getList(
  resource: string,
  params: GetListParams
): Promise<GetListResult<any>> {
  console.log('getList (groups)', params);
  try {
    if (
      params.filter.not_for_user ||
      params.filter.not_for_catalogue_item ||
      params.filter.not_for_tenant
    ) {
      const nodes = await getFilteredNodes(params);

      const ids = nodes.map(node => node.group?.name).filter(truthyFilter);
      const filter: GetGroupsFilteredQueryVariables['filter'] = {
        not: { name: { in: ids } },
      };

      if (params.filter.name && params.filter.name !== '') {
        filter.name = { includesInsensitive: params.filter.name };
      }

      const data_groups = await backendClient.query({
        query: getGroupsFilteredQuery,
        variables: {
          perPage: params.pagination.perPage,
          offset: (params.pagination.page - 1) * params.pagination.perPage,
          order: order(params.sort.field, params.sort.order),
          filter,
        },
      });

      return {
        data:
          data_groups.data.groups?.nodes
            .filter(truthyFilter)
            .map(transformFromBackend) || [],
        total: data_groups.data.groups?.totalCount || 0,
      };
    }

    const variables = {
      perPage: params.pagination.perPage,
      offset: (params.pagination.page - 1) * params.pagination.perPage,
      order: order(params.sort.field, params.sort.order),
    };

    if (params.filter.q) {
      const data_groups = await backendClient.query({
        query: getGroupsFilteredQuery,
        variables: {
          ...variables,
          filter: {
            or: [
              { name: { includesInsensitive: params.filter.q } },
              { description: { includesInsensitive: params.filter.q } },
            ],
          },
        },
      });

      return {
        data:
          data_groups.data.groups?.nodes
            .filter(truthyFilter)
            .map(transformFromBackend) || [],
        total: data_groups.data.groups?.totalCount || 0,
      };
    }

    const data_groups = await backendClient.query({
      query: getGroupsQuery,
      variables,
    });

    return {
      data:
        data_groups.data.groups?.nodes
          .filter(truthyFilter)
          .map(transformFromBackend) || [],
      total: data_groups.data.groups?.totalCount || 0,
    };
  } catch (error) {
    return handleReturnError({
      error,
      title: 'getList group error',
      message: 'An error occured while retrieving the group list',
    });
  }
}

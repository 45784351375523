import {
  Children,
  ComponentProps,
  isValidElement,
  useState,
  useMemo,
  useContext,
  createContext,
} from 'react';
import {
  Datagrid,
  DatagridBody,
  DatagridRow,
  DatagridRowProps,
  DatagridBodyProps,
  DatagridHeaderProps,
  DatagridHeader,
  useStore,
  useResourceContext,
} from 'react-admin';
import ColumnIcon from '@mui/icons-material/ViewColumn';
import Button from '@mui/material/Button';

import SelectionDialog from './SelectionDialog';

const arrayToSelection = (columns: string[]) =>
  Object.fromEntries(columns.map(columnName => [columnName, true]));

const CustomizableDatagridRow = ({ children, ...props }: DatagridRowProps) => {
  const selection = useContext(ColumnContext);
  return (
    <DatagridRow {...props}>
      {Children.map(children, field => {
        const source = (isValidElement(field) && field?.props.source) || null;
        return source && !selection?.[source] ? null : field;
      })}
    </DatagridRow>
  );
};

const CustomizableDatagridHeader = ({
  children,
  ...props
}: DatagridHeaderProps) => {
  const selection = useContext(ColumnContext);
  return (
    <DatagridHeader {...props}>
      {Children.map(children, field => {
        const source = (isValidElement(field) && field?.props.source) || null;
        return source && !selection?.[source] ? null : field;
      })}
    </DatagridHeader>
  );
};

const CustomizableDatagridBody = (props: DatagridBodyProps) => (
  <DatagridBody {...props} row={<CustomizableDatagridRow />} />
);

function getInitialSelection(
  defaultColumns: string[] = [],
  columnNames: string[] = []
): SelectedColumns {
  // if defaultColumns are set let's return them
  if (defaultColumns?.length) {
    return arrayToSelection(defaultColumns);
  }

  // otherwise we fallback on the default behaviour : display all columns
  return arrayToSelection(columnNames);
}

type SelectedColumns = Record<string, boolean>;
type CustomizableDatagridProps = {
  defaultColumns?: string[];
  storeContext?: string;
} & ComponentProps<typeof Datagrid>;

const ColumnContext = createContext<SelectedColumns | undefined>(undefined);

function CustomizableDatagrid({
  children,
  defaultColumns = [],
  storeContext = '',
  ...rest
}: CustomizableDatagridProps) {
  const resource = useResourceContext();
  const columnLabels = useMemo(
    () =>
      (
        Children.map(children, el =>
          isValidElement(el)
            ? {
                source: el.props.source,
                label: el.props.label,
              }
            : null
        ) ?? []
      ).filter(input => Boolean(input?.source)),
    [children]
  );
  const columnNames = columnLabels.map(({ source }) => source);

  const [selection, setSelection] = useStore<SelectedColumns>(
    `${resource}${storeContext}.columns`,
    getInitialSelection(defaultColumns, columnNames)
  );
  const [modalOpened, setModalOpened] = useState(false);
  const toggleColumn = (column: string) =>
    setSelection(prev => ({ ...prev, [column]: !prev[column] }));

  return (
    <div style={{ alignSelf: 'stretch' }}>
      <div style={{ float: 'right', marginRight: '1rem', marginTop: '1rem' }}>
        <Button
          variant='outlined'
          aria-label='add'
          onClick={() => setModalOpened(true)}>
          <ColumnIcon />
        </Button>
      </div>
      {modalOpened && (
        <SelectionDialog
          selection={selection}
          columns={columnLabels}
          onColumnClicked={toggleColumn}
          onClose={() => setModalOpened(false)}
        />
      )}
      <ColumnContext.Provider value={selection}>
        <Datagrid
          body={<CustomizableDatagridBody />}
          header={<CustomizableDatagridHeader />}
          rowClick='edit'
          {...rest}>
          {children}
        </Datagrid>
      </ColumnContext.Provider>
    </div>
  );
}

export default CustomizableDatagrid;
